import React, { useState } from 'react';

import { Query } from 'react-apollo';

import TerritoryDetail from './territory-detail';
import DateFilter from '../layouts/date-filter';
import { graphQlQueries } from '../gql/main-gql';
import { countryVariables } from '../../utils/country-variables';
import { decrypt } from '../../utils/utils-2';

const SingleTerritory = (props) => {
  // Variable we name to the main dashboard that allow
  // selecting Territory Query based on the selected country
  // This name must match a key on the main-gql.js file
  const componentName = 'single-territory';

  // Capturing the territoriy abbreviation passed as parameters
  const {
    match: { params },
  } = props;
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');
  const country = decrypt(localStorage.getItem('country'));

  const territoryLabel = country
    ? countryVariables[country]['territoryLabel']
    : '';

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <div className="d-flex align-items-center">
            <DateFilter parentDateFilter={dateFiltering} />
          </div>
          <Query
            query={graphQlQueries[country][componentName]}
            variables={{ abbreviation: params.id }}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return <p>Error. Try refreshing the page</p>;
              }

              return (
                <TerritoryDetail
                  data={data[territoryLabel]}
                  currentThursday={
                    filteringDate ? filteringDate : latestThursday
                  }
                  country={country}
                />
              );
            }}
          </Query>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default SingleTerritory;
