import React, { useState, useRef, useEffect } from 'react';
// reactstrap components
import { Button, Modal } from 'reactstrap';

import { useMutation } from '@apollo/react-hooks';

import PaymentRewardModalBody from './payment-reward-body';
import { countryVariables } from '../../utils/country-variables';
import { graphQlQueries } from '../gql/main-gql';

const RewardPaymentModal = ({ props }) => {
  const initialArray = Array.apply(null, Array(100)).map(() => {
    return { agentId: '', rewards: [], note: '' };
  });

  const [formFields, handleformFields] = useState(initialArray);
  const [errors, setErrors] = useState({ error: false, errorMessage: '' });
  const [emptyModal, setEmptyModal] = useState('');
  const [progress, setProgress] = useState('');
  const [success, setSuccess] = useState('');
  const [duplicateError, setDuplicateError] = useState(false);
  const stateRef = useRef();
  const [updateCommission] = useMutation(
    graphQlQueries[props.country]['update-commission'],
  );
  const [createAction] = useMutation(
    graphQlQueries[props.country]['create-action'],
  );
  const [updateReward] = useMutation(
    graphQlQueries[props.country]['update-reward'],
  );

  // Label indicating how returned response from graphql queries and mutation are called
  const createActionLabel =
    countryVariables[props.country]['createActionLabel'];
  const actionLabel = countryVariables[props.country]['actionLabel'];

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  stateRef.current = success;

  // Update initial array when component render
  useEffect(() => {
    const array = Array.apply(null, Array(props.data.length)).map(() => {
      return {
        agentId: '',
        rewards: [],
        note: '',
        lastCommissionId: '',
      };
    });
    handleformFields(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle state change
  const handleFormChange = (formFieldsValues, error) => {
    // Update various error messages if any
    setEmptyModal('');
    setErrors({ error: false, errorMessage: '' });
    // Update duplicat error status
    setDuplicateError(error);

    // Update form field state
    handleformFields(formFieldsValues);
  };

  // handle action submission
  const handleActionSubmit = () => {
    const agentRewards = formFields.filter((item) => item.id !== '');
    if (agentRewards.length !== props.selectedRewards.length) {
      setEmptyModal('you shoud fill at least one field for each agent');
      return;
    }

    const userEmail = localStorage.getItem('userEmail');
    const actionName = 'pay reward';
    if (!userEmail) {
      setEmptyModal('The session expired, Log In Again to Continue');
      return;
    }
    setProgress(`${actionName} In Progress`);
    // First create an action
    createAction({
      variables: { actionName: actionName, userEmail: userEmail },
    }).then(
      (response) =>
        hanlePostAction(response, agentRewards, userEmail, actionName),
      (error) => handleError(error),
    );
  };

  const hanlePostAction = (response, agentRewards, userEmail, actionName) => {
    setProgress('');
    const actionId = response.data[createActionLabel][actionLabel].id;
    agentRewards.forEach((agentReward) => {
      const agentId = agentReward.agentId;
      const rewardReceptions = agentReward.rewards;
      const note = agentReward.note;
      const lastCommissionId = agentReward.lastCommissionId;
      let totalCash = 0;
      rewardReceptions.forEach((item) => {
        if (item.isCash) {
          totalCash += item.rewardCash;
        }
      });
      const payload = {
        userEmail,
        actionName,
        actionId,
        agentId,
        amount: totalCash,
        note,
        rewardReceptions,
        country: props.country,
      };
      // track Reward Reception success
      const numRewards = rewardReceptions.length;
      let successTracker = 0;
      // Track Reward reception success updates
      const rewardUpdateSuccessTracker = () => {
        successTracker += 1;
        if (successTracker === numRewards) {
          // Update agent last commission
          updateCommission({
            variables: {
              id: lastCommissionId,
              payload: JSON.stringify(payload),
            },
          }).then(
            (result) => handleSuccess(),
            (error) => handleError(error),
          );
        }
      };
      // update reward reception
      rewardReceptions.forEach((reward) => {
        const id = reward.rewardReceptionId;
        const load = { isCash: reward.isCash, userEmail };
        updateReward({
          variables: { id: id, payload: JSON.stringify(load) },
          refetchQueries: [
            {
              query: graphQlQueries[props.country]['awaited-reward'],
              variables: {
                territoryName: props.territoryName,
              },
            },
          ],
        }).then(
          (response) => rewardUpdateSuccessTracker(),
          (error) => handleError(error),
        );
      });
    });
  };

  // Form submission success handler
  const handleSuccess = () => {
    setProgress('');
    setSuccess('Action added successfully');
    handleToggler();
  };

  const handleToggler = () => {
    const succeed = stateRef.current;
    if (succeed) {
      setTimeout(() => {
        setSuccess('');
        if (errors.error) {
          setErrors({
            error: false,
            errorMessage: '',
          });
        }
        setEmptyModal('');
        props.toggler();
      }, 2000);
    } else {
      if (errors.error) {
        setErrors({
          error: false,
          errorMessage: '',
        });
      }
      setEmptyModal('');
      props.toggler();
    }
    // reset form field
    const array = Array.apply(null, Array(props.data.length)).map(() => {
      return {
        commissionId: '',
        amount: '',
        note: '',
        physicalReward: '',
        rewardType: '',
      };
    });
    handleformFields(array);
  };

  const handleError = (err) => {
    setProgress('');
    err.graphQLErrors.map(({ message }) =>
      setErrors({
        error: true,
        errorMessage: message,
      }),
    );
  };

  return (
    <>
      <div className="d-inline mr-3">
        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={props.showModal}
          toggle={handleToggler}
        >
          <div className="modal-header">
            <h4 className="modal-title">
              {props.selectedAction} from selected agents
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleToggler}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="text-center text-danger mb-2">
            <small>{errors.error ? errors.errorMessage : ''}</small>
          </div>
          <div className="text-center text-danger mb-2">
            <small>{emptyModal ? emptyModal : ''}</small>
          </div>
          <div className="text-center text-success mb-2">
            <small>{progress ? progress : ''}</small>
          </div>
          <div className="text-center text-success mb-2">
            <small>{success ? success : ''}</small>
          </div>
          <div className="modal-body p-0">
            {/* modal body based on the selected action */}
            <PaymentRewardModalBody
              agentRewards={props.selectedRewards}
              handleCleanData={handleFormChange}
            />
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleToggler}
            >
              Cancel
            </Button>
            {!duplicateError ? (
              <Button
                color="primary"
                className="ml-auto"
                type="button"
                onClick={handleActionSubmit}
              >
                Okay
              </Button>
            ) : (
              ''
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default RewardPaymentModal;
