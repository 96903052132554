import React, {useState} from 'react'

import { Query } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { GET_ALL_AGENTS } from '../gql/gql';
import { GET_SHOP_REVENUES, GET_NEW_PRCING_GROUPS, GET_SHOPS_LEADERBOARD } from '../gql/gql-sec';
import { constructAgentsLeaderboard } from '../../utils/utils';
import { changeNumberFormat } from '../../utils/helpers';
import AgentLeaderBoard from './agent-leaderboard'



const LeaderBoard = ({
  startDate,
  endDate,
  exchanger,
  leaderboardName='Agent Leaderboard',
  shopLeaderboard=false
}) => {

  const [excelReportData, setExportReportData] = useState([]);

  // Excel related info
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formattedStart = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const fileName = formattedStart !== formattedEnd ? `${formattedStart} - ${formattedEnd} ${leaderboardName}` : `${formattedStart} ${leaderboardName}`;
  
  // Load agents
  const { data, loading, error } = useQuery(GET_ALL_AGENTS);

  // Load New Pricing Groups
  const { data:groupData, loading:groupLoading, error:groupError } = useQuery(GET_NEW_PRCING_GROUPS);

  // Load Shops
  const { data:shopData, loading:shopLoading, error:shopError } = useQuery(GET_SHOPS_LEADERBOARD);

  // Loading Pricing Groups
  if (groupLoading) return <p className='text-white'>LOADING...</p>;
  if (groupError) return `Error! ${groupError.message}`;
  if (!groupData) return `NOT FOUND`;
  const pricingGroups = groupData.allNewPricingGroups.edges.map(group => group.node);

  // Loading agents
  if (loading) return <p className='text-white'>LOADING...</p>;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;
  const users = data.allAgents.edges.map(agent => agent.node);
  const agents = users.filter(user => {
    return user.role.toLowerCase() === 'agent' || user.role.toLowerCase() === 'agent - urban'
  }).filter(user => user.archived === false).filter(user => user.district !== null);

  // shops
  if (shopLoading) return <p className='text-white'>LOADING...</p>;
  if (shopError) return `Error! ${shopError.message}`;
  if (!shopData) return `NOT FOUND`;
  const shops = shopData.allShops.edges.map(shop => shop.node);


  const entity =  shopLeaderboard ? shops : agents;
  const excelEntityName = shopLeaderboard ? 'Shop Name' : 'Agent Full Name';

  const generateLeaderboardData = (saleEdges) => {
    const sales = saleEdges.map(sale => {
      return sale.node;
    });
    // Construct export  report data
    const excelData = constructAgentsLeaderboard(entity, pricingGroups, sales, shopLeaderboard);
    // Update excel report data state
    setExportReportData(excelData);
  }

  const exportToExcel = (excelData, fileName) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const end = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const wsCols = excelData.map((item, index) => { 
      if (index === 0) {return {wch: 19 }}
      if (index === 1) {return {wch: 30 }}
      return { wch: 13 } });
    // Adding 4 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 4)).map(item => { return { hpt: 20 } });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A3' });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} ${leaderboardName}`
    ws['B1'] = { t: 's', v: title };

    // // Change headers Name
    ws.A3.v = 'Territory Name'; ws.B3.v = excelEntityName; ws.C3.v = 'Total Points';

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws['!ref'].split(':')[0]
    const lastRefCell = ws['!ref'].split(':')[1]

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1,));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws['!ref'] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ['C'], '#,##0')


    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' }; ws[`B${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(B4:B${lastRefCellRow})`, z: '#,##0' };
    ws[`C${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(C4:C${lastRefCellRow})`, z: '#,##0' };

    /* Write total data */
    const wb = { Sheets: { 'Shop Staff bonuses': ws }, SheetNames: ['Shop Staff bonuses'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  //  excel exporter to call from parent component
  const leaderboardExcelExporter = () => {
    exportToExcel(excelReportData, fileName)

  }

  // Add the child function to exchanger that might be called by the parent.
  exchanger.leaderboardExcelExporter = leaderboardExcelExporter;

  return (
    <>
    <div>
      {/*Normal Table*/}
      <Query query={GET_SHOP_REVENUES}
        variables={{ range: `${startDate},${endDate}` }}
        fetchPolicy='no-cache'
        onCompleted={data => generateLeaderboardData(data.allSales.edges)}
      >
        {({ loading, error, data }) => {
          if (loading) return <p className="text-white">LOADING...</p>
          if (error) {
            return <p>Error. Try refreshing the page</p>
          }

          return <AgentLeaderBoard commissionData={excelReportData} shopLeaderboard={shopLeaderboard}/>
        }}
      </Query>
    </div>
     
    </>
  )
}

export default LeaderBoard;
