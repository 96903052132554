import React from 'react'

// reactstrap components
import {
  Row,
  Container, Card, CardBody, Col, CardTitle
} from "reactstrap";
import {Link} from "react-router-dom";


const Reports = () => {

  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Card className="card-stats mb-4">
            <CardBody>
              <Row className="justify-content-between">
                <Col md="6">
                  <CardTitle
                    tag="h5"
                    className="mb-0 territory-name"
                  >
                    Sierra Leone Reports
                  </CardTitle>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Container fluid>
          <Row>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/monthly-report`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Agent's Monthly Report
                      </CardTitle>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-calendar-alt"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <small className="font-italic font-weight-bold">Allowance, Commission,<br/>Reward, Paid, and
                      Withheld</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/supervisor-bonuses`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Supervisor Bonuses
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                            {/*2,356*/}
                          </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-hand-holding-usd"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <small className="font-italic font-weight-bold"> Commission and<br/>Percentage Bonuses</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/ba-commissions`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Brand Ambassadors' Commissions
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                            {/*2,356*/}
                          </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-hand-holding-usd"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <small className="font-italic font-weight-bold"> BAs<br/>Commissions</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/staff-bonuses`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Shop Staff bonuses
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                            {/*2,356*/}
                          </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-hand-holding-usd"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <small className="font-italic font-weight-bold"> Shop Staff<br/>Bonuses</small>
                  </p>
                </CardBody>
              </Card>
            </Col>
            
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/agent-leaderboard`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Agents Leaderboard
                      </CardTitle>
                      {/*<span className="h2 font-weight-bold mb-0">924</span>*/}
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-toolbox"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/*<span className="text-warning mr-2">*/}
                    {/*  <i className="fas fa-arrow-down" /> 1.10%*/}
                    {/*</span>{" "}*/}
                    <span className="text-nowrap">Agents <br/> Leaderboard</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/shop-leaderboard`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Shops Leaderboard
                      </CardTitle>
                      {/*<span className="h2 font-weight-bold mb-0">924</span>*/}
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-toolbox"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/*<span className="text-warning mr-2">*/}
                    {/*  <i className="fas fa-arrow-down" /> 1.10%*/}
                    {/*</span>{" "}*/}
                    <span className="text-nowrap">Shops <br/> Leaderboard</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="4">
              <Card className="card-stats mb-4" tag={Link}
              to={{
                pathname: `/admin/monthly-reports/asset-finance`,
              }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Asset Finance Scheme
                      </CardTitle>
                      {/*<span className="h2 font-weight-bold mb-0">924</span>*/}
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-toolbox"/>
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {/*<span className="text-warning mr-2">*/}
                    {/*  <i className="fas fa-arrow-down" /> 1.10%*/}
                    {/*</span>{" "}*/}
                    <span className="text-nowrap">Asset Finance <br/> Scheme</span>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  )
}

export default Reports
