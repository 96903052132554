import React, {useState} from 'react'

import { Query } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { GET_TERRITORIES, GET_DATE_RANGED_COMMISSIONS_SUPERVISOR, GET_SUPERVISORS } from "../gql/gql"
import SupervisorMonthlyTable from "./supervisor-monthly";
import { constructMonthlySupervisorBonuses, changeNumberFormat } from '../../utils/helpers';


const SupervisorBonuses = ({startDate, endDate, exchanger}) => {

  const [bonusesData, setBonusesData] = useState([]);

  // Excel related info
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const formattedStart = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const fileName = formattedStart !== formattedEnd ? `${formattedStart} - ${formattedEnd} Bonuses Report` : `${formattedStart} Bonuses Report`;

  // Loading territories
  const { data, loading, error } = useQuery(GET_TERRITORIES);
  // Loading supervisors
  const { data: supData, loading: load, error: err } = useQuery(GET_SUPERVISORS);

  if (loading) return <p className="text-white">LOADING...</p>;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;
  const territories = data.allTerritories.edges.map(territory => territory.node);

  
  if (load) return <p className="text-white">LOADING...</p>;
  if (err) return `Error! ${error.message}`;
  if (!supData) return `NOT FOUND`;
  const supervisors = supData.allSupervisors.edges.map(supervisor => supervisor.node);


  const generateBonusesData = (commissionEdges) => {
    const reportCommissions = commissionEdges.map(commission => {
      return commission.node;
    });
    // Construct the excel export data
    const excelBonusData = constructMonthlySupervisorBonuses(territories, reportCommissions, supervisors, true);
    // Update excel report data state
    setBonusesData(excelBonusData);
  }

  const exportToExcel = (excelData, fileName) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const end = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const wsCols = excelData.map((item, index) => { 
      if (index === 2) {return {wch: 19 } }
      return { wch: 12 } });
    // Adding 4 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 4)).map(item => { return { hpt: 20 } });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: "A3" });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} Supervisor Bonuses Report`
    ws["C1"] = { t: 's', v: title };

    // // Change headers Name
    ws.A3.v = "Territory Name"; ws.B3.v = "Territory Abbreviation"; ws.C3.v = "Supervisor Name";
    ws.D3.v = "Username"; ws.E3.v = "Number of Agents"; ws.F3.v = "Commissions"; ws.G3.v = "Bonuses";

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws["!ref"].split(':')[0]
    const lastRefCell = ws["!ref"].split(':')[1]

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1,));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws["!ref"] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ["F", "G"], "#,##0")

    // // merge total cell A12 and B12
    const merge = [{ s: { r: lastRefCellRow, c: 0 }, e: { r: lastRefCellRow, c: 3 } }];
    ws["!merges"] = merge;

    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' }; ws[`E${lastRefCellRow + 1}`] = { t: "n", f: `SUM(E4:E${lastRefCellRow})`, z: "#,##0" };
    ws[`F${lastRefCellRow + 1}`] = { t: "n", f: `SUM(F4:F${lastRefCellRow})`, z: "#,##0" }; ws[`G${lastRefCellRow + 1}`] = { t: "n", f: `SUM(G4:G${lastRefCellRow})`, z: "#,##0" };

    /* Write total data */
    const wb = { Sheets: { 'Supervisor bonuses': ws }, SheetNames: ['Supervisor bonuses'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  //  excel exporter to call from parent component
  const bonusExcelExporter = () => {
    exportToExcel(bonusesData, fileName)

  }

  // Add the child function to exchanger that might be called by the parent.
  exchanger.bonusExcelExporter = bonusExcelExporter;

  return (
    <>
    <div>
      {/*Normal Table*/}
      <Query query={GET_DATE_RANGED_COMMISSIONS_SUPERVISOR}
        variables={{ range: `${startDate},${endDate}` }}
        fetchPolicy='no-cache'
        onCompleted={data => generateBonusesData(data.allCommissions.edges)}
      >
        {({ loading, error, data }) => {
          if (loading) return <p className="text-white">LOADING...</p>
          if (error) {
            return <p>Error. Try refreshing the page</p>
          }

          return <SupervisorMonthlyTable
            commissions={data.allCommissions.edges}
            territories={territories}
            supervisors={supervisors}/>
        }}
      </Query>
    </div>
     
    </>
  )
}

export default SupervisorBonuses
