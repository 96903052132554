import React from 'react';
// reactstrap components
import { Input, Table, Media } from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';

import { GET_SYSTEM_REWARD } from '../gql/gql';

import { extractNumberFromString } from '../../utils/helpers';

const RewardModalBody = ({ commissions, handleChange }) => {
  // Load point system rewards
  const { data, loading, error } = useQuery(GET_SYSTEM_REWARD);
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  return (
    <Table
      className="align-items-center table-flush custom-table"
      hover
      responsive
    >
      <thead className="thead-light">
        <tr>
          <th scope="col">Agent Name</th>
          <th scope="col">Reward Type</th>
          <th scope="col">
            Amount <br /> Or Reward
          </th>
          <th scope="col">
            Date <br /> Select
          </th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody>
        {commissions.map((commission, index) => {
          const rewardPoint = extractNumberFromString(commission.rewardType);
          const pointReward = data.allPointSystemRewards.filter(
            (item) => item.numberOfPoint === rewardPoint,
          );
          let cashValue;
          if (pointReward.length) {
            cashValue = pointReward[0].cash;
          }

          return (
            <tr key={commission.id}>
              <td>
                <Media className="text-sm font-weight-bold">
                  {commission.agentFullName}
                  <span className="pl-1">({commission.agentUsername})</span>
                </Media>
              </td>
              <td>
                <p className="h5">
                  {commission.rewardType} <br />
                  {cashValue ? (
                    <span className="text-success ml-5">
                      (
                      {cashValue
                        ? new Intl.NumberFormat().format(cashValue)
                        : ''}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <h4 className="mr-2 mb-0">Le</h4>
                  <Input
                    name="amount"
                    type="number"
                    bsSize="sm"
                    placeholder={
                      cashValue
                        ? new Intl.NumberFormat().format(cashValue)
                        : 'No Amount Found'
                    }
                    data-id={commission.id}
                    onChange={(e) => handleChange(e, index)}
                    data-reward={commission.rewardType}
                  />
                </div>{' '}
                <br />
                <div className="d-flex align-items-center">
                  <h4 className="mr-2 mb-0">Or</h4>
                  <Input
                    type="select"
                    name="physicalReward"
                    id="exampleSelect"
                    bsSize="sm"
                    data-id={commission.id}
                    onChange={(e) => handleChange(e, index)}
                    data-reward={commission.rewardType}
                  >
                    <option value="">------------</option>
                    <option value="Physical Reward">Physical Reward</option>
                  </Input>
                </div>
              </td>
              <td>
                {/* Date <br /> Selection */}
                {new Date(commission.date).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </td>
              <td>
                <Input
                  type="textarea"
                  placeholder="Reference note"
                  maxLength="50"
                  name="note"
                  id="exampleText"
                  bsSize="sm"
                  data-id={commission.id}
                  onChange={(e) => handleChange(e, index)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default RewardModalBody;
