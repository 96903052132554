import React, { useState } from 'react';

import { Query } from 'react-apollo';

import DateFilter from '../layouts/date-filter';
import SupervisorDetails from './supervisor-details';
import { graphQlQueries } from '../gql/main-gql';
import { countryVariables } from '../../utils/country-variables';
import { decrypt } from '../../utils/utils-2';

const SingleSupervisor = (props) => {
  // Capturing the territoriy abbreviation passed as parameters
  const {
    match: { params },
  } = props;
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');
  const country = decrypt(localStorage.getItem('country'));

  const supervisorLabel = country
    ? countryVariables[country]['supervisorLabel']
    : '';

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <DateFilter parentDateFilter={dateFiltering} />
          <Query
            query={graphQlQueries[country]['supervisor-detail']}
            variables={{ username: params.username }}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return <p>Error. Try refreshing the page</p>;
              }

              return (
                <SupervisorDetails
                  data={data[supervisorLabel]}
                  currentThursday={
                    filteringDate ? filteringDate : latestThursday
                  }
                  country={country}
                />
              );
            }}
          </Query>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default SingleSupervisor;
