import React from 'react';
import CurrencyFormat from 'react-currency-format';

// reactstrap components
import { Card, CardBody, Row, Col, Container } from 'reactstrap';

import TerritoryAgentsList from '../territory/territory-agents';

import {
  supervisorTotalRemitted,
  supervisorExpectedRemittance,
  supervisorCommissions,
  getSupervisorActualRevenue,
  getSupervisorTargetRevenue
} from '../../utils/supervisor-utils';

import { simplePercentage, formatPhoneNumber } from '../../utils/helpers';
import { countryVariables } from '../../utils/country-variables';

// update Sierra Leone data
countryVariables['sierra leone'].supervisorDetail.remittance.func = supervisorExpectedRemittance;
countryVariables['sierra leone'].supervisorDetail.remitted.func = supervisorTotalRemitted;

// Update Liberia data
countryVariables['liberia'].supervisorDetail.remittance.func = getSupervisorTargetRevenue;
countryVariables['liberia'].supervisorDetail.remitted.func = getSupervisorActualRevenue;


const SupervisorDetails = ({ data, currentThursday, country }) => {
  const supervisorAgentLabel =
    countryVariables[country]['supervisorAgentLabel'];
  const commissions = supervisorCommissions(
    data[supervisorAgentLabel].agents,
    currentThursday,
  );

  const supervisorVariable = countryVariables[country]['supervisorDetail'];
  const currenySymbol = countryVariables[country]['currencySymbol'];

  const remitProps = {
    remittance: supervisorVariable.remittance.func(
      data[supervisorAgentLabel].agents,
      currentThursday,
    ),
    toRemit:
      supervisorVariable.remittance.func(
        data[supervisorAgentLabel].agents,
        currentThursday,
      ) -
      supervisorVariable.remitted.func(
        data[supervisorAgentLabel].agents,
        currentThursday,
      ),
    remitted: supervisorVariable.remitted.func(
      data[supervisorAgentLabel].agents,
      currentThursday,
    ),
    simplePercentage: simplePercentage(
      supervisorVariable.remitted.func(
        data[supervisorAgentLabel].agents,
        currentThursday,
      ),
      supervisorVariable.remittance.func(
        data[supervisorAgentLabel].agents,
        currentThursday,
      ),
    ),
  };

  return (
    <>
      <Container fluid>
        <Row className="align-items-center mb-4">
          <Col md="12" lg="12" xl="12">
            <div className="card-stats overview-stats bg-transparent">
              <Row className="align-items-center justify-content-between">
                <Col md="5">
                  <Card className="card-stats mb-3">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col md="12">
                          <div className="mb-0 territory-name align-items-center">
                            <div className="avatar avatar-xl rounded-circle mr-3">
                              <img
                                alt="..."
                                className="avatar-xl rounded-circle"
                                src={
                                  data.userPhoto === '0'
                                    ? require('../../assets/img/profile/default-avatar.png')
                                    : data.userPhoto
                                }
                              />
                            </div>
                            <div>
                              <h2 className="">
                                {data.firstName} {data.lastName}{' '}
                                <strong>({data.username})</strong>
                              </h2>
                              <p>{formatPhoneNumber(data.primaryPhone)}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={{ size: 6, offset: 1 }}>
                  <Row>
                    <Col md="6">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-shape bg-white text-primary rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                        <span className="pl-2">
                          <h1 className="text-white mb-0">
                            {data[supervisorAgentLabel].agents.length}
                          </h1>
                        </span>
                      </div>
                      <Row className="justify-content-between text-white">
                        <Col md={6}>
                          <small>{supervisorVariable.remittance.label}</small>
                        </Col>
                        <Col md={6} className="text-right text-nowrap">
                          <CurrencyFormat
                            value={supervisorVariable.remittance.func(
                              data[supervisorAgentLabel].agents,
                              currentThursday,
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-between text-white">
                        <Col md={6}>
                          <small>{supervisorVariable.toRemit.label}</small>
                        </Col>
                        <Col md={6} className="text-right text-nowrap">
                          <CurrencyFormat
                            value={
                              supervisorVariable.remittance.func(
                                data[supervisorAgentLabel].agents,
                                currentThursday,
                              ) -
                              supervisorVariable.remitted.func(
                                data[supervisorAgentLabel].agents,
                                currentThursday,
                              )
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" className="">
                      <div className="text-success stats-simplePercentage text-right mb-1">
                        {simplePercentage(
                          supervisorVariable.remitted.func(
                            data[supervisorAgentLabel].agents,
                            currentThursday,
                          ),
                          supervisorVariable.remittance.func(
                            data[supervisorAgentLabel].agents,
                            currentThursday,
                          ),
                        )}
                        %
                      </div>
                      <div className="d-flex justify-content-end text-success">
                        <span className="pr-3">
                          <small>{supervisorVariable.remitted.label}</small>
                        </span>
                        <span className="text-right text-nowrap">
                          <CurrencyFormat
                            value={supervisorVariable.remitted.func(
                              data[supervisorAgentLabel].agents,
                              currentThursday,
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <TerritoryAgentsList
        data={commissions}
        territoryName={data.name}
        supervisorName={`${data.firstName} ${data.lastName} (${data.username})`}
        currentThursday={currentThursday}
        remitProps={remitProps}
        country={country}
      />
    </>
  );
};

export default SupervisorDetails;
