import React, { useState } from 'react';

// reactstrap components
import { Row, Container, Col, Button } from 'reactstrap';

import moment from 'moment';
import DateRangeFilter from '../../utils/date-range-filter';

import AgentsMonthlyReports from './agents-monthly-reports';
import SupervisorBonuses from './supervisor-bonus';
import ShopStaffBonuses from './shop-staff-bonus';
import BrandAmbassadorCommissions from './ba-commissions';
import NewBrandAmbassadorCommissions from './new-ba-commissions'
import LeaderBoard from './leaderboard';
import AssetFinanceScheme from './asset-finance';

const ReportHeader = (props) => {
  // Capturing report type props passed to the route
  const { match: { params } } = props;
  const reportType = params.reportType;
  const type = ['agent-leaderboard', 'shop-leaderboard'];
  const dayPicker = type.includes(reportType) ? true : false;

  // Object that can pass information between parent and children
  let exchanger = {};

  // Pick previous month first and last date
  const lastMonthStart = moment()
    .subtract(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastMonthEnd = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD');

  // Current month dates
  const date = new Date();
  const currentMonthEnd = new Date();
  const todayDay = currentMonthEnd.getDate();
  const currentMonthStart = new Date(
    date.getFullYear(),
    date.getMonth(),
    todayDay - 7,
  );

  const defaultStartDate = dayPicker ? currentMonthStart : lastMonthStart;
  const defaultEndDate = dayPicker ? currentMonthEnd : lastMonthEnd;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleSelectedDateRange = (event, dayPicker = false) => {
    if (dayPicker) {
      if (event.name === 'start') {
        setStartDate(event.date);
      } else {
        setEndDate(event.date);
      }
    } else {
      if (event.target.name.startsWith('start')) {
        setStartDate(event.target.value);
      } else {
        setEndDate(event.target.value);
      }
    }
  };

  const handleExcelExport = () => {
    // exporting monthly reports excel file
    if (reportType === 'monthly-report') {
      exchanger.reportExcelExporter();
    }
    if (reportType === 'supervisor-bonuses') {
      exchanger.bonusExcelExporter();
    }
    if (
      reportType === 'ba-commissions' ||
      reportType === 'pds-ba-commissions'
    ) {
      exchanger.baCommissionExcelExporter();
    }
    if (reportType === 'staff-bonuses') {
      exchanger.staffBonusExcelExporter();
    }
    if (
      reportType === 'agent-leaderboard' ||
      reportType === 'shop-leaderboard'
    ) {
      exchanger.leaderboardExcelExporter();
    }
    if (reportType === 'asset-finance') {
      exchanger.assetFinanceExcelExporter();
    }
  };

  let componentToRedirect;
  if (reportType === 'monthly-report') {
    componentToRedirect = (
      <AgentsMonthlyReports
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
      />
    );
  }
  if (reportType === 'supervisor-bonuses') {
    componentToRedirect = (
      <SupervisorBonuses
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
      />
    );
  }
  if (reportType === 'ba-commissions') {
    componentToRedirect = (
      <NewBrandAmbassadorCommissions
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
      />
    );
  }
  if (reportType === 'staff-bonuses') {
    componentToRedirect = (
      <ShopStaffBonuses
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
      />
    );
  }

  if (reportType === 'pds-ba-commissions') {
    componentToRedirect = (
      <BrandAmbassadorCommissions
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
        pdsBA={true}
      />
    );
  }
  if (reportType === 'agent-leaderboard') {
    componentToRedirect = (
      <LeaderBoard
        startDate={moment(startDate).format('YYYY-MM-DD')}
        endDate={moment(endDate).format('YYYY-MM-DD')}
        exchanger={exchanger}
      />
    );
  }
  if (reportType === 'shop-leaderboard') {
    componentToRedirect = (
      <LeaderBoard
        startDate={moment(startDate).format('YYYY-MM-DD')}
        endDate={moment(endDate).format('YYYY-MM-DD')}
        exchanger={exchanger}
        leaderboardName="Shop Leaderboard"
        shopLeaderboard={true}
      />
    );
  }
  if (reportType === 'asset-finance') {
    componentToRedirect = (
      <AssetFinanceScheme
        startDate={startDate}
        endDate={endDate}
        exchanger={exchanger}
      />
    );
  }

  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="align-items-center">
            <Col md="12" lg="12" xl="12">
              <div className="d-flex justify-content-between align-items-end mb-2">
                {/* Date range filter */}
                <div className="d-flex align-items-end">
                  <DateRangeFilter
                    HandleDateChange={handleSelectedDateRange}
                    dayPicker={dayPicker}
                    pickerStartDate={startDate}
                    pickerEndDate={endDate}
                  />
                  <Button color="info" onClick={handleExcelExport}>
                    Download Report
                  </Button>
                </div>

                <h1 className="text-white text-right text-capitalize mb-0">
                  {dayPicker
                    ? moment(startDate).format('MMM DD YYYY')
                    : new Date(startDate).toLocaleString('en-us', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })}{' '}
                  -{' '}
                  {dayPicker
                    ? moment(endDate).format('MMM DD YYYY')
                    : new Date(endDate).toLocaleString('en-us', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })}{' '}
                  <br />
                  {reportType} Overview
                </h1>
              </div>
              {/*Normal Table*/}
              {componentToRedirect}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ReportHeader;
