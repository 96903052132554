import React, { useState } from 'react'

import {Query} from 'react-apollo'

import { GET_SINGLE_AGENT_COMMISSIONS}  from '../gql/gql';
import DateFilter from "../layouts/date-filter";
import AgentFieldProfile from "./agent-field-profile"

const AgentFieldCommissions = props => {

  const params =  props.match ? props.match.params : null;
  const username = localStorage.getItem('username')
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
     <div className="bg-gradient-primary pb-4 pt-md-5 h-100vh pt-md-8">
    <DateFilter parentDateFilter={dateFiltering} agentView="yes"/>
    <Query query={GET_SINGLE_AGENT_COMMISSIONS} variables={{
      agent_Username: params ? params.username : username, agentview:"yes"}}>
      {({loading, error, data}) => {
          if (loading) return <p className="text-white">LOADING...</p>
          if (error) {
            console.log(error.message)
            return <p>Error. Try refreshing the page</p>
          }
          return <AgentFieldProfile commissionData={data}
            currentThursday={filteringDate ? filteringDate: latestThursday}
          />
        }}
    </Query>
    </div>
  </>
  )
}

export default AgentFieldCommissions
