import React from 'react';

import { FormGroup } from 'reactstrap';
import DateSelecter from '../utils/date-select';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DateRangeFilter = ({
  HandleDateChange,
  dayPicker = false,
  pickerStartDate = '',
  pickerEndDate = '',
}) => {
  const handleSelect = (event) => {
    HandleDateChange(event);
  };

  const handleStartSelect = (date) => {
    HandleDateChange({ date, name: 'start' }, (dayPicker = true));
  };

  const handleEndSelect = (date) => {
    HandleDateChange({ date, name: 'end' }, (dayPicker = true));
  };

  return (
    <div className="d-flex align-items-end">
      <h2 className="text-white mr-3">Month Picker</h2>
      <FormGroup className="mr-2 mb-0">
        <p className="label text-white mb-0 font-weight-bold">From</p>
        {dayPicker ? (
          <DatePicker selected={pickerStartDate} onChange={handleStartSelect} />
        ) : (
          <DateSelecter
            name="startDate"
            id="startDateSelect"
            handleParentSelect={handleSelect}
          />
        )}
      </FormGroup>
      <FormGroup className="mr-2 mb-0">
        <p className="label text-white mb-0 font-weight-bold">To</p>
        {dayPicker ? (
          <DatePicker selected={pickerEndDate} onChange={handleEndSelect} />
        ) : (
          <DateSelecter
            name="endDate"
            id="endDateSelect"
            handleParentSelect={handleSelect}
          />
        )}
      </FormGroup>
    </div>
  );
};

export default DateRangeFilter;
