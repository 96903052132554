import * as XLSX from 'xlsx';

import { graphQlQueries } from '../components/gql/main-gql';
import { countryVariables } from './country-variables';

/*  Find total agent from a list of districts
  based on the structure of our graphql  response data
  note that we used alias in the query */
const territoryTotalAgents = (districts, country) => {
  let totalAgents = 0;
  const agentRole = countryVariables[country]['agentRole'];
  districts.forEach((district) => {
    const districtAgent = graphQlQueries[country]['districtAgent'];
    const agents = district.node[districtAgent].agents
      .map((agent) => agent.node)
      .filter((agent) =>
        agentRole.length > 0
          ? agent.role.toLowerCase() === `${agentRole[0]}` ||
            agent.role.toLowerCase() === `${agentRole[1]}`
          : agent.role.toLowerCase() === `${agentRole[0]}`,
      );
    totalAgents += agents.length;
  });
  return totalAgents;
};

/* Find total remitted for a territroy from a list of districts
  based on the structure of our graphql  response data
  note that we used alias in the query */

const dateSelect = '2020-04-09';
const territoryTotalRemitted = (districts, date = dateSelect) => {
  let totalRemitted = 0;
  districts.forEach((district) => {
    const agents = district.node.districtAgent.agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }

      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        totalRemitted += commission.node.totalRemitted;
      });
    });
  });
  return Math.trunc(totalRemitted);
};

/* Find expected remittance for a territroy from a list of districts
  based on the structure of our graphql  response data
  note that we used alias in the query */
const territoryExpectedRemittance = (districts, date = dateSelect) => {
  let expectedRemittance = 0;
  districts.forEach((district) => {
    const agents = district.node.districtAgent.agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        if (commission.node.expectedRemittance > 0) {
          expectedRemittance += commission.node.expectedRemittance;
        }
      });
    });
  });
  return Math.trunc(expectedRemittance);
};

/* Find To Remit for all agents of a Territory
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const territoryTotalToRemit = (districts, date = dateSelect) => {
  return (
    territoryExpectedRemittance(districts, date) -
    territoryTotalRemitted(districts, date)
  );
};

// Return a percentage from two numbers
export const percentageTerritory = (districts, date = dateSelect) => {
  const paid = territoryTotalRemitted(districts, date);
  const expected = territoryExpectedRemittance(districts, date);
  if (expected < 0) {
    return 100;
  }
  const result = paid / expected;
  if (Number.isNaN(result)) {
    return 0;
  }
  // return +(Math.round((result * 100) + "e+2")  + "e-2");
  return Math.round(result * 100);
};

/* Find expected remittance for all agents from a list of territories
  based on the structure of our graphql  response data
  note that we used alias in the query */
const totalExpectedRemittance = (territories, date = dateSelect) => {
  let expectedRemittance = 0;
  territories.forEach((territory) => {
    const districts = territory.terriDistrict.districts;
    districts.forEach((district) => {
      const agents = district.node.districtAgent.agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          if (commission.node.expectedRemittance > 0) {
            expectedRemittance += commission.node.expectedRemittance;
          }
        });
      });
    });
  });
  return Math.trunc(expectedRemittance);
};

/* Find expected remittance for all agents from a list of territories
  based on the structure of our graphql  response data
  note that we used alias in the query */
const totalRemitted = (territories, date = dateSelect) => {
  let totalRemitted = 0;
  territories.forEach((territory) => {
    const districts = territory.terriDistrict.districts;
    districts.forEach((district) => {
      const agents = district.node.districtAgent.agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          totalRemitted += commission.node.totalRemitted;
        });
      });
    });
  });
  return Math.trunc(totalRemitted);
};

/* Find To Remit for all agents from a list of territories
  based on the structure of our graphql  response data
  note that we used alias in the query */
const totalToRemit = (territories, date = dateSelect) => {
  return (
    totalExpectedRemittance(territories, date) -
    totalRemitted(territories, date)
  );
};

// Return a percentage from territories
const percentage = (territories, date = dateSelect) => {
  const paid = totalRemitted(territories, date);
  const expected = totalExpectedRemittance(territories, date);
  if (expected < 0) {
    return 100;
  }
  const result = paid / expected;
  if (Number.isNaN(result)) {
    return 0;
  }
  // return +(Math.round((result * 100) + "e+2")  + "e-2");
  return Math.round(result * 100);
};

// Return a percentage from two numbers
export const simplePercentage = (paid, expected) => {
  if (expected < 0) {
    return 100;
  }
  const result = paid / expected;
  if (Number.isNaN(result)) {
    return 0;
  }
  // return +(Math.round((result * 100) + "e+2")  + "e-2");
  return Math.round(result * 100);
};

// Return difference between two variable
export const simpleDiff = (first, second) => {
  return first - second;
};

/* Find total of all agents
  based on the structure of our graphql  response data
  note that we used alias in the query */
const totalAgents = (territories, country) => {
  let totalAgents = 0;
  const agentRole = countryVariables[country]['agentRole'];
  territories.forEach((territory) => {
    const territoryDistrict = graphQlQueries[country]['territoryDistrict'];
    const districts = territory[territoryDistrict].districts;
    districts.forEach((district) => {
      const districtAgent = graphQlQueries[country]['districtAgent'];
      const agents = district.node[districtAgent].agents
        .map((agent) => agent.node)
        .filter((agent) =>
          agentRole.length > 0
            ? agent.role.toLowerCase() === `${agentRole[0]}` ||
              agent.role.toLowerCase() === `${agentRole[1]}`
            : agent.role.toLowerCase() === `${agentRole[0]}`,
        );
      totalAgents += agents.length;
    });
  });
  return totalAgents;
};

/* Find total agents that should receive rewards from a list of territories
  based on the structure of our graphql  response data
  note that we used alias in the query */
const totalRewards = (
  territories,
  date = dateSelect,
  country = 'sierra leone',
) => {
  let totalReward = 0;
  territories.forEach((territory) => {
    const territoryDistrict = graphQlQueries[country]['territoryDistrict'];
    const districts = territory[territoryDistrict].districts;
    districts.forEach((district) => {
      const districtAgent = graphQlQueries[country]['districtAgent'];
      const agents = district.node[districtAgent].agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          const reward = commission.node.reward !== '-' ? 1 : 0;
          totalReward += reward;
        });
      });
    });
  });
  return Math.trunc(totalReward);
};

/* Find agents commission under a territory filtered by a date
  based on the structure of our graphql  response data
  note that we used alias in the query */
const territoryCommissions = (districts, date, country) => {
  const territoryCommissions = [];
  districts.forEach((district) => {
    const districtAgent = graphQlQueries[country]['districtAgent'];
    const agents = district.node[districtAgent].agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        territoryCommissions.push(commission.node);
      });
    });
  });
  return territoryCommissions;
};

/* Find agents commission by querying directly the commission filtered by a date
  based on the structure of our graphql  response data */
const singleAgentCommissions = (commissions, date = dateSelect) => {
  const agentCommissions = [];
  const latestThursday = date;
  const filteredcommissions = commissions.filter((commission) => {
    return commission.node.currentWeekThursday === latestThursday;
  });

  filteredcommissions.forEach((commission) => {
    agentCommissions.push(commission.node);
  });
  if (!agentCommissions.length) {
    return [];
  }
  return agentCommissions;
};

/* Format phone numbers provided by Angaza */
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {return ''}
  if (phoneNumber.endsWith('.0')) {
    const list = phoneNumber.split('.');
    return `+${list[0]}`;
  }
  return phoneNumber;
};

/* Gather Rewards by agent based on the
   graphql schema
 */
const getRewardByagent = (list) => {
  const result = [];
  for (const reward of list) {
    const agentIndex = result.findIndex((item) => item.id === reward.agent.id);
    if (agentIndex > -1) {
      result[agentIndex].items.push(reward);
    } else {
      const fullName = `${reward.agent.firstName} ${reward.agent.lastName}`;
      console.log(reward.agent.username);
      const agentCommissions = reward.agent.agentCommission.edges;
      console.log(agentCommissions)

      if (agentCommissions.length > 0) {
        const lastCommission = agentCommissions[agentCommissions.length - 1];
        const agentTotalPoints = lastCommission.node.totalPoints;
        const currentWeekThursday = lastCommission.node.currentWeekThursday;
        const lastCommissionId = lastCommission.node.id;
        const agentPhone = reward.agent.primaryPhone;
        result.push({
          id: reward.agent.id,
          username: reward.agent.username,
          fullName: fullName,
          totalPoints: agentTotalPoints,
          agentPhone: agentPhone,
          currentWeekThursday: currentWeekThursday,
          items: [reward],
          lastCommissionId: lastCommissionId,
        });
      }
    }
  }
  return result;
};

/* Extract number from a string */
const extractNumberFromString = (string) => {
  //  string.replace(/\D/g, '');
  // Check if there is a digits in the string and return the first occurence
  const match = string.match(/\d+/);
  if (match) {
    // reward points are integers
    return parseInt(match[0]);
  } else {
    return 0;
  }
};

/* Get select 2 type of select option based on  reward reception table*/
const getSelectOptions = (agentReward) => {
  const options = [];
  for (let i = 0; i < agentReward.rewardItems.length; i++) {
    const rewardReception = agentReward.rewardItems[i];
    const itemValue1 = {
      agentId: agentReward.id,
      rewardReceptionId: rewardReception.id,
      rewardPoints: rewardReception.pointSystemReward.numberOfPoint,
      rewardCash: rewardReception.pointSystemReward.cash,
      rewardId: rewardReception.pointSystemReward.id,
      lastCommissionId: agentReward.agentLastCommissionId,
      isCash: true,
    };
    const itemValue2 = {
      agentId: agentReward.id,
      rewardReceptionId: rewardReception.id,
      rewardPoints: rewardReception.pointSystemReward.numberOfPoint,
      rewardCash: rewardReception.pointSystemReward.cash,
      rewardId: rewardReception.pointSystemReward.id,
      lastCommissionId: agentReward.agentLastCommissionId,
      isCash: false,
    };
    const label1 = `P${itemValue1.rewardPoints} Cash`;
    const label2 = `P${itemValue2.rewardPoints} Physical`;
    const option1 = { value: JSON.stringify(itemValue1), label: label1 };
    const option2 = { value: JSON.stringify(itemValue2), label: label2 };
    options.push(option1);
    options.push(option2);
  }
  return options;
};

/* Find duplicate object*/
const findDuplicate = (list, id) => {
  const reducer = list.reduce((acc, cur) => {
    acc[cur[id]] = ++acc[cur[id]] || 0;
    return acc;
  }, {});
  return list.filter((item) => reducer[item[id]]);
};

/* Takes a list of commission  and a list a territories and return an Array of object
  that can be extracted */
const constructMonthlyReportArray = (list, commissions) => {
  const reportData = [];
  if (!list.length) {
    return;
  }
  list.forEach((territory) => {
    const filteredNullDistrict = commissions.filter(
      (commission) => commission.agent.district !== null,
    );
    const territoryCommissions = filteredNullDistrict.filter(
      (commission) =>
        commission.agent.district.territory.name === territory.name,
    );
    const sumRemitCommissions = territoryCommissions.reduce(
      (sum, commission) => {
        return sum + commission.remitCommission;
      },
      0,
    );
    const sumAmountWithheldKYCIssue = territoryCommissions.reduce((sum, commission) => {
      return sum + commission.amountWithheld ;
    }, 0);
    const sumAllowances = territoryCommissions.reduce((sum, commission) => {
      return sum + (commission.weeklyTopup + commission.weeklyOwed);
    }, 0);
    const sumRewards = territoryCommissions.reduce((sum, commission) => {
      return sum + commission.agentOfTheMonthAndOtherRewards;
    }, 0);
    const sumPayments = territoryCommissions.reduce((sum, commission) => {
      return sum + commission.mobilePaymentCommission;
    }, 0);
    const sumPdsAllowances = territoryCommissions.reduce((sum, commission) => {
      return sum + (commission.pdsCommission + commission.pdsBonus);
    }, 0);
    const accrued =
      sumRemitCommissions +
      sumAllowances +
      sumRewards +
      sumPayments +
      sumPdsAllowances;
    const paid = territoryCommissions.reduce((sum, commission) => {
      return sum + commission.angazaAdjustment;
    }, 0);
    const withheld = accrued - paid;
    const territoryReportData = {
      territoryName: territory.name,
      territoryAbbr: territory.abbreviation,
      allowances: sumAllowances,
      commission: sumRemitCommissions,
      rewards: sumRewards,
      payments: sumPayments,
      pdsAllowances: sumPdsAllowances,
      kycWithheld: sumAmountWithheldKYCIssue,
      accrued: accrued,
      paid: paid,
      withheld: withheld,
    };
    reportData.push(territoryReportData);
  });

  return reportData;
};

/* Finds total of monthly report aggreates */ 
const getMonthlyReportTotalAggregates = (reportData) => {
  const totalAllowances = reportData.reduce((sum, data) => {
    return sum + data.allowances;
  }, 0);
  const totalCommissions = reportData.reduce((sum, data) => {
    return sum + data.commission;
  }, 0);
  const totalRewards = reportData.reduce((sum, data) => {
    return sum + data.rewards;
  }, 0);
  const totalPayments = reportData.reduce((sum, data) => {
    return sum + data.payments;
  }, 0);
  const totalPds = reportData.reduce((sum, data) => {
    return sum + data.pdsAllowances;
  }, 0);
  const totalAccrued = reportData.reduce((sum, data) => {
    return sum + data.accrued;
  }, 0);
  const totalPaid = reportData.reduce((sum, data) => {
    return sum + data.paid;
  }, 0);
  const totalWithheld = reportData.reduce((sum, data) => {
    return sum + data.withheld;
  }, 0);
  const totalKycWithheld = reportData.reduce((sum, data) => {
    return sum + data.kycWithheld;
  }, 0);
  return {
    totalAllowances,
    totalCommissions,
    totalRewards,
    totalPds,
    totalKycWithheld,
    totalAccrued,
    totalPaid,
    totalWithheld,
    totalPayments,
  };
};

/* takes a list of commission  and a list a territories and return an Array of territories
  with supervisor and aggreates supervisor monthly bonuses*/
const constructMonthlySupervisorBonuses = (
  territoryList,
  commissions,
  supervisors,
  excel = false,
) => {
  // TODO: need to refactor this function to have less than 50 lines
  const monthlyBonusesData = [],
    monthlyBonusExportData = [];
  territoryList.forEach((territory) => {
    const filteredNullDistrict = commissions.filter(
      (commission) => commission.agent.district != null,
    );
    const territoryCommissions = filteredNullDistrict.filter(
      (commission) =>
        commission.agent.district.territory.name === territory.name,
    );
    const territorySupervisors = supervisors
      .filter((supervisor) => supervisor.territory !== null)
      .filter((supervisor) => supervisor.territory.name === territory.name);
    const supervisorList = [];
    territorySupervisors.forEach((supervisor) => {
      const supervisorCommissions = territoryCommissions
        .filter((commission) => commission.agent.supervisor !== null)
        .filter((commission) => {
          return commission.agent.supervisor.username === supervisor.username;
        });
      const supervisorUsername = supervisor.username;
      const supervisorName = `${supervisor.firstName} ${supervisor.lastName}`;

      const totalOwedForTheWeek = supervisorCommissions.reduce(
        (sum, commission) => {
          return sum + commission.commissionOwedForTheWeek;
        },
        0,
      );

      const totalWeeklyTopup = supervisorCommissions.reduce(
        (sum, commission) => {
          return sum + commission.weeklyTopup;
        },
        0,
      );

      const totalAgentOwed = totalOwedForTheWeek - totalWeeklyTopup;

      // supervisor commission is 10% of totalAgentOwed. this is a temporary
      // solution. the percentage should come from the backend to allow changes
      // to be reflected naturally without changing code
      const supervisorBonus = totalAgentOwed * 0.1;
      const agents = supervisor.agentSup.edges
        .map((agent) => agent.node)
        .filter(
          (agent) =>
            agent.role.toLowerCase() === 'agent' ||
            agent.role.toLowerCase() === 'agent - urban',
        );

      const supervisorAgentsNumber = agents.length;

      const supervisorData = {
        territoryName: territory.name,
        territoryAbbr: territory.abbreviation,
        supervisorName: supervisorName,
        username: supervisorUsername,
        numAgents: supervisorAgentsNumber,
        totalAgentOwed: totalAgentOwed,
        bonus: Math.trunc(supervisorBonus),
      };
      supervisorList.push(supervisorData);
      if (excel) {
        monthlyBonusExportData.push(supervisorData);
      }
    });

    const territoryBonusData = {
      territoryName: territory.name,
      territoryAbbr: territory.abbreviation,
      supervisors: supervisorList,
    };
    monthlyBonusesData.push(territoryBonusData);
  });
  if (excel) {
    return monthlyBonusExportData;
  }
  return monthlyBonusesData;
};

/* Total superviosr bonuses*/
const getTotalMontlyBonuses = (monthlyBonusData) => {
  let totalAgent = 0,
    totalCommissions = 0,
    totalBonuses = 0;
  monthlyBonusData.forEach((item) => {
    const numAgent = item.supervisors.reduce((sum, data) => {
      return sum + data.numAgents;
    }, 0);
    const commissions = item.supervisors.reduce((sum, data) => {
      return sum + data.totalAgentOwed;
    }, 0);
    const bonuses = item.supervisors.reduce((sum, data) => {
      return sum + data.bonus;
    }, 0);
    totalAgent += numAgent;
    totalCommissions += commissions;
    totalBonuses += bonuses;
  });
  return { totalAgent, totalCommissions, totalBonuses };
};

// Change excel file number format
const changeNumberFormat = (ws, colList, format) => {
  for (const colName of colList) {
    const C = XLSX.utils.decode_col(colName); // 1
    const fmt = format; // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

    /* get worksheet range */
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let i = range.s.r + 1; i <= range.e.r; ++i) {
      /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
      const ref = XLSX.utils.encode_cell({ r: i, c: C });
      /* if the particular row did not contain data for the column, the cell will not be generated */
      if (!ws[ref]) continue;
      /* `.t == "n"` for number cells */
      if (ws[ref].t !== 'n') continue;
      /* assign the `.z` number format */
      ws[ref].z = fmt;
    }
  }
};

// extract excel file header
const extractHeader = (ws) => {
  const header = [];
  const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
  for (let i = 0; i < columnCount; ++i) {
    header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
  }
  return header;
};

// Find username
const getUsernameFromEmail = (email) => {
  if (!email) {
    return '';
  }
  const emailWithoutDot = email.split('.')[0];
  const dotSplit = emailWithoutDot.split('.');
  if (dotSplit.length >= 2) {
    return dotSplit[0];
  }
  const atSplit = emailWithoutDot.split('@');
  if (atSplit.length) {
    return atSplit[0];
  }
  return '';
};

// Returns Commission values based on the field passed
// and it object key in the country-variable files
export const commissionValue = (commission, commissionFields, fieldName) => {
  const name = commissionFields[fieldName].name;
  const value = commissionFields[fieldName].value;
  const func = commissionFields[fieldName].func;
  if (name) {
    return commission[name];
  } else if (value) {
    return value;
  } else if (func) {
    return func;
  } else {
    return 0;
  }
};

// get agent target revenue
export const getAgentTargetRevenue = (commission) => {
  return commission.numberOfAnticaptedSales * commission.averageSalesPrice;
}

export default territoryTotalAgents;
export {
  territoryTotalRemitted,
  territoryExpectedRemittance,
  percentage,
  singleAgentCommissions,
  totalExpectedRemittance,
  totalRemitted,
  totalAgents,
  territoryCommissions,
  findDuplicate,
  formatPhoneNumber,
  totalRewards,
  getRewardByagent,
  extractNumberFromString,
  getSelectOptions,
  constructMonthlyReportArray,
  getMonthlyReportTotalAggregates,
  extractHeader,
  constructMonthlySupervisorBonuses,
  getTotalMontlyBonuses,
  changeNumberFormat,
  getUsernameFromEmail,
  totalToRemit,
};
