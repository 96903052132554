const SL_territories = [
    'HQ',
    'Western Urban',
    'Kono',
    'Kailahun',
    'Kenema',
    'Bombali',
    'Falaba',
    'Koinadugu',
    'Tonkolili',
    'Karene',
    'Kambia',
    'Portloko',
    'Bo',
    'Bonthe',
    'Moyamba',
    'Pujehun',
    'Western Rural',
]

const LB_territories = [
    'Bass Cess',
    'Bong',
    'Grand Bomi',
    'Grand Gedeh',
    'Lofa',
    'Margibi',
    'Monsterrado',
    'Nimba',
    'Riverland',
    'Sinoe',
]

export const territories = {
    'sierra leone': SL_territories,
    liberia: LB_territories,
}

