import React, { useState } from 'react';

// reactstrap components
import { FormGroup, Input } from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';

import { GET_ALL_COMMISSIONS } from '../gql/gql';

import { getPreviousNThursday } from '../../utils/helpers-2';

const DateFilter = ({ parentDateFilter, agentView = 'true' }) => {
  let latestThursday = '';
  let fullThursdaysList = [];
  const [useShortView, toggleDateViews] = useState(true);
  const [filteringDate, setFilteringDate] = useState('');

  const { data, loading, error } = useQuery(GET_ALL_COMMISSIONS, {
    variables: { agentview: agentView },
  });

  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  const allCommissions = data.allCommissions.edges.map((commission) => {
    return commission.node;
  });

  if (allCommissions.length) {
    // Get the most recent thursday date based on the backend filtering
    const mostRecentThusdayNode = allCommissions[allCommissions.length - 1];
    latestThursday = mostRecentThusdayNode.currentWeekThursday;
    const allDates = allCommissions.map(
      (commission) => commission.currentWeekThursday,
    );

    const dateList = [...new Set(allDates)].reverse();
    fullThursdaysList = getPreviousNThursday(dateList[0], 10)
  }

  const handleDateView = () => {
    toggleDateViews(false);
    // inputRef.current.focus;
  };

  // Apply filtering by country
  const handleDateFilter = (event) => {
    event.preventDefault();
    const date = event.target.value;
    if (date !== 'more') {
      setFilteringDate(date);
    } else {
      handleDateView();
    }
  };

  // Send filter dates to the parent component
  parentDateFilter(latestThursday, filteringDate);

  // Thursday dates list to display
  const datesToDisplay = agentView === 'yes' ? fullThursdaysList.slice(0,2) : fullThursdaysList;

  return (
    <div className="date-filter d-flex align-items-center mb-lg-3 mb-xl-3 mb-md-3 mb-0 justify-content-lg-start justify-content-md-start justify-content-sm-end justify-content-end">
      <h2 className="text-white mb-0 mr-3">Date Picker</h2>
      <FormGroup className="mr-2">
        <p className="mb-4 text-white" />
        <Input
          type="select"
          name="year"
          id="yearSelect"
          onChange={handleDateFilter}
        >
          {datesToDisplay.map((date, i) => {
            return (
              <option
                value={date}
                key={i}
                className={i > 3 ? (useShortView ? 'hide-option' : '') : ''}
              >
                {' '}
                {new Date(date).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}{' '}
              </option>
            );
          })}
          {useShortView ? (
            <option value="more">More</option>
          ) : (
            ''
          )}
        </Input>
      </FormGroup>
    </div>
  );
};

export default DateFilter;
