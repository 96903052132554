import React from "react";
// reactstrap components
import {
  Input,
  Table,
  Media,
} from "reactstrap";
import CurrencyFormat from 'react-currency-format';


const AdjustmentModalBody = ({ commissions, handleChange }) => {
  return (
    <Table className="align-items-center table-flush custom-table" hover responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Agent Name</th>
          <th scope="col">Deposit <br /> After Remit</th>
          {/* <th scope="col">Loan <br /> Type</th> */}
          <th scope="col">Amount <br /> To Adjust</th>
          <th scope="col">Select<br />Date </th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody>
        {commissions.map((commission, index) => {
          return (
            <tr  key={commission.id}>
              <td>
                <Media className="text-sm font-weight-bold">
                  {commission.agentFullName}<span className="pl-1">({commission.agentUsername})</span>
                </Media>
              </td>
              <td>
                <CurrencyFormat
                  value={commission.debtAfterRemit}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Le '} />
              </td>
              {/* <td>
                <Input type="select" name="select" id="exampleSelect" bsSize="sm">
                  <option>Select Type</option>
                  <option>Add Loan</option>
                  <option>Adjust Loan</option>
                </Input>
              </td> */}
              <td>
                <div className="d-flex align-items-center">
                  <h4 className="mr-2 mb-0">Le</h4>
                  <Input className="" name="amount" placeholder="Enter amount" type="number" bsSize="sm"
                  data-id={commission.id} onChange={(e) => handleChange(e, index)} />
                </div>
              </td>
              <td>
                {/* Date Selecting <br /> Here */}
                {
                new Date(commission.date).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </td>
              <td>
                <Input type="textarea" placeholder="Reason for adjustment" maxLength="50" name="note" id="exampleText" bsSize="sm"
                data-id={commission.id} onChange={(e) => handleChange(e, index)} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default AdjustmentModalBody;
