import React, { useState } from 'react';
import {Col, Row, Spinner} from "reactstrap";

const TableSpinner = ({  }) => {

  return (
    <>
      <Row className="align-items-center">
        <Col lg={12} className="my-5 px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <Spinner color="primary" >Loading...</Spinner>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TableSpinner;
