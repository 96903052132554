/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// core components
import AdminNavbar from '../layouts/adminNavbar';
import Sidebar from '../layouts/adminSidebar';

import whiteLogo from '../../assets/img/brand/easy-logo-1.png';
import AgentFieldProfile from '../agent-profile/agent-field-commissions';
import routes from '../routes.js';

import { decrypt } from '../../utils/utils-2';

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    const email = localStorage.getItem('userEmail');
    const isStaff = decrypt(localStorage.getItem('isStaff'));
    // if there is email stored on localstorage which means
    // no authenticated user, redirect to login
    if (!email) {
      return <Redirect to={{ pathname: '/login' }} />;
    }
    // if the agent is not a staff member show him only his/her profile page
    if (!JSON.parse(isStaff)) {
      return <AgentFieldProfile />;
    }

    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path,
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return '';
  };
  render() {
    const isStaff = decrypt(localStorage.getItem('isStaff'));
    return (
      <>
        {JSON.parse(isStaff) ? (
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: '/admin/index',
              imgSrc: whiteLogo,
              imgAlt: '...',
            }}
          />
        ) : (
          ''
        )}
        <div className="main-content" ref="mainContent">
          {JSON.parse(isStaff) ? (
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
          ) : (
            ''
          )}

          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          {/*<Container fluid>*/}
          {/*  <AdminFooter />*/}
          {/*</Container>*/}
        </div>
      </>
    );
  }
}

export default Admin;
