import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import _ from 'lodash';

import { useMutation } from '@apollo/react-hooks';

// reactstrap components
import {
  Button,
  Card,
  FormGroup,
  Form,
  Label,
  Input,
  Table,
  Row,
  Container,
  CardHeader,
  Media,
  Progress,
  UncontrolledTooltip,
} from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';

import ActionModal from '../layouts/action-modal';

import { simplePercentage, simpleDiff, getAgentTargetRevenue } from '../../utils/helpers';
import { countryVariables } from '../../utils/country-variables';
import { graphQlQueries } from '../gql/main-gql';

// sierra Leone
countryVariables['sierra leone'].territoryAgentTable.toRemit.func = simpleDiff;
countryVariables[
  'sierra leone'
].territoryAgentTable.percentageShow.func = simplePercentage;

// Liberia
countryVariables['liberia'].territoryAgentTable.toRemit.func = simpleDiff;
countryVariables[
  'liberia'
].territoryAgentTable.percentageShow.func = simplePercentage;
countryVariables[
  'liberia'
].territoryAgentTable.balance.func = getAgentTargetRevenue;

const TerritoryAgentsList = ({
  data,
  currentThursday,
  territoryName,
  country,
  supervisorName,
  remitProps,
}) => {
  // eslint-disable-next-line
  const [updateCommission, result = { data }] = useMutation(
    graphQlQueries[country]['update-commission'],
  );

  // Select options avaible for each country
  const availableSelectOptions =
    countryVariables[country].territorySelectOptions;

  const sortedCommissions = _.orderBy(data, ['agent.username'], ['asc']);

  // naming fields based on country
  const commissionFields = countryVariables[country].territoryAgentTable;
  const currencySymbol = countryVariables[country]['currencySymbol'];
  // the name of the variable returned when fetching smses
  const smsLabel = countryVariables[country]['smsLabel'];

  // Add modal state hanlder
  const [showModal, toggleModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('Select Action');
  const [selectedCommissions, setSelectedCommissions] = useState([]);
  // Initialize the data with a random length to make checkboxes controlled component
  // We take 10000 because we do not know before hand the number of agents in a territory
  const [checkedCheckboxes, handleChechedCheckboxes] = useState(
    Array(10000).fill(false),
  );
  const [copy, handleCopy] = useState({ show: false, message: '' });

  //Toggle snapshot class
  const [readySnapshot, unReadySnapshot] = useState(false);

  // Handle copy to clipboar for insecure brower context like http
  // We can user navigator clipboard for all secure context (https, localhost)
  const firstSmsRef = useRef(null);
  const secondSmsRef = useRef(null);
  const thirdSmsRef = useRef(null);

  const handleDeductCommissionOnList = (id, e) => {
    updateCommission({
      variables: { id: id, deductDebtFromCommission: e.target.checked },
    });
  };

  const handleWithholdCommissionOnList = (id, e) => {
    const value = e.target.checked ? 'yes' : 'no';
    updateCommission({ variables: { id: id, withholdingCommission: value } });
  };

  // Toggle modal
  const toggler = () => {
    if (showModal) {
      handleChechedCheckboxes(Array(10000).fill(false));
      setSelectedCommissions([]);
    }
    toggleModal(!showModal);
  };

  // Returns Commission values based on the field passed
  // and it object key in the country-variable files
  const getCommissionValue = (commission, fieldName) => {
    const name = commissionFields[fieldName].name;
    const value = commissionFields[fieldName].value;
    const func = commissionFields[fieldName].func;
    if (name) {
      return commission[name];
    } else if (value) {
      return value;
    } else if (func) {
      return func;
    } else {
      return 0;
    }
  };

  // Handle checkbox changes
  const handleCheckboxClick = (e, index) => {
    let value = checkedCheckboxes.slice();
    value[index] = !value[index];
    handleChechedCheckboxes(value);
  };

  const handleCommissionSelect = (e) => {
    const commission = JSON.parse(e.target.value);
    const id = commission.id;

    if (e.target.checked) {
      setSelectedCommissions((selectedCommissions) => [
        ...selectedCommissions,
        commission,
      ]);
    } else {
      setSelectedCommissions(
        selectedCommissions.filter((item) => item.id !== id),
      );
    }
  };

  // Define props to pass to the modal component
  const props = {
    showModal,
    toggler,
    selectedAction,
    selectedCommissions,
    territoryName,
    data,
    country,
  };

  // Load smses
  const { data: smses, loading, error } = useQuery(
    graphQlQueries[country]['smses'],
    {
      variables: {
        currentWeekThursday: currentThursday,
        territoryName: territoryName,
      },
    },
  );
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  const currentSmses = _.orderBy(smses[smsLabel], ['agent.username'], ['asc']);
  let firstSmses = currentSmses
    .filter((sms) => {
      const number = sms.messageNumber[sms.messageNumber.length - 1];
      return number === '1';
    })
    .map((sms) => sms.textMessage);

  firstSmses = firstSmses.join('--------------------------------\n\n');

  let secondSmses = currentSmses
    .filter((sms) => {
      const number = sms.messageNumber[sms.messageNumber.length - 1];
      return number === '2';
    })
    .map((sms) => sms.textMessage);

  secondSmses = secondSmses.join('--------------------------------\n\n');

  let thirdSmses = currentSmses
    .filter((sms) => {
      const number = sms.messageNumber[sms.messageNumber.length - 1];
      return number === '3';
    })
    .map((sms) => sms.textMessage);

  thirdSmses = thirdSmses.join('--------------------------------\n\n');

  // Copying smses
  const copySmses = (action) => {
    if (action === 'First Smses') {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(firstSmses);
      } else {
        firstSmsRef.current.select();
        document.execCommand('copy');
      }
    }
    if (action === 'Second Smses') {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(secondSmses);
      } else {
        secondSmsRef.current.select();
        document.execCommand('copy');
      }
    }
    if (action === 'Third Smses') {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(thirdSmses);
      } else {
        thirdSmsRef.current.select();
        document.execCommand('copy');
      }
    }
    handleCopy({
      show: true,
      message: `Succesfully copied ${action} to clipboard`,
    });
    setTimeout(() => {
      handleCopy({ show: false, message: '' });
    }, 2000);
  };

  return (
    <Container className="" fluid>
      {/* Normal Table */}
      <Row>
        <div className="col">
          <Card className="shadow">
            <div
              className={
                copy.show ? 'd-block text-center text-success mt-2' : 'd-none'
              }
            >
              <small>{copy.show ? copy.message : ''}</small>
            </div>

            <CardHeader className="border-0 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="mb-0">
                  {territoryName || supervisorName} Agents
                </h3>
                {territoryName ? (
                  <FormGroup className="ml-3 mb-0">
                    <Input
                      type="select"
                      name="select"
                      id="selectsms"
                      onChange={(e) => copySmses(e.target.value)}
                    >
                      <option>Select Smses to copy</option>
                      <option>First Smses</option>
                      <option>Second Smses</option>
                      <option>Third Smses</option>
                    </Input>
                  </FormGroup>
                ) : (
                  ''
                )}
              </div>

              <ActionModal props={props} />
              <Form inline>
                {supervisorName ? (
                  <Button
                    color="info"
                    onClick={() => unReadySnapshot(!readySnapshot)}
                  >
                    {readySnapshot ? (
                      <span>Original view</span>
                    ) : (
                      <span>Snapshot view</span>
                    )}
                  </Button>
                ) : (
                  ''
                )}
                <FormGroup>
                  <Label for="exampleSelect" className="mr-2">
                    <h2 className="mb-0"> </h2>
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    value={selectedAction}
                    onChange={(e) => setSelectedAction(e.target.value)}
                  >
                    <option>Select Action</option>
                    {availableSelectOptions.map((option) => {
                      return <option>{option}</option>;
                    })}
                  </Input>
                </FormGroup>
                <Button color="primary" className="ml-2" onClick={toggler}>
                  GO
                </Button>
              </Form>
            </CardHeader>
            <Table
              className="align-items-center table-flush custom-table"
              hover
              responsive
              size={readySnapshot ? 'sm' : 'md'}
            >
              <thead className="thead-light">
                <tr>
                  <th className={readySnapshot ? 'd-none' : 'table-checkbox'}>
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="selectAll"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      ></label>
                    </div>
                  </th>
                  <th scope="col">Agent Name</th>
                  <th scope="col" className={readySnapshot ?  country === 'sierra leone' ? 'd-none' : null: null}>
                    {commissionFields.balance.label[0]} <br />
                    {commissionFields.balance.label[1]}
                  </th>
                  <th scope="col">
                    {commissionFields.expected.label[0]} <br />
                    {commissionFields.expected.label[1]}
                  </th>
                  <th scope="col" className={readySnapshot ?  country !== 'sierra leone' ? 'd-none' : null: null}>
                    {commissionFields.paid.label[0]} <br />
                    {commissionFields.paid.label[1]}
                  </th>
                  <th scope="col" className={readySnapshot ? null : 'd-none'}>
                    {commissionFields.toRemit.label[0]} <br />
                    {commissionFields.toRemit.label[1]}
                  </th>
                  <th scope="col">{commissionFields.payStatus.label[0]}</th>
                  <th scope="col" className={readySnapshot ? 'd-none' : null}>
                    {commissionFields.comOwedWeek.label[0]}
                    <br />
                    {commissionFields.comOwedWeek.label[1]}
                  </th>
                  <th scope="col" className={readySnapshot ? 'd-none' : null}>
                    {commissionFields.deductComDebt.label[0]} <br />
                    {commissionFields.deductComDebt.label[1]}
                  </th>
                  <th scope="col" className={readySnapshot ? 'd-none' : null}>
                    {commissionFields.debtAfterRemit.label[0]} <br />
                    {commissionFields.debtAfterRemit.label[1]}
                  </th>
                  <th scope="col" className={readySnapshot ? 'd-none' : null}>
                    {commissionFields.withholdCom.label[0]} <br />
                    {commissionFields.withholdCom.label[1]}
                  </th>
                  <th scope="col">{commissionFields.points.label[0]}</th>
                  <th scope="col">{commissionFields.tier.label[0]}</th>
                  <th scope="col">
                    {commissionFields.currentPoints.label[0]} <br />
                    {commissionFields.currentPoints.label[1]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCommissions.sort().map((commission, index) => {
                  console.log(getCommissionValue(commission, 'tier'))
              
                  const selection = JSON.stringify({
                    id: commission.id,
                    agentFullName: `${commission.agent.firstName} ${commission.agent.lastName}`,
                    toAdjust: commission.toAdjustOnAngaza,
                    commissionOwedForTheWeek: commission.commissionOwedForTheWeek,
                    weeklyTopup: commission.weeklyTopup,
                    weeklyOwed: commission.weeklyOwed,
                    amountSent: commission.amountSent,
                    agentUsername: commission.agent.username,
                    debtAfterRemit: commission.debtAfterRemit,
                    date: commission.currentWeekThursday,
                    rewardType: commission.reward,
                  });
                  // const agentReward = commission.reward !== '-' ? true : false;
                  return (
                    <tr key={commission.id}>
                      <td
                        className={readySnapshot ? 'd-none' : 'table-checkbox'}
                      >
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            id={'select' + commission.id}
                            checked={checkedCheckboxes[index]}
                            type="checkbox"
                            value={selection}
                            onChange={(e) => handleCommissionSelect(e)}
                            onClick={(e) => handleCheckboxClick(e, index)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={'select' + commission.id}
                          ></label>
                        </div>
                      </td>
                      <th>
                        <Link
                          to={
                            '/admin/agent-profile/' + commission.agent.username
                          }
                        >
                          <Media className="text-sm d-flex align-items-center">
                            {`${commission.agent.firstName} ${commission.agent.lastName}`}{' '}
                            <strong className="pl-1">
                              {`(${commission.agent.username})`}
                            </strong>
                            {/* {agentReward ? <small className={`fas fa-gift pl-1 ${commission.isRewardPaid ? 'text-success' : 'text-danger'}` }></small> : ""} */}
                          </Media>
                        </Link>
                      </th>
                      <td className={readySnapshot ?  country === 'sierra leone' ? 'd-none' : null: null}>
                        <CurrencyFormat
                          value={commissionFields['balance'].name ?
                            Math.trunc(
                            getCommissionValue(commission, 'balance'),
                          ): Math.trunc(
                            getCommissionValue(commission, 'balance')(commission),
                          )
                        }
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={getCommissionValue(commission, 'expected')}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      </td>
                      <td className={readySnapshot ?  country !== 'sierra leone' ? 'd-none' : null: null}>
                        <div
                          id={'remitTooltip' + index}
                          className="p-1"
                          onClick={(e) => e.preventDefault()}
                        >
                          <CurrencyFormat
                            value={getCommissionValue(commission, 'paid')}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currencySymbol}
                          />
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={'remitTooltip' + index}
                        >
                          <div className="text-left">
                            <div>
                              {commissionFields.manualPay.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'manualPay',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.mobilePay.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'mobilePay',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.delta.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(commission, 'delta')}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                          </div>
                        </UncontrolledTooltip>
                      </td>
                      <td className={readySnapshot ? null : 'd-none'}>
                        <CurrencyFormat
                          value={country === 'sierra leone' ? 
                            getCommissionValue(commission, 'toRemit')(
                              commission[commissionFields['expected'].name],
                              commission[commissionFields['paid'].name],
                            ): 
                            getCommissionValue(commission, 'toRemit')(
                              Math.trunc(
                                getCommissionValue(commission, 'balance')(commission),
                              ),
                              commission[commissionFields['expected'].name],
                            )}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span style={{ minWidth: 34 }} className="mr-1">
                            {commissionFields['percentageShow'].name
                              ? getCommissionValue(commission, 'percentageShow')
                              : getCommissionValue(
                                  commission,
                                  'percentageShow',
                                )(
                                  commission[commissionFields['paid'].name],
                                  commission[commissionFields['expected'].name],
                                )}
                            %
                          </span>
                          <div>
                            <Progress
                              max="100"
                              value={
                                commissionFields['percentageShow'].name
                                  ? getCommissionValue(
                                      commission,
                                      'percentageShow',
                                    )
                                  : getCommissionValue(
                                      commission,
                                      'percentageShow',
                                    )(
                                      commission[commissionFields['paid'].name],
                                      commission[
                                        commissionFields['expected'].name
                                      ],
                                    )
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td className={readySnapshot ? 'd-none' : null}>
                        <div
                          className="p-1"
                          id={'commissionTooltip' + index}
                          onClick={(e) => e.preventDefault()}
                        >
                          <CurrencyFormat
                            value={commission.commissionOwedForTheWeek}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currencySymbol}
                          />
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={'commissionTooltip' + index}
                          placement="top"
                        >
                          <div className="text-left">
                            <div>
                              {commissionFields.weeklyOwed.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'weeklyOwed',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.remitCom.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'remitCom',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.payCom.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(commission, 'payCom')}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.topup.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(commission, 'topup')}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.owedBefore.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'owedBefore',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.reward.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(commission, 'reward')}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.pdsCom.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(commission, 'pdsCom')}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.pdsBonus.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'pdsBonus',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                          </div>
                        </UncontrolledTooltip>
                      </td>
                      <td className={readySnapshot ? 'd-none' : null}>
                        <div className="d-flex align-items-center">
                          <label className="custom-toggle mb-0">
                            <input
                              defaultChecked={getCommissionValue(
                                commission,
                                'deductComDebt',
                              )}
                              type="checkbox"
                              onChange={(e) =>
                                handleDeductCommissionOnList(commission.id, e)
                              }
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                          <strong className="pl-3">
                            {getCommissionValue(commission, 'deductComDebt')
                              ? '(Yes)'
                              : '(No)'}
                          </strong>
                        </div>
                      </td>
                      <td className={readySnapshot ? 'd-none' : null}>
                        <div
                          className="p-1"
                          id={'debtTooltip' + index}
                          onClick={(e) => e.preventDefault()}
                        >
                          <CurrencyFormat
                            value={getCommissionValue(
                              commission,
                              'debtAfterRemit',
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currencySymbol}
                          />
                        </div>
                        <UncontrolledTooltip
                          delay={0}
                          target={'debtTooltip' + index}
                          placement="top"
                        >
                          <div className="text-left">
                            <div>
                              {commissionFields.debtBeforeRemit.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'debtBeforeRemit',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                            <div>
                              {commissionFields.comDeductDebt.label[0]}:
                              <CurrencyFormat
                                value={getCommissionValue(
                                  commission,
                                  'comDeductDebt',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </div>
                          </div>
                        </UncontrolledTooltip>
                      </td>
                      <td className={readySnapshot ? 'd-none' : null}>
                        <div className="d-flex align-items-center">
                          <label className="custom-toggle mb-0">
                            <input
                              defaultChecked={getCommissionValue(
                                commission,
                                'withholdCom',
                              )}
                              type="checkbox"
                              onChange={(e) =>
                                handleWithholdCommissionOnList(commission.id, e)
                              }
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                          <strong className="pl-3">
                            {getCommissionValue(commission, 'withholdCom')
                              ? '(Yes)'
                              : '(No)'}
                          </strong>
                        </div>
                      </td>
                      <td>
                        <span>
                          <strong>
                            {getCommissionValue(commission, 'points')}
                          </strong>
                        </span>
                        <sup>
                          Week :{' '}
                          <strong>
                            {getCommissionValue(commission, 'weeklyPoints')}
                          </strong>
                        </sup>
                      </td>
                      <td>
                        <span>
                          <strong
                            className={
                              'span-' + getCommissionValue(commission, 'tier')
                            }
                          />
                        </span>
                      </td>
                      <td>
                        <strong>
                          {getCommissionValue(commission, 'currentPoints')}
                        </strong>
                      </td>
                    </tr>
                  );
                })}
                {/*Total Row*/}
                <tr
                  bgcolor={'#f6f9fc'}
                  className={readySnapshot ? null : 'd-none'}
                >
                  <td>
                    <h4 className="mb-0">Total</h4>
                  </td>
                  <td>
                    {country !== 'sierra leone' ? <p>Target Revenue</p> : ''}
                    <h4 className="mb-0">
                      <CurrencyFormat
                        value={remitProps ? remitProps.remittance : ''}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                      />
                    </h4>
                  </td>
                  <td>
                  {country !== 'sierra leone' ? <p>Actual Revenue</p> : ''}
                    <h4 className="mb-0">
                      <CurrencyFormat
                        value={remitProps ? remitProps.remitted : ''}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                      />
                    </h4>
                  </td>
                  <td>
                  {country !== 'sierra leone' ? <p>Revenue Diff</p> : ''}
                    <h4 className="mb-0">
                      <CurrencyFormat
                        value={remitProps ? remitProps.toRemit : ''}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                      />
                    </h4>
                  </td>
                  <td>
                  {country !== 'sierra leone' ? <p>Revenue %</p> : ''}
                    <div className="d-flex align-items-center">
                      <h4 style={{ minWidth: 34 }} className="mr-1 mb-0">
                        {remitProps ? remitProps.simplePercentage : ''}%
                      </h4>
                      <div>
                        <Progress
                          max="100"
                          value={remitProps ? remitProps.simplePercentage : ''}
                        />
                      </div>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
              </tbody>
            </Table>
          </Card>
        </div>
      </Row>
      {/* <p style={{color: 'white', whiteSpace: "pre-line"}} ref={firstSmsRef}>{firstSmses}</p>
      <p style={{color: 'white', whiteSpace: "pre-line"}} ref={secondSmsRef}>{secondSmses}</p>
      <p style={{color: 'white', whiteSpace: "pre-line"}} ref={thirdSmsRef}>{thirdSmses}</p> */}
      <textarea
        ref={firstSmsRef}
        value={firstSmses}
        readOnly
        style={{ position: 'absolute', left: '-2000px' }}
      />
      <textarea
        ref={secondSmsRef}
        value={secondSmses}
        readOnly
        style={{ position: 'absolute', left: '-2000px' }}
      />
      <textarea
        ref={thirdSmsRef}
        value={thirdSmses}
        readOnly
        style={{ position: 'absolute', left: '-2000px' }}
      />
    </Container>
  );
};

export default TerritoryAgentsList;
