import React, { useState } from 'react';

// reactstrap components
import { FormGroup, Input } from 'reactstrap';
import { encrypt, decrypt } from '../../utils/utils-2';

const CountryFilter = ({ parentCountryFilter }) => {
  const [country, setCountry] = useState(
    decrypt(localStorage.getItem('country')),
  );
  const countryList = {
    'sierra leone': ['sierra leone', 'Sierra Leone'],
    liberia: ['liberia', 'Liberia'],
  };
  let fullCountryList = [];

  const handleCountryFilter = (event) => {
    event.preventDefault();
    const country = event.target.value;
    // Update local storage to use it accross all the app
    localStorage.setItem('country', encrypt(country));
    setCountry(country);
  };

  // // Send filter country to the parent component
  parentCountryFilter(country);

  const isSuperuser = JSON.parse(decrypt(localStorage.getItem('isSuperuser')));
  const userCountry = decrypt(localStorage.getItem('country'));

  if (isSuperuser) {
    fullCountryList = [countryList['sierra leone'], countryList['liberia']];
  } else {
    fullCountryList = [countryList[userCountry]];
  }

  return (
    <div className="date-filter d-flex align-items-center mb-lg-3 mb-xl-3 mb-md-3 mb-0 justify-content-lg-start justify-content-md-start justify-content-sm-end justify-content-end">
      <FormGroup className="mr-2">
        <p className="mb-4 text-white" />
        <Input
          type="select"
          name="country"
          id="countrySelect"
          onChange={handleCountryFilter}
        >
          {fullCountryList.map((countryItem, i) => {
            if (countryItem[0] === country) {
              return (
                <option value={countryItem[0]} key={i} selected>
                  {countryItem[1]}
                </option>
              );
            }
            return (
              <option value={countryItem[0]} key={i}>
                {countryItem[1]}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    </div>
  );
};

export default CountryFilter;
