import React from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  CardTitle,
} from 'reactstrap';

import TerritoryAgentsList from './territory-agents';

import territoryTotalAgents, {
  territoryTotalRemitted,
  territoryExpectedRemittance,
  territoryTotalToRemit,
  percentageTerritory,
  territoryCommissions,
} from '../../utils/helpers';

import {
  getTerritoryTargetSalesRevenue,
  getTerritorySalesRevenue,
  getTerritoryAveragePAR,
  territoryPercentageSales,
} from '../../utils/lb-utils';

import { countryVariables } from '../../utils/country-variables';
import { graphQlQueries } from '../gql/main-gql';

// Upate country variable to fill appropriate functions
// We did the update here, because we will have a circular dependency
// when we countryVariable to helper.js

// Sierra Leone
countryVariables[
  'sierra leone'
].territoryDetail.variables[0][1] = territoryExpectedRemittance;
countryVariables[
  'sierra leone'
].territoryDetail.variables[1][1] = territoryTotalToRemit;
countryVariables[
  'sierra leone'
].territoryDetail.variables[2][1] = territoryTotalRemitted;
countryVariables[
  'sierra leone'
].territoryDetail.variables[3][1] = percentageTerritory;

// Liberia
countryVariables[
  'liberia'
].territoryDetail.variables[0][1] = getTerritoryTargetSalesRevenue;
countryVariables[
  'liberia'
].territoryDetail.variables[1][1] = getTerritoryAveragePAR;
countryVariables[
  'liberia'
].territoryDetail.variables[2][1] = getTerritorySalesRevenue;
countryVariables[
  'liberia'
].territoryDetail.variables[3][1] = territoryPercentageSales;

const TerritoryDetail = ({ data, currentThursday, country }) => {
  const territoryDistrict = graphQlQueries[country]['territoryDistrict'];
  const variables = countryVariables[country]['territoryDetail']['variables'];
  const currenySymbol = countryVariables[country]['currencySymbol'];
  const commissions = territoryCommissions(
    data[territoryDistrict].districts,
    currentThursday,
    country,
  );
  const rewardCount = commissions.filter(
    (commission) => commission.reward !== '-',
  ).length;

  const supervisorLabel = country
    ? countryVariables[country]['supervisorLabel']
    : '';

  return (
    <div>
      <Container fluid>
        <Row className="align-items-center mb-5">
          <Col md="12" lg="12" xl="12">
            <div className="card-stats overview-stats bg-transparent mb-4">
              <Row className="align-items-center justify-content-between">
                <Col md="5">
                  <Card className="card-stats">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col md="6">
                          <CardTitle tag="h5" className="mb-0 territory-name">
                            <p className="territory-name-short">
                              {data.abbreviation}
                            </p>
                            <p className="territory-name-full text-left">
                              {data.name}
                            </p>
                          </CardTitle>
                          {rewardCount ? (
                            <span className="text-primary">
                              <i className="fas fa-gift"></i>{' '}
                              <span>{rewardCount}</span>
                            </span>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col md="6" className="text-right">
                          <small className="mb-1">
                            Territory manager{' '}
                            <i className="fas fa-user-secret pl-2" />
                          </small>
                          <p className="font-weight-bold">
                            {data.territoryManager
                              ? `${data.territoryManager.firstName} ${data.territoryManager.lastName} (${data.territoryManager.username})`
                              : 'No Manager'}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-end mt-4">
                        <Col className="d-flex justify-content-end">
                          <Button
                            className="btn-icon btn-3"
                            color="warning"
                            tag={Link}
                            // to={`/admin/supervisor-territory/${data.abbreviation}`}
                            to={{
                              pathname: `/admin/territory-rewards/${data.abbreviation}`,
                              state: {
                                territoryManager: data.territoryManager
                                  ? `${data.territoryManager.firstName} ${data.territoryManager.lastName} (${data.territoryManager.username})`
                                  : 'No Manager',
                                territoryName: data.name,
                              },
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-gifts" />
                            </span>
                            <span className="btn-inner--text">
                              Rewards <strong></strong>
                            </span>
                          </Button>

                          <Button
                            className="btn-icon btn-3"
                            color="primary"
                            tag={Link}
                            // to={`/admin/supervisor-territory/${data.abbreviation}`}
                            to={{
                              pathname: `/admin/supervisor-territory/${data.abbreviation}`,
                              state: {
                                territoryManager: data.territoryManager
                                  ? `${data.territoryManager.firstName} ${data.territoryManager.lastName} (${data.territoryManager.username})`
                                  : 'No Manager',
                                territoryName: data.name,
                              },
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-users" />
                            </span>
                            <span className="btn-inner--text">
                              Supervisors{' '}
                              <strong>
                                ({data[supervisorLabel].supervisors.length})
                              </strong>
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={{ size: 6, offset: 1 }}>
                  <Row>
                    <Col md="6">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-shape bg-white text-primary rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                        <span className="pl-2">
                          <h1 className="text-white mb-0">
                            {territoryTotalAgents(
                              data[territoryDistrict].districts,
                              country,
                            )}
                          </h1>
                        </span>
                      </div>
                      <Row className="justify-content-between text-white">
                        <Col>
                          <small>{variables[0][0]}</small>
                        </Col>
                        <Col className="text-right text-nowrap">
                          <CurrencyFormat
                            value={variables[0][1](
                              data[territoryDistrict].districts,
                              currentThursday,
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-between text-white">
                        <Col>
                          <small>{variables[1][0]}</small>
                        </Col>
                        <Col className="text-right text-nowrap">
                          <CurrencyFormat
                            value={variables[1][1](
                              data[territoryDistrict].districts,
                              currentThursday,
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={
                              country === 'sierra leone' ? currenySymbol : ''
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" className="">
                      <div className="text-success stats-percentage text-right mb-1">
                        {variables[3][1](
                          data[territoryDistrict].districts,
                          currentThursday,
                        )}
                        %
                      </div>
                      <div className="d-flex justify-content-end text-success">
                        <span className="pr-3">
                          <small>{variables[2][0]}</small>
                        </span>
                        <span className="text-right text-nowrap">
                          <CurrencyFormat
                            value={variables[2][1](
                              data[territoryDistrict].districts,
                              currentThursday,
                            )}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <TerritoryAgentsList data={commissions}
        territoryName={data.name}
        currentThursday={currentThursday}
        country={country}
      />
    </div>
  );
};

export default TerritoryDetail;
