import React from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Modal, 
  ModalHeader, 
  ModalBody,
  ModalFooter,
  FormGroup,
  Spinner,
  Row,
  Form,
  Label,
  Col,
  Input,
} from 'reactstrap';

export const ApprouveModal = (props) => {
    return (
        <Modal isOpen={props.data.display} toggle={()=>{}} size="sm" centered>
        <ModalHeader toggle={()=>{}}>Confirm</ModalHeader>
        <ModalBody>
          Do you want to approve <strong>{props?.data?.prospect?.prospectName}</strong> prospect?
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={!props.loading ? props.onConfirm: ''}>
            {props.loading && 
              <Spinner
                style={{ width: "0.7rem", height: "0.7rem" }}
                type="grow"
                color="light"
              />}
              {!props.loading ? 'Approve' : ''}
            </Button>{' '}
          <Button color="secondary" onClick={props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal> 
    )
}

export const RejectModal = (props) => {
  return (
    <Modal isOpen={props.data.display} toggle={()=>{}} size="sm" centered>
    <ModalHeader toggle={()=>{}}>Confirm</ModalHeader>
    <ModalBody>
      Do you want to reject <strong> {props?.data?.prospect?.prospectName}</strong> prospect?
    </ModalBody>
    <ModalFooter>
      <Button color="danger" onClick={!props.loading ? props.onConfirm: ''}>
      {!props.loading ? 'Reject' : ''}
      </Button>{' '}
      <Button color="secondary" onClick={props.onCancel}>Cancel</Button>
    </ModalFooter>
  </Modal>
  )
}

export const AlertModal = (props) => {
  return (
      <Modal isOpen={props.data.display} toggle={props.onConfirm} size="sm" centered>
      <ModalHeader toggle={props.onConfirm}>{props.data.title}</ModalHeader>
      <ModalBody>
        {/* The prospect <strong>{props?.data?.prospect?.prospectName}</strong> have been approuved successfully ? */}
        {props.data.body}
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={props.onConfirm}>OK</Button>{' '}
        {/* <Button color="secondary" onClick={props.onCancel}>Cancel</Button> */}
      </ModalFooter>
    </Modal> 
  )
}

export const InfoModal = (props) => {

  return (
    <Modal isOpen={props.data.display} toggle={props.onCancel} size="lg" centered>
        <ModalHeader toggle={props.onCancel}>Prospect Review</ModalHeader>
        <ModalBody>
          <Row className="mb-4">
            <Col md="3">
              <h3><i className="fa fa-calendar-alt mr-2"></i>{dayjs(props.data?.createdDate).format('YYYY-MM-DD')}</h3>
            </Col>
            <Col md="3">
              <h3><i className="fa fa-user-plus mr-2"></i>{props?.data?.prospect?.prospectName}</h3>
            </Col>
            <Col md="3">
              <h3><i className="fa fa-mobile-alt mr-2"></i>{props.data?.prospect?.phone}</h3>
            </Col>
            <Col md="3">
              <h3><i className="fa fa-user-secret mr-2"></i>{props.data?.prospect?.responsibleUser}</h3>
            </Col>
            <Col md="12">
              <h3><i className="fa fa-box mr-2"></i>{props.data?.prospect?.pricingGroup}</h3>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>House quality</Label>
                  <h4>Ans: {props?.data?.houseQuality}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Customer owns a generator ?</Label>
                  <h4>Ans: {props?.data?.ownsGenerator}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Customer owns working vehicle/motorbike</Label>
                  <h4>Ans: {props?.data?.workingVehicle}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Type of Phone</Label>
                  <h4>Ans: {props?.data?.typeOfPhone}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>House has more than 1 floor ?</Label>
                  <h4>Ans: {props?.data?.moreThan1HouseFloors}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Owns freezer or TV ?</Label>
                  <h4>Ans: {props?.data?.freezerTv}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Customer receives remittances from out of SL ?</Label>
                  <h4>Ans: {props?.data?.remittancesFromIntl}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>House is finished ?</Label>
                  <h4>Ans: {props?.data?.houseIsFinished}</h4>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>More than 1 person living in the same house earning income over 1 million ?</Label>
                  <h4>Ans: Yes</h4>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.onConfirm}>Done</Button>{' '}
          <Button color="secondary" onClick={props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
  )
}

export const ModalForm = (props) => {
  const {
    data,
    onCancel,
    onConfirm,
    setFormData,
    formData,
    loading
  } = props

  // write it exactly as it is in the response table
  // because the pointing system depend on it
  const houseQualityOptions = [
    'Zinc House',
    'Straw',
    'Dirt brick',
    'Cement floors, walls, zinc roof'
  ]

  const YesOrNo = [
    'Yes',
    'No'
  ]
  const workingVehicle = [
    'None',
    'Motorbike',
    'Vehicle'
  ]
  const phoneType = [
    'None',
    'Feature phone',
    'Smartphone'
  ]

  return (
  <Modal isOpen={data.display} toggle={onCancel} size="lg" centered>
    <ModalHeader toggle={onCancel}>To Question</ModalHeader>
    <ModalBody>
      <Row className="mb-4">
        <Col md="3">
          <h3><i className="fa fa-calendar-alt mr-2"></i>{dayjs(props.data?.createdDate).format('YYYY-MM-DD')}</h3>
        </Col>
        <Col md="3">
          <h3><i className="fa fa-user-plus mr-2"></i>{props?.data?.prospect?.prospectName}</h3>
        </Col>
        <Col md="3">
          <h3><i className="fa fa-mobile-alt mr-2"></i>{props.data?.prospect?.phone}</h3>
        </Col>
        <Col md="3">
          <h3><i className="fa fa-user-secret mr-2"></i>{props.data?.prospect?.responsibleUser}</h3>
        </Col>
        <Col md="12">
          <h3><i className="fa fa-box mr-2"></i>{props.data?.prospect?.pricingGroup}</h3>
        </Col>
        </Row>
      <Form>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>House quality</Label>
              <Input type="select" name=""
                defaultValue={data.houseQuality}
                onChange={(e) => {
                  setFormData({
                    houseQuality: e.target.value,
                    ...formData
                  })
                }}>
                <option>Please select</option>
                {houseQualityOptions.map (e => (
                  <option key={`house_quality_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Customer owns a generator ?</Label>
              <Input type="select" name=""
                onChange={(e) => {
                  setFormData({
                    ownsGenerator: e.target.value,
                    ...formData
                  })
                }}
              >
                <option>Please select</option>
                {YesOrNo.map (e => (
                  <option key={`own_generator_${e}`} selected={formData.ownsGenerator === e}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Customer owns working vehicle/motorbike</Label>
              <Input type="select" name=""
                defaultValue={data.workingVehicle}
                onChange={(e) => {
                  setFormData({
                    workingVehicle: e.target.value,
                    ...formData
                  })
                }}>
              <option value={''}>Please select</option>
                {workingVehicle.map (e => (
                  <option key={`working_vehicle_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Type of Phone</Label>
              <Input type="select" name=""
                defaultValue={data.typeOfPhone}
                onChange={(e) => {
                  setFormData({
                    typeOfPhone: e.target.value,
                    ...formData
                  })
                }}>
                <option value={''}>Please select</option>
                {phoneType.map (e => (
                  <option key={`phone_type_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>House has more than 1 floor ?</Label>
              <Input type="select" name=""
                defaultValue={data.moreThan1HouseFloors}
                onChange={(e) => {
                  setFormData({
                    moreThan1HouseFloors: e.target.value,
                    ...formData
                  })
                }}>
                <option value={''}>Please select</option>
                  {YesOrNo.map (e => (
                    <option key={`house_floor_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Owns freezer or TV ?</Label>
              <Input type="select" name=""
                defaultValue={data.freezerTv}
                onChange={(e) => {
                  setFormData({
                    freezerTv: e.target.value,
                    ...formData
                  })
                }}>
                <option value=''>Please select</option>
                  {YesOrNo.map (e => (
                    <option key={`freezerTv_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Customer receives remittances from intl ?</Label>
              <Input type="select" name=""
                defaultValue={data.remittancesFromIntl}
                onChange={(e) => {
                  setFormData({
                    remittancesFromIntl: e.target.value,
                    ...formData
                  })
                }}>
                <option value=''>Please select</option>
                  {YesOrNo.map (e => (
                    <option key={`remittancesFromIntl_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>House is finished ?</Label>
              <Input type="select" name=""
                defaultValue={data.houseIsFinished}
                onChange={(e) => {
                  setFormData({
                    houseIsFinished: e.target.value,
                    ...formData
                  })
                }}>
              <option value=''>Please select</option>
                  {YesOrNo.map (e => (
                    <option key={`houseIsFinished_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          
          <Col md="6">
            <FormGroup>
              <Label>More than 1 person living in the same house earning income over $100 ?</Label>
              <Input type="select" name=""
                defaultValue={data.additionalIncome}
                onChange={(e) => {
                  setFormData({
                    additionalIncome: e.target.value,
                    ...formData
                  })
                }}
              >
                <option value=''>Please select</option>
                  {YesOrNo.map (e => (
                    <option key={`additional_income_${e}`}>{e}</option>
                ))}
              </Input>
            </FormGroup>
          </Col> 
         
        </Row>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={!loading ? onConfirm: ''}>
            {loading && 
              <Spinner
                style={{ width: "0.7rem", height: "0.7rem" }}
                type="grow"
                color="light"
              />}
              {!loading ? 'Update' : ''}
        </Button>{' '}
      <Button color="secondary" onClick={onCancel}>Cancel</Button>
    </ModalFooter>
  </Modal>
  )
}
