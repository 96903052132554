/* Find total remitted for a supevisor from a territory
  based on the structure of our graphql  response data
  note that we used alias in the query */

const defaultDate = '2020-04-09';
export const supervisorTotalRemitted = (agents, date = defaultDate) => {
  let totalRemitted = 0;
  agents.forEach((agent) => {
    const agentCommissions = agent.node.agentCommission.commissions;
    if (!agentCommissions.length) {
      return 0;
    }

    const latestThursday = date;
    const commissions = agent.node.agentCommission.commissions.filter(
      (commission) => {
        return commission.node.currentWeekThursday === latestThursday;
      },
    );
    commissions.forEach((commission) => {
      totalRemitted += commission.node.totalRemitted;
    });
  });
  return Math.trunc(totalRemitted);
};

/* Find expected remittance for a supevisor from a territory
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const supervisorExpectedRemittance = (agents, date = defaultDate) => {
  let expectedRemittance = 0;
  agents.forEach((agent) => {
    const agentCommissions = agent.node.agentCommission.commissions;
    if (!agentCommissions.length) {
      return 0;
    }
    const latestThursday = date;
    const commissions = agent.node.agentCommission.commissions.filter(
      (commission) => {
        return commission.node.currentWeekThursday === latestThursday;
      },
    );
    commissions.forEach((commission) => {
      if (commission.node.expectedRemittance > 0) {
        expectedRemittance += commission.node.expectedRemittance;
      }
    });
  });

  return Math.trunc(expectedRemittance);
};

/* Find agents commission under a supervisor filtered by a date
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const supervisorCommissions = (agents, date = '2020-04-09') => {
  const territoryCommissions = [];
  agents.forEach((agent) => {
    const agentCommissions = agent.node.agentCommission.commissions;
    if (!agentCommissions.length) {
      return 0;
    }
    const latestThursday = date;
    const commissions = agent.node.agentCommission.commissions.filter(
      (commission) => {
        return commission.node.currentWeekThursday === latestThursday;
      },
    );
    commissions.forEach((commission) => {
      territoryCommissions.push(commission.node);
    });
  });
  return territoryCommissions;
};

/*  Find total Target Sales Revenue for all supervisor agents
  based on the structure of our graphql  response data
  note that we used alias in the query */
  export const getSupervisorTargetRevenue = (agents, date) => {
    let anticipatedSales = 0
    let averageSalePrice = 0
    const totalAgent = agents.length;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
  
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        anticipatedSales = commission.node.numberOfAnticaptedSales;
        averageSalePrice = commission.node.averageSalesPrice;
      });
    });
    return Math.trunc(anticipatedSales * averageSalePrice * totalAgent);
  };

  /*  Find total Actual Sales Revenue for supervisors
  based on the structure of our graphql  response data
  note that we used alias in the query */
  export const getSupervisorActualRevenue = (agents, date) => {
    let actualSaleRevenue = 0;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        actualSaleRevenue += commission.node.totalRevenueMonth;
      });
    });
    return Math.trunc(actualSaleRevenue);
  }
