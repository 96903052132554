import React from 'react';

import _ from 'lodash';
import { Row, Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import { getShopsByTerritory } from '../../utils/utils';
import { countryVariables } from '../../utils/country-variables';

const ShopList = ({ shopData, currentThursday, country }) => {
 
  // Getting shops by territory
  const territoryShops = getShopsByTerritory(shopData);

  const orderTerritoryShops = _.orderBy(territoryShops, ['territoryName'], ['asc']);

  return (
    <>
      {orderTerritoryShops.map((territory) => {
        return (
          <div key={territory.territoryName}>
            <h2 className="mb-0 text-white">
              {territory.territoryName} ({territory.territoryAbbr})
            </h2>
            <hr className="bg-white mt-2 mb-3" />
            <Row>
              {territory.shopList.map((shop, index) => {
                const shopCoodinator = shop.shopManagerName;
                const shopAssistant = shop.shopAssistantName;
                const brandAmbassadors = [];
                const shopRevenue = shop.shopRevenue;
                const brandAmbassadorCount = shop.brandAmbassadorCount;
                const shopCoodinatorPhone = shop.shopManagerPhoneNumber;
                const shopAssistantPhone = shop.shopAssistantPhoneNumber;
                const shopTerritory = shop.territory;
                
                return (
                  <Col md="6" lg="4" xl="4" key={index}>
                    <Card
                      className="mb-4 shops-card"
                      tag={Link}
                      to={{
                        pathname: `/admin/single-shop/${shop.shopUsername}`,
                        state: {
                          shopCoodinator,
                          shopCoodinatorPhone,
                          shopAssistant,
                          shopAssistantPhone,
                          brandAmbassadors,
                          shopRevenue,
                          shopTerritory,
                          shop,
                          currentThursday,
                          brandAmbassadorCount,
                          country
                        },
                      }}
                    >
                      <span className="fas fa-store mr-1 store-bg"></span>
                      <CardBody>
                        <Row className="justify-content-between">
                          <Col md="6">
                            <h2 className="territory-name-full text-nowrap text-uppercase font-weight-bold mb-1">
                              {`${shop.shopName}`}
                            </h2>
                          </Col>
                          <Col md="6" className="text-right">
                            {brandAmbassadorCount}{' '}
                            <span className="fas fa-users"></span>
                          </Col>
                        </Row>
                        <div className="mb-2">
                          <span className="fas fa-user-secret mr-2"></span>
                          {shopCoodinator}
                        </div>
                        <div>
                          <span className="fas fa-money-bill-wave mr-2"></span>
                          <CurrencyFormat
                            value={shopRevenue}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={countryVariables[country]['currencySymbol']}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default ShopList;
