import React, { useState } from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import DateFilter from '../layouts/date-filter';
import { Query } from 'react-apollo';

import ShopBanner from './shop-banner';
import BrandAmbassadorList from './ba-list';
import { GET_SHOP_DATA } from '../gql/combined';

const SingleShop = (props) => {
  // Getting data from route link
  const { currentThursday, shop, country } = props.location.state;

  const [shopReportData, setShopReportData] = useState([]);
  const [latestThursday, updateLatestThursday] = useState(currentThursday);
  const [filteringDate, updateFilteringDate] = useState('');
  const [loading, setLoading] = useState(true);

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
      <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
        <DateFilter parentDateFilter={dateFiltering} />
        <Container fluid>
          <Query
            query={GET_SHOP_DATA}
            variables={{
              date: filteringDate ? filteringDate : latestThursday,
              country: country,
              username: shop.shopUsername,
            }}
            onCompleted={(data) => {
              if (data.allShopData.length > 0) {
                const ambassadorsList = JSON.parse(
                  data.allShopData[0].brandAmbassadorData,
                );
                setShopReportData(ambassadorsList);
              }
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return (
                  <p className="text-white">Error. Try refreshing the page</p>
                );
              }
              if (!loading) {
                setLoading(false);
              }
              const shopRevenue =
                data.allShopData.length > 0
                  ? data.allShopData[0].shopRevenue
                  : 0;

              return (
                <ShopBanner
                  routeProps={props}
                  shopRevenue={shopRevenue}
                  shopData={data.allShopData}
                />
              );
            }}
          </Query>
          {shopReportData.length > 0 ? (
            <BrandAmbassadorList ambassadorsList={shopReportData} />
          ) : loading ? (
            <p className="text-white">Loading...</p>
          ) : (
            <p className="text-white">
              The Shop Does not have any Brand ambassador
            </p>
          )}
        </Container>
      </div>
    </>
  );
};

export default SingleShop;
