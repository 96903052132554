import React from 'react';

// reactstrap components
import { Row, Card, Col, Table } from 'reactstrap';

import CurrencyFormat from 'react-currency-format';

const ShopStaffMonthly = ({ shopStaffReportData }) => {
  const totalRevenue = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.shopRevenue;
  }, 0);
  const totalRemitted = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.shopRemits;
  }, 0);
  const totalStaffCommissions = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.staffBonuses;
  }, 0);
  const totalrevenueWithKycIssues = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.revenueWithKycIssue;
  }, 0);
  const totalBonusWithoutKycIssue = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.bonusWithoutKycIssue;
  }, 0);

  const totalWithheldBonus = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.withheldBonus;
  }, 0);

  const totalReleasedBonus = shopStaffReportData.reduce((sum, shop) => {
    return sum + shop.releasedBonus;
  }, 0);



  return (
    <>
      <Row className="mb-5 mt-4">
        <Col md="12" lg="12" xl="12">
          <Card className="shadow">
            <Table
              className="align-items-center table-flush custom-table"
              size="sm"
              hover
              responsive
            >
              <thead className="bg-primary">
                <tr>
                  <th scope="col" className="text-white">
                    Shop Name
                  </th>
                  <th scope="col" className="text-white">
                    Total Revenue
                  </th>
                  <th scope="col" className="text-white">
                    Revenue with KYC issue
                  </th>
                  <th scope="col" className="text-white">
                    Total Remitted
                  </th>
                  <th scope="col" className="text-white">
                    Bonus without KYC issue
                  </th>
                  <th scope="col" className="text-white">
                    KYC Withheld Bonus
                  </th>
                  <th scope="col" className="text-white">
                    KYC Released Bonus
                  </th>
                  <th scope="col" className="text-white">
                    Staff Bonuses
                  </th>
                  <th scope="col" className="text-white">
                    Accounts with KYC issues
                  </th>
                </tr>
              </thead>
              <tbody>
                {shopStaffReportData.map((shop, index) => {
                  return (
                    <tr key={index}>
                      <td className="">{shop.shopName}</td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.shopRevenue}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.revenueWithKycIssue}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.shopRemits}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.bonusWithoutKycIssue}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.withheldBonus}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.releasedBonus}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <CurrencyFormat
                            value={shop.staffBonuses}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'Le '}
                          />
                        </strong>
                      </td>
                      <td>
                        <strong>
                         {shop.accountsWithKycIssues}
                        </strong>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <h4 className="mb-0">Total</h4>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalRevenue}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalrevenueWithKycIssues}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalRemitted}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalBonusWithoutKycIssue}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalWithheldBonus}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalReleasedBonus}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                  <td>
                    <strong>
                      <CurrencyFormat
                        value={totalStaffCommissions}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ShopStaffMonthly;
