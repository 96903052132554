import React, { useState } from 'react';

import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import _ from 'lodash';

import BACommissionMonthly from './ba-com-monthly';
import { GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS } from '../gql/gql-sec';
import { changeNumberFormat } from '../../utils/helpers';

const NewBrandAmbassadorCommissions = ({ startDate, endDate, exchanger }) => {
  const [baComData, setBaComData] = useState([]);

  // Excel related info
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formattedStart = new Date(startDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const fileName =
    formattedStart !== formattedEnd
      ? `${formattedStart} - ${formattedEnd} BAs Commissions`
      : `${formattedStart} BAs Commissions`;
  const fileTitle = "Brand Ambassadors' Commissions Report";

  const generateBaCommissionData = (performanceQueryResultList) => {
    // Construct the excel export data
    const performanceData = performanceQueryResultList
      .map((performance) => performance.node)
      .map((data) => {
        return {
          region: data.region,
          territory: data.territoryName,
          shopName: data.shopName,
          fullName: data.baFullName,
          baCode: data.baCode,
          role: data.role,
          phoneNumber: data.baPhoneNumber,
          bankName: data.brandAmbassador.bankName,
          bankAccount: data.brandAmbassador.bankAccount,
          BBANNumber: data.brandAmbassador.BBANNumber,
          month: data.endOfMonthDate,
          monthlyPoints: data.monthlyPoints,
          totalPoints: data.totalMonthlyPoints,
          monthlyInactivity: data.consecutiveMonthlyInactivity,
          prospected: data.monthlyProspected,
          converted: data.monthlyConverted,
          pdsRevenue: data.pdsRevenue,
          retailRevenue: data.retailRevenue,
          baRevenue: data.monthlyRevenue,
          salesCommission: data.commissionOnConvertedSales,
          monthlyAllowance: data.monthlyAllowance,
          bonus: data.bonus,
          topPerformerAward: data.topPerformerAward,
          baCommission: data.monthlyCommission,
        };
      });

    const orderedPerformanceData = _.orderBy(
      performanceData,
      ['shopName', 'fullName'],
      ['asc', 'asc'],
    );
    // Update excel report data state
    setBaComData(orderedPerformanceData);
  };

  const exportToExcel = (excelData, fileName, fileTitle) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const end = new Date(endDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    // we have 7 columns
    const wsCols = Array.apply(1, Array(27)).map((item, index) => {
      if (index === 1) {
        return { wch: 19 };
      }
      return { wch: 12 };
    });
    // Adding 5 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 5)).map((item) => {
      return { hpt: 20 };
    });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A3' });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} ${fileTitle}`;
    ws['B1'] = { t: 's', v: title };

    // Change headers Name
    ws.A3.v = 'Region';
    ws.B3.v = 'Territory';
    ws.C3.v = 'Shop Name';
    ws.D3.v = 'Full Name';
    ws.E3.v = 'Activation Code';
    ws.F3.v = 'Role';
    ws.G3.v = 'Phone Number';
    ws.H3.v = 'Bank Name';
    ws.I3.v = 'Bank Account';
    ws.J3.v = 'BBAN Number';
    ws.K3.v = 'Month';
    ws.L3.v = 'Points';
    ws.M3.v = 'Total Points';
    ws.N3.v = 'Monthly Inactivity Count';
    ws.O3.v = 'Total Prospected';
    ws.P3.v = 'Total Converted';
    ws.Q3.v = 'PDS Revenue';
    ws.R3.v = 'Retail Revenue';
    ws.S3.v = 'Revenue';
    ws.T3.v = 'Commission on Sales';
    ws.U3.v = 'Monthly Allowance';
    ws.V3.v = 'Bonus';
    ws.W3.v = 'Top Performer Award';
    ws.X3.v = 'Total Commissions';

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws['!ref'].split(':')[0];
    const lastRefCell = ws['!ref'].split(':')[1];

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws['!ref'] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ['L', 'M', 'Q', 'R', 'S', 'T', 'U', 'V'], '#,##0');

    // // merge total cell A12 and B12
    const merge = [
      { s: { r: lastRefCellRow, c: 0 }, e: { r: lastRefCellRow, c: 3 } },
    ];
    ws['!merges'] = merge;

    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' };
    ws[`O${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(O4:O${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`P${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(P4:P${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`Q${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(Q4:Q${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`R${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(R4:R${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`S${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(S4:S${lastRefCellRow})`,
      z: '#,##0',
    };

    ws[`T${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(T4:T${lastRefCellRow})`,
      z: '#,##0',
    };

    ws[`U${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(U4:U${lastRefCellRow})`,
      z: '#,##0',
    };

    ws[`V${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(V4:V${lastRefCellRow})`,
      z: '#,##0',
    };

    ws[`W${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(W4:W${lastRefCellRow})`,
      z: '#,##0',
    };

    ws[`X${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(X4:X${lastRefCellRow})`,
      z: '#,##0',
    };

    /* Write total data */
    const wb = {
      Sheets: { "Brand Ambassadors' Commissions": ws },
      SheetNames: ["Brand Ambassadors' Commissions"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  //  excel exporter to call from parent component
  const baCommissionExcelExporter = () => {
    exportToExcel(baComData, fileName, fileTitle);
  };

  // Add the child function to exchanger that might be called by the parent.
  exchanger.baCommissionExcelExporter = baCommissionExcelExporter;

  return (
    <>
      <div>
        {/*Normal Table*/}
        <Query
          query={GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS}
          variables={{
            endofmonth: `${endDate}`,
          }}
          fetchPolicy="no-cache"
          onCompleted={(data) =>
            generateBaCommissionData(data.allBaMonthlyConverted.edges)
          }
        >
          {({ loading, error, data }) => {
            if (loading) return <p className="text-white">LOADING...</p>;
            if (error) {
              return <p>Error. Try refreshing the page</p>;
            }

            return <BACommissionMonthly brandAmbassadorsList={baComData} />;
          }}
        </Query>
      </div>
    </>
  );
};

export default NewBrandAmbassadorCommissions;
