import React, { useState } from 'react';
// reactstrap components
import { Input, Table, Media } from 'reactstrap';

import Select from 'react-select';

import { useQuery } from '@apollo/react-hooks';

import { GET_SYSTEM_REWARD } from '../gql/gql';

import { getSelectOptions, findDuplicate } from '../../utils/helpers';

const PaymentRewardModalBody = ({ agentRewards, handleCleanData }) => {
  const initialArray = Array.apply(null, Array(agentRewards.length)).map(() => {
    return {
      agentId: '',
      rewards: [],
      note: '',
      lastCommissionId: '',
    };
  });

  // Validate form fields
  const initError = Array.apply(null, Array(agentRewards.length)).map(() => {
    return { error: false, errorMessage: '' };
  });

  // Getting filled rows and their data
  // eslint-disable-next-line
  const [formFields, handleFormFields] = useState(initialArray);
  const [fieldErrors, setFieldErrors] = useState(initError);

  // Load point system rewards
  const { data, loading, error } = useQuery(GET_SYSTEM_REWARD);
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  // Validate form fields
  const validateFormFields = (formFields, index) => {
    // validate form fields
    const formField = formFields[index];
    if (formField.agentId) {
      const rewards = formField.rewards;
      if (rewards.length) {
        // Look if there is the same reward picked twice (cash and physical)
        const pickedRewards = findDuplicate(rewards, 'rewardId');
        if (pickedRewards.length >= 2) {
          const errors = fieldErrors.map((item, i) => {
            if (i === index) {
              return {
                error: true,
                errorMessage:
                  'You should only pick one item for the same reward',
              };
            } else {
              return item;
            }
          });

          // Update the error state if duplicate is true
          setFieldErrors(errors);
          handleCleanData(formFields, true);
        } else {
          setFieldErrors(initError);
          // Update parent form fields change handler
          handleCleanData(formFields, false);
        }
      }
    }
  };

  // Handle form fileds
  const handleChange = (event, index, selectedOption = null) => {
    let value = formFields.slice();
    if (!selectedOption) {
      if (event.target.name === 'note') {
        value[index].note = event.target.value;
        value[index].agentId = event.target.dataset.id;
      }
    }
    if (selectedOption) {
      // The dom event object is altered when using React Select
      // If there is no React select event, reinitialize the row rewards value
      if (!event) {
        value[index].rewards = [];
        if (!value[index.note]) {
          value[index].agentId = '';
        }
      } else {
        const selectedOptionsValues = event.map((selected) =>
          JSON.parse(selected.value),
        );
        // Check if the Select is not empty or emptied
        if (selectedOptionsValues.length) {
          const agentId = selectedOptionsValues[0].agentId;
          const lastCommissionId = selectedOptionsValues[0].lastCommissionId;
          value[index].agentId = agentId;
          value[index].rewards = selectedOptionsValues;
          value[index].lastCommissionId = lastCommissionId;
        } else {
          value[index].rewards = [];
          if (!value[index.note]) {
            value[index].agentId = '';
            value[index].lastCommissionId = '';
          }
        }
      }
    }
    // Validate form field
    validateFormFields(value, index);
  };

  return (
    <Table
      className="align-items-center table-flush custom-table"
      hover
      responsive
    >
      <thead className="thead-light">
        <tr>
          <th scope="col">Agent Name</th>
          {/* <th scope="col">Reward Type</th> */}
          <th scope="col">Amount Or Reward</th>
          <th scope="col">Week Thursday</th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody className="mb-5">
        {agentRewards.map((agentReward, index) => {
          // Add values that will pass to backend to the the values of selected elements
          const options = getSelectOptions(agentReward);

          return (
            <tr key={agentReward.id}>
              <td>
                <Media className="text-sm font-weight-bold">
                  {agentReward.agentFullName}
                  <span className="pl-1">({agentReward.agentUsername})</span>
                </Media>
              </td>
              <td>
                <Select
                  options={options}
                  isMulti
                  name="rewards"
                  id="31"
                  onChange={(e, selectedOption) =>
                    handleChange(e, index, selectedOption)
                  }
                  data-id={agentReward.id}
                />
                <div className="text-center text-danger mb-2">
                  <small>
                    {fieldErrors[index].error
                      ? fieldErrors[index].errorMessage
                      : ''}
                  </small>
                </div>
              </td>
              <td>
                {/* Date <br /> Selection */}
                {new Date(agentReward.date).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </td>
              <td>
                <Input
                  type="textarea"
                  placeholder="Reference note"
                  maxLength="50"
                  name="note"
                  id="exampleText"
                  bsSize="sm"
                  data-id={agentReward.id}
                  onChange={(e) => handleChange(e, index)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PaymentRewardModalBody;
