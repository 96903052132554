import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';

import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';

// reactstrap components
import {
  Button,
  CardBody,
  Row,
  Col,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
} from 'reactstrap';

import { countryVariables } from '../../utils/country-variables';
import { AGENT_BIRTHDAYS } from '../gql/combined';

const Overview = ({
  currentThursday = '2021-01-13',
  globalData = {},
  country = 'sierra leone',
}) => {
  const currenySymbol = countryVariables[country]['currencySymbol'];
  const { expected, remaining, fulfilled } = countryVariables[country];

  const [modal, setModal] = useState(false);

  const [
    getBirthDays,
    { data },
  ] = useLazyQuery(AGENT_BIRTHDAYS, {
    variables: { country, weekThursday: currentThursday },
  });

  const toggle = () => setModal(!modal);

  const handleBirthdayClick = () => {
    getBirthDays();
    toggle();
  };

  return (
    <>
      <Modal isOpen={modal} size={'lg'} centered toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h3 className="m-0">Upcoming Birthdays</h3>
        </ModalHeader>
        <ModalBody className="py-0">
          {data && data.agentsBirthdays.length > 0 ? (
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Birthday date</th>
                  <th>Name</th>
                  <th>Phone number</th>
                  <th>Territory</th>
                </tr>
              </thead>
              <tbody>
                {data.agentsBirthdays.map((agent) => {
                  const birthday = moment(agent.birthdayDate).format("MMM, DD")
                  return (
                    <tr>
                      <th>
                        <strong>{birthday}</strong>
                      </th>
                      <td>{agent.fullName}</td>
                      <td>{agent.phoneNumber}</td>
                      <td>{agent.territory}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>No upcoming birthdays</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button color="primary" onClick={toggle}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>
      <Row className="align-items-center">
        <Col md="12" lg="12" xl="12">
          <div className="card-stats overview-stats mb-4">
            <CardBody>
              <Row className="align-items-center justify-content-between">
                <Col md="6">
                  <Button
                    className="btn-icon"
                    color="primary"
                    type="button"
                    onClick={handleBirthdayClick}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-birthday-cake" />
                    </span>
                    <span className="btn-inner--text">Birthdays</span>
                  </Button>
                </Col>

                <Col md="6">
                  <Row>
                    <Col></Col>
                    <Col>
                      <CardTitle tag="h1" className="text-right">
                        Overview Stats
                      </CardTitle>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Row className="justify-content-between align-items-center">
                        <Col>
                          <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                          <span className="agent-amount pl-2">
                            {globalData.totalAgentCount}
                          </span>
                        </Col>
                        <Col className="text-right">
                          <h1 className="text-primary mb-0">
                            <i className="fas fa-gift"></i>{' '}
                            <span>{globalData.totalToReward}</span>
                          </h1>
                        </Col>
                      </Row>

                      <Row className="justify-content-between text-dark">
                        <Col>
                          {/* Expected Remittance for Sierra Leone, Target sales for Liberia*/}
                          <small>{expected}</small>
                        </Col>
                        <Col className="text-right text-nowrap">
                          <CurrencyFormat
                            value={globalData.expected}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-between text-danger">
                        <Col>
                          {/* to remit for Sierra Leone, PAR for Liberia */}
                          <small>{remaining}</small>
                        </Col>
                        <Col className="text-right text-nowrap">
                          <CurrencyFormat
                            value={globalData.remaining}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={
                              country === 'sierra leone' ? currenySymbol : ''
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6" className="">
                      <div className="text-success text-right stats-percentage mb-1">
                        {isNaN(globalData.percentage * 100)
                          ? 0
                          : Math.round(globalData.percentage * 100)}
                        %
                      </div>
                      <div className="text-success text-right">
                        <span>{fulfilled}: </span>
                        <span className="text-success text-nowrap h2">
                          +{' '}
                          <CurrencyFormat
                            value={globalData.fulfilled}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={currenySymbol}
                          />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
