/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from './dashboard/dashboard.js';
import Reports from './reports/reports.js';
import SingleTerritory from './territory/single-territory';
import AgentCommissions from './agent-profile/agent-commissions';
import AgentFieldProfile from './agent-profile/agent-field-commissions';
import SingleSupervisor from './supervisor/single-supervisor';
import SingleSupervisorTerritory from './territory/single-supervisor-territory';
import AgentsMonthlyReports from './reports/agents-monthly-reports';
import SupervisorBonus from './reports/supervisor-bonus';
import ReportHeader from './reports/report-header';
import Shops from './shops/shops';
import SingleShop from './shops/single-shop';
import Prospects from './prospects/prospects';
import Login from './login/login.js';

import TerritoryRewards from './territory/territory-rewards';
import BrandAmbassadorCommissions from './reports/ba-commissions';

// Liberia
import LbReports from './lib_reports/reports.js';
import LbReportHeader from './lib_reports/report-header';

const textPrimary = 'text-primary';

const userIcon = `fas fa-user ${textPrimary}`;
const chartIcon = `fas fa-chart-bar ${textPrimary}`;

const routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: `ni ni-tv-2 ${textPrimary}`,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/single-territory/:id',
    name: 'Territory',
    component: SingleTerritory,
    layout: '/admin',
  },
  {
    path: '/agent-profile/:username',
    name: "Agent's Profile",
    userIcon,
    component: AgentCommissions,
    layout: '/admin',
  },
  {
    path: '/agent-field-profile/:username',
    name: 'My Profile',
    userIcon,
    component: AgentFieldProfile,
    layout: '/admin',
  },
  {
    path: '/supervisor-territory/:abbr',
    name: 'Territory Supervisors',
    userIcon,
    component: SingleSupervisorTerritory,
    layout: '/admin',
  },
  {
    path: '/supervisor-details/:username',
    name: "Supervisor's Details",
    userIcon,
    component: SingleSupervisor,
    layout: '/admin',
  },
  {
    path: '/territory-rewards/:abbr',
    name: 'Territory Rewards',
    userIcon,
    component: TerritoryRewards,
    layout: '/admin',
  },
  {
    path: '/reports',
    name: 'Reports',
    chartIcon,
    component: Reports,
    layout: '/admin',
  },
  {
    path: '/monthly-reports/:reportType',
    name: 'monthly reports',
    chartIcon,
    component: ReportHeader,
    layout: '/admin',
  },
  {
    path: '/shops',
    name: 'Shop Outlets',
    icon: `fas fa-user ${textPrimary}`,
    component: Shops,
    layout: '/admin',
  },
  {
    path: '/single-shop',
    name: 'Single Shop',
    icon: `fas fa-user ${textPrimary}`,
    component: SingleShop,
    layout: '/admin',
  },
  {
    path: '/agents-monthly-reports',
    name: 'Agents Monthly Reports',
    icon: `fas fa-chart-bar ${textPrimary}`,
    component: AgentsMonthlyReports,
    layout: '/admin',
  },
  {
    path: '/supervisor-bonus',
    name: 'Supervisor Bonuses',
    chartIcon,
    component: SupervisorBonus,
    layout: '/admin',
  },
  {
    path: '/ba-commissions',
    name: 'BAs Commissions',
    icon: 'fas fa-chart-bar text-primary',
    component: BrandAmbassadorCommissions,
    layout: '/admin',
  },
  {
    path: '/prospects',
    name: 'Prospects Validation',
    icon: `fas fa-user-plus ${textPrimary}`,
    component: Prospects,
    layout: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
  },
  // Liberia
  {
    path: '/lb_reports',
    name: 'LbReports',
    chartIcon,
    component: LbReports,
    layout: '/admin',
  },
  {
    path: '/lb-monthly-reports/:reportType',
    name: 'monthly reports',
    chartIcon,
    component: LbReportHeader,
    layout: '/admin',
  },
];
export default routes;
