import React, { useState } from 'react';

import { Query } from 'react-apollo';

import DateFilter from '../layouts/date-filter';
import AgentProfile from './agent-profile';
import { graphQlQueries } from '../gql/main-gql';
import { decrypt } from '../../utils/utils-2';

const AgentCommissions = (props) => {
  // Variable we name to the main dashboard that allow
  // selecting Territory Query based on the selected country
  // This name must match a key on the main-gql.js file
  const componentName = 'agent-commissions';

  const {
    match: { params },
  } = props;
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');
  const country = decrypt(localStorage.getItem('country'));

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
      {country ? (
        <div className="bg-gradient-primary pb-8 pt-5 pt-md-8">
          <DateFilter parentDateFilter={dateFiltering} />
          <Query
            query={graphQlQueries[country][componentName]}
            variables={{ agent_Username: params.username }}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return <p>Error. Try refreshing the page</p>;
              }
              return (
                <AgentProfile
                  commissionData={data}
                  currentThursday={
                    filteringDate ? filteringDate : latestThursday
                  }
                  country={country}
                />
              );
            }}
          </Query>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default AgentCommissions;
