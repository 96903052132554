import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { encrypt} from '../../utils/utils-2';


// reactstrap components
import {
  Button,
  Card,
  Container,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';

import { getUsernameFromEmail } from '../../utils/helpers';

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      user {
        id
        isStaff
        username
        country
        role
        isSuperuser
      }
    }
  }
`;

class Login extends Component {
  state = {
    email: '',
    password: '',
    error: false,
    errorMessage: '',
  };

  render() {
    const { email, password, error, errorMessage } = this.state;
    return (
      <>
        <div className="main-content">
          {/*<AuthNavbar />*/}
          <div className="header bg-gradient-primary h-100vh pt-5">
            <Container>
              <div className="header-body text-center mb-4">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img
                      alt="..."
                      className="mb-5"
                      src={require('../../assets/img/brand/easy-logo.png')}
                    />

                    <h1 className="text-white">Login</h1>
                    <p className="text-lead text-light">
                      Use login credentials to sign in to Agent Management Tool
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* Page content */}
            <Container className="pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sign in with credentials</small>
                        <br></br>
                      </div>
                      <div className="text-center text-danger mb-2">
                        <small>{error ? errorMessage : ''}</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value={email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              type="email"
                              placeholder="Your email address"
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value={password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                              placeholder="Password"
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          <Mutation
                            mutation={LOGIN_MUTATION}
                            variables={{ email, password }}
                            onCompleted={(data) => this._confirm(data)}
                            onError={(err) => this._handleError(err)}
                          >
                            {(mutation) => (
                              <Button
                                className="my-4"
                                color="primary"
                                block
                                type="button"
                                onClick={mutation}
                              >
                                Login
                              </Button>
                            )}
                          </Mutation>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  _confirm = async (data) => {
    const { token } = data.tokenAuth;
    const { user } = data.tokenAuth;
    this._saveUserData(token, user);

    if (user.isStaff) {
      this.props.history.push('/admin');
    } else {
      const { username } = user;
      // if there is username redirect to user's profile
      if (username) {
        localStorage.setItem('username', username);
        this.props.history.push(`/admin/agent-field-profile/${username}`);
      } else {
        const username = getUsernameFromEmail(this.state.email);
        if (username) {
          localStorage.setItem('username', username);
          this.props.history.push(`/admin/agent-field-profile/${username}`);
        } else {
          this.setState({
            error: true,
            errorMessage: 'You need an account to log in',
          });
        }
      }
    }
  };

  _handleError = async (err) => {
    err.graphQLErrors.map(({ message }) =>
      this.setState({
        error: true,
        errorMessage: message,
      }),
    );
  };

  _saveUserData = (token, user = {}) => {
    /* TODO: this is not good in production we'll need a better way
           to authenticate with the token
        */
    // Split the country and reconstruct it
    const countryList = user.country.split(/[\s,_]+/);
    const country = countryList.join(' ').toLowerCase();
    localStorage.setItem('token', token);
    // Add the email of the user to localstorage if
    localStorage.setItem('userEmail', this.state.email);
    localStorage.setItem('isStaff', encrypt(JSON.stringify(user.isStaff)));
    localStorage.setItem('isSuperuser', encrypt(JSON.stringify(user.isSuperuser)));
    localStorage.setItem('country', encrypt(country));
    localStorage.setItem('role', encrypt(user.role));
    // Add this to handle logout 
    localStorage.setItem('loggedout', true)
  };
}

export default Login;
