import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

// reactstrap components
import { Card, Table, UncontrolledTooltip, Badge } from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';

import { getRewardByagent, formatPhoneNumber } from '../../../utils/helpers';
import { graphQlQueries } from '../../gql/main-gql';
import { countryVariables } from '../../../utils/country-variables';
import { decrypt } from '../../../utils/utils-2';
import RewardBadge from './badge';

const RewardedAgents = ({ territoryName }) => {
  // Refetch the rewarded query everytime the component rerender
  useEffect(() => {
    refetch();
  });

  const country = decrypt(localStorage.getItem('country'));
  const currencySymbol = countryVariables[country]['currencySymbol'];
  const rewardLabel = countryVariables[country].rewardLabel;
  // Load rewards
  const { data, loading, error, refetch } = useQuery(
    graphQlQueries[country]['awaited-reward'],
    {
      variables: { territoryName: territoryName, rewarded: 'true' },
      // fetchPolicy: "no-cache"
    },
  );
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  const agentRewards = getRewardByagent(data[rewardLabel]);
  return (
    <>
      {country ? (
        <Card className="shadow">
          <Table
            className="align-items-center table-flush custom-table"
            hover
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col" className="pl-3 pr-2">
                  Agent Name
                </th>
                <th scope="col" className="pl-2 pr-2">
                  Rewarded
                  <br /> Item or Cash prize
                </th>
              </tr>
            </thead>
            <tbody>
              {agentRewards.map((agentReward) => {
                return (
                  <tr key={agentReward.id}>
                    <th className="pl-3 pr-2" scope="row">
                      <Link to={'/admin/agent-profile/' + agentReward.username}>
                        {agentReward.fullName}{' '}
                        <strong>
                          ({agentReward.username}){' '}
                          {formatPhoneNumber(agentReward.agentPhone)}
                        </strong>
                      </Link>
                    </th>
                    <td className="pl-2 pr-2" style={{ whiteSpace: 'normal' }}>
                      {agentReward.items.map((reward, index) => (
                        <RewardBadge
                          reward={reward}
                          currencySymbol={currencySymbol}
                          index={index}
                          showPaidDate={true}
                        />
                      ))}
                    </td>
                    <td className="pl-2 pr-0">
                      <div style={{ whiteSpace: 'normal' }}></div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default RewardedAgents;
