import {
  GET_ALL_LB_TERRITORIES,
  GET_SINGLE_LB_TERRITORY,
  UPDATE_LB_COMMISSION,
  GET_SINGLE_LB_AGENT_COMMISSIONS,
  GET_CURRENT_LB_SMSES,
  CREATE_LB_ACTION,
  GET_LB_AWAITED_REWARD,
  UPDATE_LB_REWARD,
  GET_SINGLE_LB_TERRITORY_SUPERVISORS,
  GET_LB_SUPERVISOR_DETAIL,
} from './lib-gql';

import {
  GET_ALL_TERRITORIES,
  GET_SINGLE_TERRITORY,
  UPDATE_COMMISSION,
  GET_SINGLE_AGENT_COMMISSIONS,
  GET_CURRENT_SMSES,
  CREATE_ACTION,
  GET_AWAITED_REWARD,
  UPDATE_REWARD,
  GET_SINGLE_TERRITORY_SUPERVISORS,
  GET_SUPERVISOR_DETAIL,
} from './gql';

import {
  PROSPECTIVE_VALIDATION_COUNT,
  GET_ALL_PROSPECTIVE_VALIDATION,
  UPDATE_PROSPECT_VALIDATION,
  LB_PROSPECTIVE_VALIDATION_COUNT,
  LB_GET_ALL_PROSPECTIVE_VALIDATION,
  LB_UPDATE_PROSPECT_VALIDATION,
} from './prospect'

export const graphQlQueries = {
  'sierra leone': {
    // Name given to main dashboard component
    dashboard: GET_ALL_TERRITORIES,
    dashboardTerritoryResult: 'allTerritories',
    territoryDistrict: 'terriDistrict',
    districtAgent: 'districtAgent',
    // name given to single territory component
    'single-territory': GET_SINGLE_TERRITORY,
    'update-commission': UPDATE_COMMISSION,
    'agent-commissions': GET_SINGLE_AGENT_COMMISSIONS,
    smses: GET_CURRENT_SMSES,
    'create-action': CREATE_ACTION,
    'awaited-reward': GET_AWAITED_REWARD,
    'update-reward': UPDATE_REWARD,
    'single-supervisor': GET_SINGLE_TERRITORY_SUPERVISORS,
    'supervisor-detail': GET_SUPERVISOR_DETAIL,

    'prospect-validation-count': PROSPECTIVE_VALIDATION_COUNT,
    'all-prospective-validation': GET_ALL_PROSPECTIVE_VALIDATION,
    'update-prospective-validation': UPDATE_PROSPECT_VALIDATION,
  },
  liberia: {
    dashboard: GET_ALL_LB_TERRITORIES,
    dashboardTerritoryResult: 'allLbTerritories',
    territoryDistrict: 'lbTerriDistrict',
    districtAgent: 'districtLbAgent',
    'single-territory': GET_SINGLE_LB_TERRITORY,
    'update-commission': UPDATE_LB_COMMISSION,
    'agent-commissions': GET_SINGLE_LB_AGENT_COMMISSIONS,
    smses: GET_CURRENT_LB_SMSES,
    'create-action': CREATE_LB_ACTION,
    'awaited-reward': GET_LB_AWAITED_REWARD,
    'update-reward': UPDATE_LB_REWARD,
    'single-supervisor': GET_SINGLE_LB_TERRITORY_SUPERVISORS,
    'supervisor-detail': GET_LB_SUPERVISOR_DETAIL,
    'prospect-validation-count': LB_PROSPECTIVE_VALIDATION_COUNT,
    'all-prospective-validation': LB_GET_ALL_PROSPECTIVE_VALIDATION,
    'update-prospective-validation': LB_UPDATE_PROSPECT_VALIDATION,
  },
};
