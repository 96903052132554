import gql from 'graphql-tag'


export const GET_SHOP_REVENUES = gql`
query GetAllShopSales($week: String, $range: String, $shops: String, $directsales: String) {
  allSales(week: $week, range: $range, shops: $shops, directsales: $directsales) {
    edges {
      node {
        unlockPrice
        accountNumber
        brandAmbassador
        groupName
        responsibleUser {
          username
        }
        pricingGroup {
          groupName
        }
      }
    }
  }
}
`
export const GET_ALL_SHOPS = gql`
query GetAllShop {
  allShops {
    edges {
      node {
        firstName
        lastName
        username
        baPseudoCode
        primaryPhone
        role
        territory {
          name
          abbreviation
        }
        staff {
          edges {
            node {
              fullName
              role
              primaryPhoneNumber
              baCode
              isActive
            }
          }
        }
      }
    }
  }
}
`

export const GET_TERRITORY_SHOPS = gql`
query GetTerritoryShops {
  allTerritories {
    edges {territory
      node {
        name
        abbreviation
        terriShop {
          edges {
            node {
              id
              firstName
              lastName
              username
              baPseudoCode
              primaryPhone
              territory {
                name
              }
              staff {
                edges {
                  node {
                    fullName
                    role
                    primaryPhoneNumber
                    baCode
                    isActive
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const GET_ALL_SHOP_STAFF = gql`
query GetAllStaff ($ambassador: String, $shop: String, $admin: String, $pdsambassador: String) {
  allShopStaffs(ambassador: $ambassador, shop: $shop, admin: $admin, pdsambassador: $pdsambassador) {
    edges {
      node {
        fullName
        role
        primaryPhoneNumber
        baCode
        isActive
        startDate
        bankName
        BBANNumber
        bankAccount
        shop {
          username
          firstName
        }
      }
    }
  }
}
`

export const GET_ALL_PROSPECTS = gql`
query GetAllProspects($week: String, $range: String, $pdsba: String) {
  allProspects(week: $week, range: $range, pdsba: $pdsba) {
    edges {
      node {
        upfrontPrice
        unlockPrice
        status
        brandAmbassador
        createdUtc
        registeredAccountNumber
        pricingGroup
      }
    }
  }
}
`

export const GET_SETTING_COMMISSIONS = gql`
query GetSettingCommissions {
  allSettingCommissions {
    nameOfCommission
    commissionPercentage
    fixedCompensation
  }
}
`

export const GET_SHOPS_FOR_REPORT = gql`
query GetAllShopReport {
  allShops {
    edges {
      node {
        firstName
        lastName
        username
      }
    }
  }
}
`

export const GET_SHOPS_REMITS = gql`
query GetShopRemits ($range: String) {
  allRemits (range: $range) {
    edges {
      node {
        user
        amount
      }
    }
  }
}
`

export const GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS = gql`
query GetAllBaMonthlyConverted($endofmonth: String, $role: String) {
  allBaMonthlyConverted(endofmonth: $endofmonth, role: $role) {
    edges {
      node  {
        baFullName
        territoryName
        shopName
        baPhoneNumber
        role
        region
        baCode
        endOfMonthDate
        monthlyPoints
        totalMonthlyPoints
        commissionOnConvertedSales
        consecutiveMonthlyInactivity
        monthlyProspected
        monthlyConverted
        monthlyPoints
        monthlyRevenue
        commissionOnConvertedSales
        monthlyAllowance
        bonus
        topPerformerAward
        monthlyCommission
        pdsRevenue
        retailRevenue
        brandAmbassador {
          bankName
          bankAccount
          BBANNumber
        }
      }
    }
  }
}
`

export const GET_PRODUCTS = gql`
query GetAllProducts {
  allProducts {
    edges {
      node {
        name
        prdPointSystem {
          credit
          cash
        }
      }
    }
  }
}
`

export const GET_NEW_PRCING_GROUPS = gql`
query GetAllPricingGroups {
  allNewPricingGroups {
    edges {
      node {
        shortName
        points
      }
    }
  }
}
`

export const GET_SHOPS_LEADERBOARD = gql`
query GetAllShopS {
  allShops {
    edges {
      node {
        firstName
        lastName
        username
        territory {
          name
        }
      }
    }
  }
}
`

export const GET_SHOP_BONUSES = gql`
query GetShopBonuses ($startOfMonthDate: Date, $endOfMonthDate: Date) {
  allShopBonuses(startOfMonthDate: $startOfMonthDate, endOfMonthDate: $endOfMonthDate){
    edges {
      node {
        startOfMonthDate
        endOfMonthDate
        shopName
        monthBonus
        monthRevenue
        bonusToWithhold
        bonusToRelease
        totalRemitted
        shopBonus
        revenueWithKycIssue
        accountsWithKycIssues
      }
    }
  }
}
`
