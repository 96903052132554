import gql from 'graphql-tag'


export const GET_SHOP_BONUSES = gql`
query GetShopBonuses ($startOfMonthDate: Date, $endOfMonthDate: Date) {
  allLbShopBonuses(startOfMonthDate: $startOfMonthDate, endOfMonthDate: $endOfMonthDate){
    edges {
      node {
        startOfMonthDate
        endOfMonthDate
        shopName
        monthBonus
        monthRevenue
        bonusToWithhold
        bonusToRelease
        totalRemitted
        shopBonus
        revenueWithKycIssue
        accountsWithKycIssues
      }
    }
  }
}
`


export const GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS = gql`
query GetAllBaMonthlyConverted($endofmonth: String, $role: String) {
  allLbBaMonthlyConverted(endofmonth: $endofmonth, role: $role) {
    edges {
      node  {
        baFullName
        territoryName
        shopName
        baPhoneNumber
        role
        region
        baCode
        endOfMonthDate
        monthlyPoints
        totalMonthlyPoints
        commissionOnConvertedSales
        consecutiveMonthlyInactivity
        monthlyProspected
        monthlyConverted
        monthlyPoints
        monthlyRevenue
        commissionOnConvertedSales
        monthlyAllowance
        bonus
        topPerformerAward
        monthlyCommission
        pdsRevenue
        retailRevenue
        brandAmbassador {
          bankName
          bankAccount
          BBANNumber
        }
      }
    }
  }
}
`
