import React, {useState} from 'react'

import { Query } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { GET_SHOP_BONUSES } from '../gql/lib-gql-sec';
import ShopStaffMonthly from './shop-staff-monthly';
import { constructMonthlyShopStaffBonuses } from '../../utils/utils';
import { changeNumberFormat } from '../../utils/helpers';


const ShopStaffBonuses = ({startDate, endDate, exchanger}) => {

  const [bonusesData, setBonusesData] = useState([]);

  // Excel related info
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formattedStart = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const fileName = formattedStart !== formattedEnd ? `${formattedStart} - ${formattedEnd} Shop Staff Bonuses Report` : `${formattedStart} Shop Staff Bonuses Report`;


  const generateBonusesData = (bonusEdges) => {
    const shopBonuses = bonusEdges.map(shopBonus => {
      return shopBonus.node;
    });
    // Construct shop staff export data
    const shopStaffBonusData = constructMonthlyShopStaffBonuses(shopBonuses);
    // Update excel report data state
    setBonusesData(shopStaffBonusData);
  }

  const exportToExcel = (excelData, fileName) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const end = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const wsCols = excelData.map((item, index) => { 
      return { wch: 13 } });
    // Adding 4 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 4)).map(item => { return { hpt: 20 } });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A3' });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} Shop Staff Bonuses Report`
    ws['C1'] = { t: 's', v: title };

    // // Change headers Name
    ws.A3.v = 'Shop Name'; ws.B3.v = 'Total Revenue'; ws.C3.v = 'Revenue with KYC issues';
    ws.D3.v = 'Total Remitted'; ws.E3.v = 'Total Bonus without KYC issue';
    ws.F3.v = 'KYC withheld bonus';
    ws.G3.v = 'KYC released Bonus'; ws.H3.v = 'Shop Staff bonus'; ws.I3.v = 'Accounts with KYC issues'

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws['!ref'].split(':')[0]
    const lastRefCell = ws['!ref'].split(':')[1]

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1,));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws['!ref'] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ['B', 'C', 'D'], '#,##0')


    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' }; ws[`B${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(B4:B${lastRefCellRow})`, z: '#,##0' };
    ws[`C${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(C4:C${lastRefCellRow})`, z: '#,##0' }; ws[`D${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(D4:D${lastRefCellRow})`, z: '#,##0' };
    ws[`E${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(E4:E${lastRefCellRow})`, z: '#,##0' };
    ws[`F${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(F4:F${lastRefCellRow})`, z: '#,##0' };
    ws[`G${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(G4:G${lastRefCellRow})`, z: '#,##0' };
    ws[`H${lastRefCellRow + 1}`] = { t: 'n', f: `SUM(H4:H${lastRefCellRow})`, z: '#,##0' };

    /* Write total data */
    const wb = { Sheets: { 'Shop Staff bonuses': ws }, SheetNames: ['Shop Staff bonuses'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  //  excel exporter to call from parent component
  const staffBonusExcelExporter = () => {
    exportToExcel(bonusesData, fileName)

  }

  // Add the child function to exchanger that might be called by the parent.
  exchanger.staffBonusExcelExporter = staffBonusExcelExporter;

  return (
    <>
    <div>
      {/*Normal Table*/}
      <Query query={GET_SHOP_BONUSES}
        variables={{startOfMonthDate: startDate, endOfMonthDate: endDate}}
        fetchPolicy='no-cache'
        onCompleted={data => generateBonusesData(data.allLbShopBonuses.edges)}
      >
        {({ loading, error, data }) => {
          if (loading) return <p className='text-white'>LOADING...</p>
          if (error) {
            return <p>Error. Try refreshing the page</p>
          }

          return <ShopStaffMonthly
           shopStaffReportData={bonusesData}/>
        }}
      </Query>
    </div>
     
    </>
  )
}

export default ShopStaffBonuses
