import React, { useEffect, useState } from 'react';
import {useLazyQuery, useMutation} from 'react-apollo';

// reactstrap components
import {
  Badge,
  Row,
  Card,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  Media,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  Table, Pagination, PaginationItem, PaginationLink, CardFooter,
  FormGroup,
  InputGroupText,
} from 'reactstrap';
import dayjs from 'dayjs';
import { graphQlQueries } from '../gql/main-gql';
import { generateKey, isEmpty, sortByValueName } from '../../utils/utils';
import {ApprouveModal, AlertModal} from './modal'
import TableSpinner from "../misc/table-spinner";
import NoDataTable from "../misc/no-data-table";
import {territories} from './const';

const ToApprovedProspects = (props) => {

  const {country} = props;
  const [filters, setFilters] = useState(territories[country]);

  const [selectedFilters, setSelectedFilters] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)

  const [queryVariable, setQueryVariable] = useState ({
    recommendedStatus: "TO_APPROVE",
    listOfValidationStatusToExclude: ["REJECTED", "APPROVED"],
    first: 30,
  })

  const [search, setSearch] = useState('')
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false
  })

  const addFilter = (filter) => {
    let old = [...selectedFilters]
    if (!old.includes(filter)) {
      old.push(filter)
      setSelectedFilters(old)
      fetchData({
        variables: {
          ...queryVariable,
          territory: old,
          after: '',
          before: '',
        }
      })
    }
  }

  const removeFilter = (filter) => {
    let arr = selectedFilters.filter(x => x !== filter);
    setSelectedFilters(arr)
    fetchData({
      variables: {
        ...queryVariable,
        territory: arr,
        after: '',
        before: '',
      }
    })
  }

  //
  // Fetching Data
  //
  const [fetchData, {loading: dataLoading}] = useLazyQuery(
    graphQlQueries[country]['all-prospective-validation'],
    {
      onCompleted(data) {
        handleFetchedData(data)
        setLoading(false)
      },
      onErron(err) {
        setLoading(false)
        // console.log('SYSTEM ERROR: ', err)
      },
      fetchPolicy: 'no-cache'
    },
  );

  const handleFetchedData = (rawData) => {
    const all = country == 'liberia' ? rawData?.allLbProspectiveValidations : rawData?.allProspectiveValidations
    setFilteredData(all?.edges)

    // pagination
    setPageInfo({
      ...pageInfo,
      hasNextPage: all?.pageInfo.hasNextPage,
      hasPreviousPage: all?.pageInfo.hasPreviousPage
    })

    const lastData = all?.edges[all?.edges.length - 1]
    if (lastData && lastData.cursor) {
      setQueryVariable({
        ...queryVariable,
        after: lastData.cursor,
        search: !isEmpty(search) ? search : ''
      })
    }
  }

  //
  // modals
  //

  const [approuveModalData, setApprouveModalData] = useState({display: false});
  const [alertData, setAlertData] = useState({display: false});

  const showValidationConfirm = (data) => {
    setApprouveModalData({
      display: true,
      ...data
    })
  }

  const hideValidationConfirm = () => {
    setApprouveModalData({
      display: false,
    })
  }

  const showAlertModal = (data) => {
    setAlertData({
      display: true,
      ...data,
    })
  }

  const hideAlertModal = () => {
    setAlertData({
      display: false,
      title: '',
      body: ''
    })
  }

  const [updateProspectiveMutation] = useMutation(graphQlQueries[country]['update-prospective-validation']);
  const updateProspect = (propectData, status) => {
    setLoading(true)
    if (!isEmpty(propectData)) {
      updateProspectiveMutation({
        variables: {
          id: propectData?.id,
          userEmail: props.userEmail,
          validationStatus: status
        }
      })
      .then( data => {
        // console.log('Mutation success : ', data)
        showAlertModal({
          display: true,
          title: 'Success',
          body: `the prospect ${propectData.prospectName} has been ${status.toLowerCase()} successfully`,
        })

        setLoading(true)
        props.countProspectData()
        fetchData({ variables: {
          ...queryVariable,
          after: '',
          before: '',
        }})

      })
      .catch(err => console.log('Approuval error: ', err))
      .finally(() =>{
        setLoading(false)
        hideValidationConfirm()
        }
      )
    }
  }

  useEffect(()=> {
    setFilters(territories[country])
    setLoading(true)
    fetchData({ variables: {
      ...queryVariable,
      before: '',
      after: '',
      first: 30
    }})
    setSelectedFilters([])
  }, [country])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(true)
      fetchData({
        variables: {
          ...queryVariable,
          search,
          after: '',
          before: ''
        }
      })
    }, 1000);

    return () => {
        clearTimeout(timerId);
    };
}, [search]);

// sorting
const [currentSort, setCurrentSort] = useState({
  name: '',
  sort: '',
})

const sortData = (name) => {
  setCurrentSort({
    sort: currentSort.sort === 'desc' ? 'asc' : 'desc',
    name: name,
  })

  setFilteredData(sortByValueName(filteredData, name, currentSort.sort))
};

  return (
    <>
      {/*<Card className="shadow">*/}
      {/*  <DataTable title={false} columns={columns} data={sampleList} pagination />*/}
      {/*</Card>*/}
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row>
            <Col md="5">
              <div className="d-flex">
                <UncontrolledButtonDropdown className="mr-2 filter-dropdown">
                  <DropdownToggle color="primary" className="rounded btn-icon">
                    <span className="btn-inner--icon">
                      <i className="ni ni-ui-04" />
                    </span>
                    <span className="btn-inner--text">Filter</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Row>
                      <Col sm="12">
                        <h5>Territory</h5>
                        <hr className="mt-0 mb-2"/>
                      </Col>
                      {filters && filters.map((e, key) => {
                        return (
                        <Col md={"6"} key={`territory_key_${e}`}>
                          <div className={"custom-control custom-checkbox mb-3"} key={key}>
                            <input
                              onClick={e => addFilter(e.target.value)}
                              className={"custom-control-input"}
                              id={`to_approuve_customCheck_${key}`}
                              type={"checkbox"}
                              value={e}
                              checked={selectedFilters.includes(e) ? true : false}
                            />
                            <label className={"custom-control-label"} htmlFor={`to_approuve_customCheck_${key}`}>
                              {e}
                            </label>
                          </div>
                        </Col>
                        )
                      })}
                    </Row>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <FormGroup className="mb-0 w-100">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-zoom-split-in" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e)=>setSearch(e.target.value)}
                      placeholder="Search"
                      type="text"
                      value={search}
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </Col>
            <Col md="7">
              <div className="filter-badges">
              {
                  selectedFilters && selectedFilters.map((e, key) => {
                    return (
                      <Badge color={"primary"} value={e} onClick={()=>removeFilter(e)} key={`${generateKey()}_reject_${key}`}>
                        {e}
                        <i className={"fa fa-times-circle ml-2"}
                           value={e}
                        />
                      </Badge>
                  )
                })
                }
              </div>
            </Col>
          </Row>
        </CardHeader>

        {loading && <TableSpinner/>}
        {!loading && !isEmpty(filteredData) && (
          <Table className="align-items-center table-flush" striped hover responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" className="pr-0" />
              <th scope="col"
                onClick={()=>sortData('createdDate')}
              >
                Date
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'createdDate' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'createdDate' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
              <th scope="col"
                onClick={()=>sortData('prospectName')}
              >
                Client name
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'prospectName' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'prospectName' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
              <th scope="col"
                onClick={()=>sortData('phone')}
              >
                Phone number
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'phone' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'phone' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
              <th scope="col"
                onClick={()=>sortData('responsibleUser')}
              >
                Agent
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'responsibleUser' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'responsibleUser' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
              <th scope="col"
                onClick={()=>sortData('points')}
              >
                Score
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'points' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'points' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
              <th scope="col"
                onClick={()=>sortData('pricingGroup')}
              >
                Pricing group
                <span className="ml-3 btn-inner--icon">
                  {currentSort.name === 'pricingGroup' && currentSort.sort === 'asc' &&  <i className="ni ni-bold-down" /> }
                  {currentSort.name === 'pricingGroup' && currentSort.sort === 'desc' &&  <i className="ni ni-bold-up" /> }
                </span>
              </th>
            </tr>
            </thead>
            <tbody>
            {filteredData.map((e) => {
              const item = e?.node

              return (
              <tr key={item.id}>
                <td className="text-right pr-0">
                <td className="text-right pr-0">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        onClick={()=> showValidationConfirm(item)}
                      >
                        <i className="ni ni-check-bold" />
                        Approve
                      </DropdownItem>
                      <DropdownItem>
                        <i className="fa fa-external-link-alt" />
                        <a
                          target={'_blank'}
                          href={`https://payg.angazadesign.com/ui/prospects/detailed/${item.angazaId}`}
                        >
                          View prospect on Angaza
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
                </td>
                <td>
                  {dayjs(item.createdDate).format('YYYY-MM-DD')}
                </td>
                <th scope="row">
                  <Media className="align-items-center">
                    <Media>
                      <span className="mb-0 text-sm">
                        {item.prospectName}
                      </span>
                    </Media>
                  </Media>
                </th>
                <td>
                  {item.phone}
                </td>
                <td>
                  <strong>{item.responsibleUser}</strong>
                </td>
                <td>
                  <Badge color="" className="badge-dot mr-4">
                    <i className={item.scoreStatus} />
                    {item.points}
                  </Badge>
                </td>
                <td>
                  {item.pricingGroup}
                </td>
              </tr>
            )}
            )}
          </tbody>
          </Table>
        )}
        {!loading && isEmpty(filteredData) && (
          <NoDataTable title={'prospect to approuve'}/>
        )}
        {/* {!loading && !isEmpty(filteredData) && ( */}
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem
                className={pageInfo.currentPage > 1 ? '' : 'disabled'}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={() => {
                    fetchData({
                      variables: {
                        ...queryVariable,
                        before: !isEmpty(queryVariable.after) ? queryVariable.after : queryVariable.before,
                        after: ''
                      }
                    })
                    setPageInfo({
                      ...pageInfo,
                      currentPage : pageInfo.currentPage > 0 ? pageInfo.currentPage - 1 : 1
                    })
                  }
                }>
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem className="active">
                <PaginationLink href="#pablo" >
                  {pageInfo?.currentPage}
                <span className="sr-only">(current)</span>
                </PaginationLink>
              </PaginationItem>
              <PaginationItem
                // className={filteredData.length >= 20 ? '' : 'disabled'}
              >
                <PaginationLink
                  href="#pablo"
                  onClick={(e) => {
                    fetchData({
                      variables: {
                        ...queryVariable,
                        after: !isEmpty(queryVariable.after) ? queryVariable.after : queryVariable.before,
                        before: ''
                      }
                    })
                    setPageInfo({
                      ...pageInfo,
                      currentPage : pageInfo.currentPage + 1
                    })
                  }}>
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardFooter>
         {/* )} */}
      </Card>
      <ApprouveModal
        data={approuveModalData}
        onCancel={hideValidationConfirm}
        onConfirm={() =>updateProspect(approuveModalData, 'approved')}
        loading={loading}
      />
      <AlertModal
        data={alertData}
        onConfirm={hideAlertModal}
        loading={loading}
      />
    </>
  );
};

export default ToApprovedProspects;
