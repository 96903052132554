import React, { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  Badge,
  CardBody,
  Container,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
  Col,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { singleAgentCommissions } from '../../utils/helpers';
import { GET_AGENT_REWARDS, GET_LOGS } from '../gql/gql';

import logo from '../../assets/img/brand/easy-logo.png';

const AgentProfile = ({ commissionData, currentThursday }) => {
  const [isOpen, setIsOpen] = useState(false);
  let history = useHistory();
  const commission = singleAgentCommissions(
    commissionData.allCommissions.edges,
    currentThursday,
  );
  let agentCommission;
  const [fetchRewards, { data }] = useLazyQuery(GET_AGENT_REWARDS);

  const handleRewardClick = (username) => {
    setIsOpen(!isOpen);
    fetchAgentRewards(username);
  };

  const handleTouchEnd = (username) => {
    setIsOpen(!isOpen);
    fetchAgentRewards(username);
  };

  const fetchAgentRewards = (username) => {
    // kind of weird but, this function being called
    // at the same the state of open is being changed, it does not reflect the real
    // state change. That's we are grapping the opposite.
    if (!isOpen) {
      fetchRewards({ variables: { username: username } });
    }
  };

  const { data: logData, loading: load, error: err } = useQuery(GET_LOGS);
  if (load) return `LOADING...`;
  if (err) return `Error! ${err.message}`;
  if (!logData) return `NOT FOUND`;

  const commandLogs = logData.allLogs.edges.map((item) => item.node);
  let lastRemitCommand;
  if (commandLogs.length) {
    lastRemitCommand = commandLogs[0];
  }

  const logout = () => {
    localStorage.removeItem('token');
    // Remove the user email as well
    localStorage.removeItem('userEmail');
    localStorage.removeItem('isStaff');
    localStorage.removeItem('username');
    localStorage.removeItem('isSuperuser');
    localStorage.removeItem('country');
    localStorage.removeItem('role');
    history.push('/login');
  };

  if (commission.length) {
    agentCommission = commission[0];

    return (
      <>
        <Container fluid>
          <img src={logo} className="logo-profile" alt="Easy Solar Logo" />
          <div className="agent-profile-name">
            <h2 className="text-white">
              Hi{' '}
              <span role="img" aria-labelledby="clap emoji">
                👋🏾
              </span>{' '}
              {agentCommission.agent.firstName}
            </h2>
          </div>
          <div className="mb-1">
            <Button
              color="warning"
              block
              id="profile"
              className="d-flex justify-content-between"
            >
              <span>My Profile</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#profile">
              <Card className="card-profile shadow mt-1">
                <CardBody>
                  <div className="text-center">
                    <h3 className="mb-0">
                      {agentCommission.agent.firstName}{' '}
                      {agentCommission.agent.lastName}
                    </h3>
                    <p className="font-weight-bold">
                      ({agentCommission.agent.username})
                    </p>
                  </div>
                  <Row>
                    <Col>
                      <div>
                        <div className="mb-3">
                          <small>
                            <span className="fas fa-calendar mr-1"></span>
                            <strong>
                              Start Date <br />
                            </strong>
                          </small>
                          <span>
                            {new Date(
                              agentCommission.agent.createdUtc,
                            ).toLocaleString('en-us', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </span>
                        </div>
                        <div className="mb-2">
                          <small>
                            <span className="fas fa-history mr-1"></span>
                            <strong>
                              Years of service
                              <br />
                            </strong>
                          </small>
                          <span>{agentCommission.yearsOfService}</span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right">
                        <div className="mb-3">
                          <small>
                            <span className="fas fa-globe-africa mr-1"></span>
                            <strong>
                              Territory
                              <br />
                            </strong>
                          </small>
                          <span>
                            {agentCommission.agent.district.territory.name}
                          </span>
                        </div>
                        <div className="mb-2">
                          <small>
                            <span className="fas fa-user-tie mr-1"></span>
                            <strong>
                              Supervisor
                              <br />
                            </strong>
                          </small>
                          <span>
                            {agentCommission.agent.supervisor.firstName}{' '}
                            {agentCommission.agent.supervisor.lastName} (
                            {agentCommission.agent.supervisor.username})
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <div className="mb-1">
                      <span className="fas fa-gifts mr-1"></span>
                      <span className="description">Products sold:</span>
                      <span className="heading pl-1">
                        {agentCommission.totalProductsSold}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-lock-open mr-1"></span>
                      <span className="description">Products Unlocked:</span>
                      <span className="heading pl-1">
                        {agentCommission.totalProductsUnlocked}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-grin-stars mr-1"></span>
                      <span className="description">Points:</span>
                      <span className="heading pl-1">
                        {agentCommission.totalPoints}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="mb-1">
            <Button
              color="warning"
              block
              id="remittance"
              className="d-flex justify-content-between"
            >
              <span>Remittance</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#remittance">
              <Card className="shadow mt-1">
                <CardBody className="p-3">
                  <div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">
                        Balance (Friday morning):
                      </span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.balanceFridayMorning}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Expected Remittance:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.expectedRemittance}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Total Remitted:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.totalRemitted}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="mb-1">
            <Button
              color="warning"
              block
              id="commission"
              className="d-flex justify-content-between"
            >
              <span>Commission</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#commission">
              <Card className="shadow mt-1">
                <CardBody className="p-3">
                  <div className="d-flex justify-content-end loan-indicator">
                    {agentCommission.angazaAdjustementDone ? (
                      <div className="btn btn-success btn-sm">
                        Commission deducted
                      </div>
                    ) : (
                      <div className="btn btn-info btn-sm">
                        Commission not deducted
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="mb-1 mt-4">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Weekly Owed:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.weeklyOwed}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Weekly Topup:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.weeklyTopup}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Remit Commission:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.remitCommission}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Payment Commission:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.mobilePaymentCommission}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Owed Before:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.commissionOwedBefore}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Reward:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.agentOfTheMonthAndOtherRewards}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <hr className="mt-0 mb-0" />
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Total Owed:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.commissionOwedForTheWeek}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="mb-1">
            <Button
              color="warning"
              block
              id="loan"
              className="d-flex justify-content-between"
            >
              <span>Loan</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#loan">
              <Card className="shadow mt-1">
                <CardBody className="p-3">
                  <div>
                    <div className="d-flex justify-content-end loan-indicator">
                      {agentCommission.debtAfterRemit > 0 ? (
                        <div className="btn btn-info btn-sm">On Loan</div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="mb-1 mt-3">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">
                        Manual Debt adjustments:
                      </span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.manualDebtAdjustments}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Com to deduct:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.commissionToDeductFromDebt}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Before Remit:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.debtBeforeRemit}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">After Remit:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.debtAfterRemit}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <hr className="mt-0 mb-0" />
                    <div className="mb-1">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description">Debt Before Remit:</span>
                      <span className="heading pl-1">
                        <CurrencyFormat
                          value={agentCommission.debtBeforeRemit}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="mb-1">
            <Button
              color="warning"
              block
              id="products"
              className="d-flex justify-content-between"
            >
              <span>Products</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#products">
              <Card className="card-profile shadow mt-1">
                <CardBody>
                  <div>
                    <div className="text-center text-md-left">
                      <strong>This week</strong>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-gifts mr-1"></span>
                      <span className="description">Products sold:</span>
                      <span className="heading pl-1">
                        {agentCommission.productsSold}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-lock-open mr-1"></span>
                      <span className="description">Products Unlocked:</span>
                      <span className="heading pl-1">
                        {agentCommission.productsUnlocked}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-grin-stars mr-1"></span>
                      <span className="description">Points:</span>
                      <span className="heading pl-1">
                        {agentCommission.weeklyPoints}
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-trophy mr-1"></span>
                      <span className="description">Tier:</span>
                      <span
                        className={
                          'heading pl-1 span-' + agentCommission.agentTierStatus
                        }
                      >
                        <sup className="text-lowercase font-weight-400 text-primary"></sup>
                      </span>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-star mr-1"></span>
                      <span className="description">Reward:</span>
                      <span className="heading pl-1">
                        {agentCommission.reward}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="mb-1">
            <Button
              color="warning"
              block
              id="rewards"
              style={{ cursor: 'pointer' }}
              className="d-flex justify-content-between"
              onClick={() => handleRewardClick(agentCommission.agent.username)}
              onTouchEnd={() => handleTouchEnd(agentCommission.agent.username)}
            >
              <span>Rewards</span>
              <span className="btn-inner--icon text-right">
                <i className="fa fa-chevron-right" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#rewards">
              <Card className="card-profile shadow mt-1">
                <CardBody>
                  <div>
                    <div className="text-center text-md-left">
                      <strong>This week</strong>
                    </div>
                    <div className="mb-1">
                      <span className="fas fa-star mr-1"></span>
                      <span className="description">Reward:</span>
                      <span className="heading pl-1">
                        {agentCommission.reward}
                      </span>
                    </div>
                    {data ? (
                      data.allRewards.length ? (
                        <Card className="">
                          <div className="ml-3 mt-1 mb-1">
                            <span className="fas fa-gift mr-1"></span>
                            <span className="description">All Rewards:</span>
                            <span className="heading pl-1">
                              {data.allRewards.length}
                            </span>
                          </div>
                          <CardBody className="p-2">
                            {data.allRewards.map((reward, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className="btn btn-sm btn-primary mr-1 mb-1"
                                    id={'tooltip' + index}
                                  >
                                    <span>
                                      {reward.pointSystemReward.rewardDetail}
                                    </span>
                                    <Badge color="info" pill>
                                      {reward.datePaid ? (
                                        <small className="text-white">
                                          <strong>Paid</strong>
                                        </small>
                                      ) : (
                                        <small className="text-white">
                                          <strong>Pending</strong>
                                        </small>
                                      )}
                                    </Badge>
                                  </div>
                                  {reward.datePaid ? (
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={'tooltip' + index}
                                    >
                                      <div className="text-left">
                                        <strong>
                                          Date Paid:{' '}
                                          {new Date(
                                            reward.datePaid,
                                          ).toLocaleString('en-us', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                          })}
                                        </strong>{' '}
                                        <br />
                                      </div>
                                    </UncontrolledTooltip>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </CardBody>
                        </Card>
                      ) : (
                        <p>No reward</p>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-5">
            <small className="text-white ml-2">
              <i>Last updated:</i>{' '}
              {lastRemitCommand
                ? new Date(lastRemitCommand.finished).toLocaleString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                  })
                : 'No update yet'}
            </small>
            <div className="mr-2">
              <Button size={'sm'} color={'info'} onClick={logout}>
                Logout
              </Button>
            </div>
          </div>
        </Container>
      </>
    );
  }
  return <p className="text-white">LOADING...</p>;
};

export default AgentProfile;
