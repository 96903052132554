import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

// reactstrap components
import { Card, Table, Badge, UncontrolledTooltip } from 'reactstrap';

import { getRewardByagent, formatPhoneNumber } from '../../../utils/helpers';
import RewardPaymentModal from '../../modals/reward-payment-modal';
import { decrypt } from '../../../utils/utils-2';
import { countryVariables } from '../../../utils/country-variables';
import RewardBadge from './badge';

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const AwaitingRewards = forwardRef(({ data, refresh, territoryName }, ref) => {
  // agentRewards point to reward reception list
  const agentRewards = getRewardByagent(data);

  // Initialize the data with a random length to make checkboxes controlled component
  // We take 1000 because we do not know before hand the number of agents in a territory
  const [checkedCheckboxes, handleChechedCheckboxes] = useState(
    Array(100).fill(false),
  );
  const [selectedRewards, setSelectedRewards] = useState([]);
  const [showModal, toggleModal] = useState(false);

  const country = decrypt(localStorage.getItem('country'));
  const currencySymbol = countryVariables[country]['currencySymbol'];

  // Update initial array when component render
  useEffect(() => {
    const array = Array(agentRewards.length).fill(false);
    handleChechedCheckboxes(array);
  }, [agentRewards.length]);

  // Handle checkbox changes
  const handleCheckboxClick = (e, index) => {
    let value = checkedCheckboxes.slice();
    value[index] = !value[index];
    handleChechedCheckboxes(value);
  };

  const handleRewardSelect = (e) => {
    const agentReward = JSON.parse(e.target.value);
    const id = agentReward.id; // which is the agent id

    if (e.target.checked) {
      setSelectedRewards((selectedRewards) => [
        ...selectedRewards,
        agentReward,
      ]);
    } else {
      setSelectedRewards(selectedRewards.filter((item) => item.id !== id));
    }
  };

  // Toggle modal
  const toggler = () => {
    if (showModal) {
      handleChechedCheckboxes(Array(agentRewards.length).fill(false));
      setSelectedRewards([]);
      // Refetch awaiting rewards everytime we close the modal
      refresh();
    }
    toggleModal(!showModal);
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    toggleModal() {
      toggler();
    },
  }));

  // Define props to pass to the modal component
  const props = {
    showModal,
    toggler,
    selectedRewards,
    data,
    country,
    territoryName,
  };

  return (
    <>
      <RewardPaymentModal props={props} />
      <Card className="shadow">
        <Table
          className="align-items-center table-flush custom-table"
          hover
          responsive
        >
          <thead className="thead-light">
            <tr>
              <th className="table-checkbox">
                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="selectAll"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="selectAll"
                  ></label>
                </div>
              </th>
              <th scope="col">Agent Name</th>
              <th
                style={{ width: 52 }}
                className="pl-1 pr-1 text-center"
                scope="col"
              >
                Total <br /> Points
              </th>
              <th scope="col">
                Eligible Reward
                <br /> Item or Cash prize
              </th>
            </tr>
          </thead>
          <tbody>
            {agentRewards.map((agentReward, index) => {
              const selection = JSON.stringify({
                id: agentReward.id,
                agentUsername: agentReward.username,
                agentFullName: agentReward.fullName,
                rewardItems: agentReward.items,
                date: agentReward.currentWeekThursday,
                agentLastCommissionId: agentReward.lastCommissionId,
              });
              return (
                <tr key={agentReward.id}>
                  <td className="table-checkbox">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        id={'select' + agentReward.id}
                        checked={checkedCheckboxes[index]}
                        type="checkbox"
                        value={selection}
                        onChange={(e) => handleRewardSelect(e)}
                        onClick={(e) => handleCheckboxClick(e, index)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={'select' + agentReward.id}
                      ></label>
                    </div>
                  </td>
                  <th scope="row">
                    <Link to={'/admin/agent-profile/' + agentReward.username}>
                      {agentReward.fullName}{' '}
                      <strong>
                        ({agentReward.username}){' '}
                        {formatPhoneNumber(agentReward.agentPhone)}
                      </strong>
                    </Link>
                  </th>
                  <td className="text-center pl-1 pr-1">
                    {agentReward.totalPoints}
                  </td>
                  <td style={{ whiteSpace: 'normal' }}>
                    {agentReward.items.map((reward, index) => (
                      <RewardBadge
                        reward={reward}
                        currencySymbol={currencySymbol}
                        index={index}
                      />
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
});

export default AwaitingRewards;
