import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {useQuery, useLazyQuery} from 'react-apollo';
// reactstrap components
import {
  Badge,
  Container,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import ApprovedProspects from "./approved";
import ToApprovedProspects from "./to-approved";
import ToRejectProspects from "./to-reject";
import ToQuestionProspects from "./to-question";
import ToDecideProspects from "./to-decide";

import { graphQlQueries } from '../gql/main-gql';
import CountryFilter from '../layouts/country-filter';
import { decrypt } from '../../utils/utils-2';
import { isEmpty } from '../../utils/utils';


const Prospects = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [prospectValidationCount, setProspectValidationCount] = useState({
      approuved: 0,
      toApprouve: 0,
      toReject: 0,
      toQuestion: 0,
      toDecide: 0,
      rejected: 0
  });

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const [country, setCountry] = useState('sierra leone');
  const [userEmail, setUserEmail] = useState('');

  const countryFiltering = (country) => {
    if (['sierra leone', 'liberia'].includes(country)) {
      setCountry(country);
    }
  };

  const [countProspectData, {loading, data, error}] = useLazyQuery(
    graphQlQueries[country]['prospect-validation-count'],
    {
      onCompleted(data) {
        console.log('==Prospect refetched ==')
        handleFetchedData(data)
      },
      fetchPolicy: 'no-cache',
      onErron(err) {
        console.log('===Error===: ', err)
      },
    },
  );

  const handleFetchedData = (rawData) => {
    const data = country == 'liberia' ? rawData.lbProspectValidationCount : rawData.prospectValidationCount
    setProspectValidationCount(data[0])
  }

  useEffect(
    () => {
      const email = localStorage.getItem('userEmail')
      if (!isEmpty(email)) {
        setUserEmail(email)
      }
      const country = decrypt(localStorage.getItem('country'))
      console.log('country has changed: ', country)
      if (['sierra leone', 'liberia'].includes(country)) {
        setCountry(country)
      }
    }, [country]
  )

  useEffect(()=> {
    countProspectData()
  }, [])

  return (
    <>
      <div className="page-content prospects-page bg-gradient-primary pb-8 pt-5 pt-md-8">
        <Container fluid>
            <div className="d-flex align-items-center">
              <CountryFilter parentCountryFilter={countryFiltering} />
            </div>
        </Container>
        <Container fluid>
          <Nav
            className="nav-fill flex-column flex-sm-row"
            id="tabs-text"
            pills
            color="info"
            role="tablist">
            <NavItem>
              <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                  active: activeTab === '1'
                })}
                onClick={() => { toggle('1'); }}
              >
                Approved
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                  active: activeTab === '2'
                })}
                onClick={() => { toggle('2'); }}
              >
                To Approve
                <Badge color="success">
                  {prospectValidationCount.toApprouve}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                  active: activeTab === '3'
                })}
                onClick={() => { toggle('3'); }}
              >
                To Reject
                <Badge color="danger">
                  {prospectValidationCount.toReject}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                  active: activeTab === '4'
                })}
                onClick={() => { toggle('4'); }}
              >
                To Question
                <Badge color="primary">
                  {prospectValidationCount.toQuestion}
                </Badge>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames("mb-sm-3 mb-md-0", {
                  active: activeTab === '5'
                })}
                onClick={() => { toggle('5'); }}
              >
                To Decide
                <Badge color="warning">
                  {prospectValidationCount.toDecide}
                </Badge>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="mt-3" activeTab={activeTab}>
            <TabPane tabId="1">
              <ApprovedProspects 
                count={prospectValidationCount.approuved}
                userEmail={userEmail}
                country={country}
              />
            </TabPane>
            <TabPane tabId="2">
              <ToApprovedProspects 
                count={prospectValidationCount.toApprouve}
                userEmail={userEmail}
                country={country}
                countProspectData={countProspectData}
                />
            </TabPane>
            <TabPane tabId="3">
              <ToRejectProspects 
                count={prospectValidationCount.toReject}
                userEmail={userEmail}
                country={country}
                countProspectData={countProspectData}
              />
            </TabPane>
            <TabPane tabId="4">
              <ToQuestionProspects 
                count={prospectValidationCount.toQuestion}
                userEmail={userEmail}
                country={country}
                countProspectData={countProspectData}
              />
            </TabPane>
            <TabPane tabId="5">
              <ToDecideProspects 
                count={prospectValidationCount.toDecide}
                userEmail={userEmail}
                country={country}
                countProspectData={countProspectData}
              />
            </TabPane>
          </TabContent>

        </Container>
      </div>
    </>
  );
};

export default Prospects;
