import React, { useState } from 'react';

import _ from 'lodash';

// reactstrap components
import { Row, Container } from 'reactstrap';

import { Query } from 'react-apollo';

import Overview from '../dashboard/overview';
import Territories from '../dashboard/territories';
import DateFilter from '../layouts/date-filter';
import CountryFilter from '../layouts/country-filter';
import { decrypt } from '../../utils/utils-2';
import { GET_GLOBAL_DASHBOARD_DATA, GET_TERRITORY_DASHBOARD_DATA } from '../gql/combined';

const Dashboard = () => {

  const [latestThursday, updateLatestThursday] = useState('2020-01-22');
  const [filteringDate, updateFilteringDate] = useState('');
  const [country, setCountry] = useState(
    decrypt(localStorage.getItem('country')),
  );

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  const countryFiltering = (country) => {
    setCountry(country);
  };


  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="d-flex align-items-center">
              <DateFilter parentDateFilter={dateFiltering} />
              <CountryFilter parentCountryFilter={countryFiltering} />
            </div>
          </Container>
          <Container fluid>
            <Query
              query={GET_GLOBAL_DASHBOARD_DATA}
              variables={{country: country, date: filteringDate ? filteringDate : latestThursday}}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }

                return (
                  <Overview
                    globalData={data.globalData[0]}
                    currentThursday={
                      filteringDate ? filteringDate : latestThursday
                    }
                    country={country}
                  />
                );
              }}
            </Query>
            <Query
              query={GET_TERRITORY_DASHBOARD_DATA}
              variables={{country: country, date: filteringDate ? filteringDate : latestThursday}}
            >
              {({ loading, error, data }) => {
                if (loading) return <p className="text-white">LOADING...</p>;
                if (error) {
                  console.log(error.message);
                  return (
                    <p className="text-white">
                      `{error.message}`
                    </p>
                  );
                }
                const territoryData = _.orderBy(data.territoryData, ['territoryName'])
 
                return (
                  <Row>
                  <Territories
                  territoryData={territoryData}
                    currentThursday={
                      filteringDate ? filteringDate : latestThursday
                    }
                    country={country}
                  />
                  </Row>
                );
              }}
            </Query>

          </Container>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default Dashboard;
