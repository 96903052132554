import React from "react";
// reactstrap components
import {
  Input,
  Table,
  Media,
} from "reactstrap";
import CurrencyFormat from 'react-currency-format';

const DeductionModalBody = ({ commissions, territoryName }) => {

  const commissionsToadjust = commissions.filter(item => item.toAdjustOnAngaza > 0)
  .filter(item => item.angazaAdjustementDone === false)

  return (
    <Table className="align-items-center table-flush custom-table" hover responsive>
      { commissionsToadjust.length > 0 ?
      <>
      <thead className="thead-light">
        <tr>
          <th scope="col">Agent Name</th>
          <th scope="col">To adjust</th>
          <th scope="col">Angaza <br />Adjustment</th>
          <th scope="col">Amount <br />Deducting</th>
          <th scope="col">Date</th>
          <th scope="col">Note</th>
        </tr>
      </thead>
      <tbody>
        {commissionsToadjust.map(commission => {
          return (
            <tr key={commission.id}>
              <td>
                <Media className="text-sm font-weight-bold">
                  {`${commission.agent.firstName} ${commission.agent.lastName}`}<span className="pl-1">({commission.agent.username})</span>
                </Media>
              </td>
              <td>
                <CurrencyFormat
                  value={commission.toAdjustOnAngaza}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Le '} />
              </td>
              <td>
                <CurrencyFormat
                  value={commission.angazaAdjustment}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'Le '} />
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <h4 className="mr-2 mb-0">Le</h4>
                  <Input placeholder="Enter amount" type="number" bsSize="sm" />
                </div>
              </td>
              <td>
                {
                  new Date(commission.currentWeekThursday).toLocaleString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
              </td>
              <td>
                <Input type="textarea" placeholder="Reference note" maxLength="50" name="text" id="exampleText" bsSize="sm" />
              </td>
            </tr>
          );
        })}
      </tbody>
      </> : <h2 className="text-center">Currently, there is no agent to deduct commission for</h2>
}
    </Table>
  );
}

export default DeductionModalBody;
