import territoryTotalAgents, { totalAgents } from './helpers';

/*  Find total Target Sales Revenue for All Counties
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTotalTargetSalesRevenue = (territories, date, country = 'liberia') => {
  let anticipatedSales = 0
  let averageSalePrice = 0
  const totalAgent = totalAgents(territories, country);
  territories.forEach((territory) => {
    const districts = territory.lbTerriDistrict.districts;
    districts.forEach((district) => {
      const agents = district.node.districtLbAgent.agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          anticipatedSales = commission.node.numberOfAnticaptedSales;
          averageSalePrice = commission.node.averageSalesPrice;
        });
      });
    });
  });
  return Math.trunc(anticipatedSales * averageSalePrice * totalAgent);
};

/*  Find total Actual Sales Revenue for All Counties
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTotalSalesRevenue = (territories, date) => {
  let actualSaleRevenue = 0;
  territories.forEach((territory) => {
    const districts = territory.lbTerriDistrict.districts;
    districts.forEach((district) => {
      const agents = district.node.districtLbAgent.agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          actualSaleRevenue += commission.node.totalRevenueMonth;
        });
      });
    });
  });
  return Math.trunc(actualSaleRevenue);
};

/*  Find total PAR for All Counties
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTotalPARforAllTerritories = (territories, date) => {
  let weekPar = 0;
  territories.forEach((territory) => {
    const districts = territory.lbTerriDistrict.districts;
    districts.forEach((district) => {
      const agents = district.node.districtLbAgent.agents;
      agents.forEach((agent) => {
        const agentCommissions = agent.node.agentCommission.commissions;
        if (!agentCommissions.length) {
          return 0;
        }
        const latestThursday = date;
        const commissions = agent.node.agentCommission.commissions.filter(
          (commission) => {
            return commission.node.currentWeekThursday === latestThursday;
          },
        );
        commissions.forEach((commission) => {
          if (commission.node.agentWeekPar > 0) {
            weekPar += commission.node.agentWeekPar;
          }
        });
      });
    });
  });
  return Math.trunc(weekPar);
};

// Get average PAR for all territories
export const getAveragePAR = (territories, date, country = 'liberia') => {
  const totalPAR = getTotalPARforAllTerritories(territories, date);
  const totalAgent = totalAgents(territories, country);
  if (totalAgent < 0) {
    return 0;
  }
  const result = totalPAR / totalAgent;
  if (Number.isNaN(result)) {
    return 0;
  }
  return Math.round(result);
};

// Return a percentage from two numbers
export const percentageSales = (territories, date) => {
  const actualSalesRevenue = getTotalSalesRevenue(territories, date);
  const targetSalesRevenue = getTotalTargetSalesRevenue(territories, date);
  if (targetSalesRevenue <= 0) {
    return 0;
  }
  const result = actualSalesRevenue / targetSalesRevenue;
  if (Number.isNaN(result)) {
    return 0;
  }
  return Math.round(result * 100);
};

/*  Find total Target Sales Revenue for each county
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTerritoryTargetSalesRevenue = (districts, date, country = 'liberia') => {
  let anticipatedSales = 0;
  let averageSalePrice = 0;
  const totalAgent = territoryTotalAgents(districts, country);
  districts.forEach((district) => {
    const agents = district.node.districtLbAgent.agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        anticipatedSales = commission.node.numberOfAnticaptedSales;
        averageSalePrice = commission.node.averageSalesPrice;
      });
    });
  });
  return Math.trunc(anticipatedSales * averageSalePrice * totalAgent);
};

/*  Find total Actual Sales Revenue for each county
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTerritorySalesRevenue = (districts, date) => {
  let actualSaleRevenue = 0;
  districts.forEach((district) => {
    const agents = district.node.districtLbAgent.agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        actualSaleRevenue += commission.node.totalRevenueMonth;
      });
    });
  });
  return Math.trunc(actualSaleRevenue);
};

/*  Find total PAR for All Counties
  based on the structure of our graphql  response data
  note that we used alias in the query */
export const getTerritoryPAR = (districts, date) => {
  let weekPar = 0;
  districts.forEach((district) => {
    const agents = district.node.districtLbAgent.agents;
    agents.forEach((agent) => {
      const agentCommissions = agent.node.agentCommission.commissions;
      if (!agentCommissions.length) {
        return 0;
      }
      const latestThursday = date;
      const commissions = agent.node.agentCommission.commissions.filter(
        (commission) => {
          return commission.node.currentWeekThursday === latestThursday;
        },
      );
      commissions.forEach((commission) => {
        if (commission.node.agentWeekPar > 0) {
          weekPar += commission.node.agentWeekPar;
        }
      });
    });
  });
  return Math.trunc(weekPar);
};

// Get average PAR for all each territory
export const getTerritoryAveragePAR = (
  districts,
  date,
  country = 'liberia',
) => {
  const totalPAR = getTerritoryPAR(districts, date);
  const totalAgent = territoryTotalAgents(districts, country);
  if (totalAgent <= 0) {
    return 0;
  }
  const result = totalPAR / totalAgent;
  if (Number.isNaN(result)) {
    return 0;
  }
  return Math.round(result);
};

// Return a percentage from two numbers
export const territoryPercentageSales = (districts, date) => {
  const actualSalesRevenue = getTerritorySalesRevenue(districts, date);
  const targetSalesRevenue = getTerritoryTargetSalesRevenue(districts, date);
  if (targetSalesRevenue < 0) {
    return 100;
  }
  const result = actualSalesRevenue / targetSalesRevenue;
  if (Number.isNaN(result)) {
    return 0;
  }
  return Math.round(result * 100);
};
