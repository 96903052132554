import _ from 'lodash';
import moment from 'moment';

/* Find Shop revenues
 */
export const getShopRevenue = (sales, shop) => {
  let shopRevenue = 0;
  const shopSales = sales.filter(
    (sale) => sale.responsibleUser.username === shop.username,
  );
  if (shopSales.length > 0) {
    for (const sale of shopSales) {
      shopRevenue += sale.unlockPrice;
    }
  }
  return shopRevenue;
};

/* Get prospected customers by Brand Ambassador */
export const getBrandAmbassadorProspects = (
  prospects,
  brandAmbassador,
  pdsBA = false,
) => {
  // for PDS BAs we consider all approved prospects as converted
  if (pdsBA) {
    return prospects.filter(
      (prospect) =>
        prospect.brandAmbassador.toLowerCase() ===
        brandAmbassador.baCode.toLowerCase(),
    );
  }
  return prospects.filter(
    (prospect) =>
      prospect.brandAmbassador.toLowerCase() ===
      brandAmbassador.baCode.toLowerCase(),
  );
};

/* Get converted leads by Brand Ambassador */
export const getBrandAmbassadorConverted = (
  prospects,
  brandAmbassador,
  sales,
  pdsBA,
) => {
  // for PDS BAs we consider all approved prospects as converted
  if (pdsBA) {
    return prospects
      .filter(
        (prospect) =>
          prospect.brandAmbassador.toLowerCase() ===
          brandAmbassador.baCode.toLowerCase(),
      )
      .filter((prospect) => prospect.status.toLowerCase() === 'approved');
  }
  const converted = prospects
    .filter(
      (prospect) =>
        prospect.brandAmbassador.toLowerCase() ===
        brandAmbassador.baCode.toLowerCase(),
    )
    .filter((prospect) => prospect.registeredAccountNumber !== '0');

  const directSales = sales.filter(
    (sale) =>
      sale.brandAmbassador.toLowerCase() ===
      brandAmbassador.baCode.toLowerCase(),
  );

  if (!directSales.length) {
    return converted;
  }
  // map sales objects to match prospects one
  const baDirectSales = directSales.map((sale) => {
    return {
      registeredAccountNumber: sale.accountNumber,
      brandAmbassador: sale.brandAmbassador,
      unlockPrice: sale.unlockPrice,
      pricingGroup: sale.pricingGroup.groupName,
    };
  });

  // combine the two array and remove duplicate
  const mergeConverted = converted.concat(baDirectSales);
  // Remove duplicates
  return _.uniqBy(mergeConverted, 'registeredAccountNumber');
};

/* round up to chosen decimal*/
export const roundUp = (n, decimals = 0) => {
  const multiplier = 10 ** decimals;
  return Math.ceil(n * multiplier) / multiplier;
};

/* Get brand Ambassaor total commission based on product sold*/
export const calculateBrandAmbassadorCommission = (
  convertedLeads,
  cashCommission,
  creditCommission,
  decimal = 1000,
) => {
  let bACommission = 0;
  for (const prospect of convertedLeads) {
    const pricingGroup = prospect.pricingGroup;
    if (pricingGroup.toLowerCase().includes('cash')) {
      const commission = roundUp(
        (prospect.unlockPrice / decimal) * (cashCommission / 100),
      );
      bACommission += parseInt(commission) * decimal;
    } else {
      const commission = roundUp(
        (prospect.unlockPrice / decimal) * (creditCommission / 100),
      );
      bACommission += parseInt(commission) * decimal;
    }
  }
  return bACommission;
};

/* Get Brand Ambassador service duration commission */
export const getBrandAmbassadorServiceCommission = (
  today,
  startDate,
  decimal = 1000,
) => {
  // if BA's service is greater than 6 month
  if (today.diff(startDate, 'months') > 6) {
    return 50 * decimal;
  }
  return 0;
};

/* Get Brand Ambassadors Converted leads based commissions */
export const getBrandAmbassadorConvertedCommission = (
  convertedLeads,
  decimal = 1000,
) => {
  // if convertedLeads = 10 ==> 50 000, 20 => 100 000, 30 => 200 000, +10 => +100 000

  // we round converted lead down to the nearest multiple of 10
  const roundedConvertedLeads = Math.floor(convertedLeads / 10) * 10;
  if (roundedConvertedLeads < 10) {
    return 0;
  }
  if (roundedConvertedLeads === 10) {
    return 50 * decimal;
  }
  if (roundedConvertedLeads === 20) {
    return 100 * decimal;
  }
  return roundedConvertedLeads * 10 * decimal - 100 * decimal;
};

/* Get fixed Compenstation */
export const getBrandAmbassadorFixedCompensation = (
  currentMonthConverted,
  fixedCompensation,
) => {
  if (currentMonthConverted >= 10) {
    return fixedCompensation;
  }
  return 0;
};

/* Get  Brand Ambassador commissions for a shop*/
export const getBrandAmbassadorCommission = (
  prospects,
  brandAmbassador,
  settingCommissions,
  currentEndOfMonthDate,
  convertedLeads,
  directSales = [],
  pdsBA = false,
) => {
  const startDate = moment(brandAmbassador.startDate);
  const today = moment(currentEndOfMonthDate);
  const bACreditCommission = settingCommissions.filter((commission) =>
    commission.nameOfCommission
      .toLowerCase()
      .includes('brand ambassador credit commission'),
  )[0];
  const bACashCommission = settingCommissions.filter((commission) =>
    commission.nameOfCommission
      .toLowerCase()
      .includes('brand ambassador cash commission'),
  )[0];
  const bAFixedCompensation = settingCommissions.filter((commission) =>
    commission.nameOfCommission
      .toLowerCase()
      .includes('brand ambassador fixed'),
  )[0];
  const creditCommission = bACreditCommission.commissionPercentage;
  const cashCommission = bACashCommission.commissionPercentage;
  const fixedCompensation = bAFixedCompensation.fixedCompensation;
  const brandAmbassadorConverted = pdsBA
    ? getBrandAmbassadorConverted(prospects, brandAmbassador, directSales, true)
    : getBrandAmbassadorConverted(
        prospects,
        brandAmbassador,
        directSales,
        false,
      );

  let totalBaCommission = 0;
  const salesBasedCom = calculateBrandAmbassadorCommission(
    brandAmbassadorConverted,
    cashCommission,
    creditCommission,
  );
  const serviceDurationBasedCom = getBrandAmbassadorServiceCommission(
    today,
    startDate,
  );
  const convertedLeadsBasedCom = getBrandAmbassadorConvertedCommission(
    convertedLeads,
  );
  const baFixedCompensation = getBrandAmbassadorFixedCompensation(
    convertedLeads,
    fixedCompensation,
  );

  totalBaCommission =
    salesBasedCom +
    serviceDurationBasedCom +
    convertedLeadsBasedCom +
    baFixedCompensation;
  return totalBaCommission;
};

//
export const constructMonthlyBACommissions = (
  prospects,
  brandAmbassadors,
  commissionSettings,
  endDate,
  directSales,
  pdsBA,
) => {
  let baCommissionReportData = [];
  if (!brandAmbassadors) {
    return;
  }
  const activeBAs = brandAmbassadors.filter(
    (ambassador) => ambassador.isActive === true,
  );
  activeBAs.forEach((ambassador) => {
    const shopName = ambassador.shop.firstName;
    const fullName = ambassador.fullName;
    const baCode = ambassador.baCode;
    const phoneNumber = ambassador.primaryPhoneNumber;
    const bankName = ambassador.bankName;
    const bankAccount = ambassador.bankAccount;
    const BBANNumber = ambassador.BBANNumber;
    const prospected = pdsBA
      ? getBrandAmbassadorProspects(prospects, ambassador, true).length
      : getBrandAmbassadorProspects(prospects, ambassador).length;
    const convertedList = pdsBA
      ? getBrandAmbassadorConverted(prospects, ambassador, directSales, true)
      : getBrandAmbassadorConverted(prospects, ambassador, directSales, false);
    const converted = convertedList.length;

    // Total of unlock prices of converted prospects (actual sales)
    const baRevenue = convertedList.reduce(
      (sum, converted) => sum + converted.unlockPrice, 0
    )

    const baCommission = pdsBA
      ? getBrandAmbassadorCommission(
          prospects,
          ambassador,
          commissionSettings,
          endDate,
          converted,
          directSales,
          true,
        )
      : getBrandAmbassadorCommission(
          prospects,
          ambassador,
          commissionSettings,
          endDate,
          converted,
          directSales,
          false,
        );

    const baData = {
      shopName,
      fullName,
      baCode,
      phoneNumber,
      bankName,
      bankAccount,
      BBANNumber,
      baRevenue,
      prospected,
      converted,
      baCommission,
    };
    baCommissionReportData.push(baData);
  });
  return _.orderBy(
    baCommissionReportData,
    ['shopName', 'fullName'],
    ['asc', 'asc'],
  );
};

/* Get Brand Ambassadors by Shops*/
export const getBrandAmbassadorsByShop = (baList) => {
  if (!baList) {
    return [];
  }
  let result = [];
  for (let ambassador of baList) {
    const baIndex = result.findIndex(
      (item) => item.shopName === ambassador.shopName,
    );
    if (baIndex > -1) {
      result[baIndex].baList.push(ambassador);
    } else {
      result.push({ shopName: ambassador.shopName, baList: [ambassador] });
    }
  }
  return result;
};

// Get Shops by Territory
export const getShopsByTerritory = (shopList) => {
  if (!shopList) {
    return [];
  }
  let result = [];
  for (let shop of shopList) {
    const shopIndex = result.findIndex(
      (item) => item.territoryName === shop.territory,
    );
    if (shopIndex > -1) {
      result[shopIndex].shopList.push(shop);
    } else {
      result.push({
        territoryName: shop.territory,
        territoryAbbr: shop.territoryAbbreviation,
        shopList: [shop],
      });
    }
  }
  return result;
};

// Get shop monthly remits
export const getShopRemits = (remits, shop) => {
  let shopTotalRemit = 0;
  
  const shopRemits = remits.filter((remit) => {
    const shop_full_name = `${shop.firstName} ${shop.lastName} (${shop.username})`
    
    return remit.user === shop_full_name
  }
  );
  if (shopRemits.length > 0) {
    for (const remit of shopRemits) {
      shopTotalRemit += remit.amount;
    }
  }
  return shopTotalRemit;
};

// get shopStaff bonuses
export const getShopStaffBonuses = (
  totalRevenue,
  totalRemits,
  decimal = 1000,
) => {
  const BELOW40COMMISSION = 0.5;
  const BELOW100COMMISSION = 1;
  const ABOVE100COMMISSION = 1.5;
  let staffBonuses = 0;
  //  total revenue < 40 000 000 => 0.5% of totalRemits
  if (totalRevenue < 40000 * decimal) {
    const bonus = roundUp((totalRemits / decimal) * (BELOW40COMMISSION / 100));
    staffBonuses = parseInt(bonus) * decimal;
  }
  //  total revenue >= 40 000 000 and < 100 000 000 => 1% of totalRemits
  if (totalRevenue >= 40000 * decimal && totalRevenue < 100000 * decimal) {
    const bonus = roundUp((totalRemits / decimal) * (BELOW100COMMISSION / 100));
    staffBonuses = parseInt(bonus) * decimal;
  }
  //  total revenue >= 100 000 000 => 1.5% of totalRemits
  if (totalRevenue >= 100000 * decimal) {
    const bonus = roundUp((totalRemits / decimal) * (ABOVE100COMMISSION / 100));
    staffBonuses = parseInt(bonus) * decimal;
  }
  return staffBonuses;
};

// Construct monthly shop staff bonuses
export const constructMonthlyShopStaffBonuses = (
  shopBonuses
) => {
  let shopStaffBonus = [];
  if (!shopBonuses) {
    return [];
  }
  shopBonuses.forEach((bonus) => {
    const shopName = bonus.shopName;
    const shopRevenue = bonus.monthRevenue;
    const shopRemits = bonus.totalRemitted;
    const staffBonuses = bonus.shopBonus;

    const shopBonus = {
      shopName,
      shopRevenue,
      revenueWithKycIssue: bonus.revenueWithKycIssue,
      shopRemits,
      bonusWithoutKycIssue: bonus.monthBonus,
      withheldBonus: bonus.bonusToWithhold,
      releasedBonus: bonus.bonusToRelease,
      staffBonuses,
      accountsWithKycIssues: bonus.accountsWithKycIssues,
    };
    shopStaffBonus.push(shopBonus);
  });
  return _.orderBy(shopStaffBonus, ['shopName'], ['asc']);
};

/*eslint-disable */
// get points based on pricing groups
export const getEntityPoints = (sales, pricingGroups) => {
  let points = 0;
  if (!sales.length) {
    return 0;
  }
  for (let sale of sales) {
    const pricingGroupName = sale.groupName;
    let point = 0;
    const pricingGroup = pricingGroups.filter(
      (group) => group.shortName === pricingGroupName,
    );
    if (pricingGroup.length) {
      points += pricingGroup.length ? pricingGroup[0].points : 0;
    }
  }
  return points;
};

// Construct construct Agent leatherboard
export const constructAgentsLeaderboard = (
  entity,
  pricingGroups,
  sales,
  shops = false,
) => {
  let leaderboard = [];
  entity.forEach((agent) => {
    const agentSales = sales.filter(
      (sale) => sale.responsibleUser.username === agent.username,
    );

    const territory = agent.territory ? agent.territory.name : '';

    const sumWeeklyPoints = agentSales
      ? getEntityPoints(agentSales, pricingGroups)
      : 0;
    const territoryName = shops ? territory : agent.district.territory.name;
    const agentFullName = `${agent.firstName} ${agent.lastName} (${agent.username})`;

    const reportData = { territoryName, agentFullName, sumWeeklyPoints };
    leaderboard.push(reportData);
  });
  return _.orderBy(leaderboard, ['sumWeeklyPoints'], ['desc']);
};

export const isEmpty = (value) =>
  value === undefined ||
  value === 'undefined' ||
  value === null ||
  value === 'null' ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const generateKey = () => Math.random() * 100 +  Math.random() * 10 + Math.random() * Math.random()

//
// sort the graphql result data based on any key given in parameter
//
export const sortByValueName = (data, key, method = 'asc') => {

  // console.log('data: ', data)

  const compareObjects = (object1, object2, key) => {
    if (object1?.node !== undefined && object2?.node !== undefined) {

      let obj1 = object1?.node[key] ? object1?.node[key] : object1?.node?.prospect[key] ? object1.node?.prospect[key]: ''
      let obj2 = object2?.node[key] ? object2?.node[key] : object2?.node?.prospect[key] ? object2.node?.prospect[key]: ''
    

      // let obj1 = object1.node
      // let obj2 = object2.node
      // console.log('value 1: ', obj1)
      // console.log('value 2: ', obj2)
      // console.log('key: ', key)
      // return 0

    if (method === 'desc') {
      if (obj1 > obj2) return -1;
      if (obj1 < obj2) return 1;
      return 0;
    } else {
      if (obj1 < obj2) return -1;
      if (obj1 > obj2) return 1;
      return 0;
    }
  };
}

  data.sort((object1, object2) => {
    return compareObjects(object1, object2, key);
  });
  return data;
};