import React, { useState } from 'react';

// reactstrap components
import { Row, Container, Card, CardBody, Col } from 'reactstrap';
import DateFilter from '../layouts/date-filter';
import CountryFilter from '../layouts/country-filter';
import { decrypt } from '../../utils/utils-2';
import CurrencyFormat from 'react-currency-format';
import { Query, useQuery } from 'react-apollo';

import ShopList from './shop-list';
import { GET_GLOBAL_SHOP_DATA, GET_SHOP_DATA } from '../gql/combined';
import { countryVariables } from '../../utils/country-variables';

const Shops = () => {
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');
  const [country, setCountry] = useState(
    decrypt(localStorage.getItem('country')),
  );
  const [totalRevenue, updateTotalRevenue] = useState(0);
  const [totalShops, settotalShops] = useState(0);

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  const countryFiltering = (country) => {
    setCountry(country);
  };

  const { error: dataError } = useQuery(GET_GLOBAL_SHOP_DATA, {
    variables: {
      date: filteringDate ? filteringDate : latestThursday,
      country: country,
    },
    onCompleted: (data) => {
      if (data.globalShopData.length > 0) {
        const globalData = data.globalShopData[0];
        settotalShops(globalData.totalShopCount);
        updateTotalRevenue(globalData.totalRevenue);
      } else {
        settotalShops(0);
        updateTotalRevenue(0);
      }
    },
  });
  if (dataError) {
    console.log('We are issues fecthing glabal shop data');
  }

  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row className="justify-content-between">
              <div className="d-flex align-items-center">
                <DateFilter parentDateFilter={dateFiltering} />
                <CountryFilter parentCountryFilter={countryFiltering} />
              </div>
              <Col md="12" lg="6" xl="5">
                <Card className="mb-4">
                  <CardBody>
                    <h3 className="font-weight-bold mb-0 position-absolute pl-2">
                      Total
                    </h3>
                    <div className="text-right">
                      <h1 className="mb-0">
                        <span className="fas fa-store-alt mr-2"></span>
                        {totalShops}
                      </h1>
                      <h1 className="mb-0">
                        <span className="fas fa-money-bill-wave mr-2"></span>
                        <CurrencyFormat
                          value={totalRevenue}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={countryVariables[country]['currencySymbol']}
                        />
                      </h1>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {latestThursday ? (
              <Query
                query={GET_SHOP_DATA}
                variables={{
                  date: filteringDate ? filteringDate : latestThursday,
                  country: country,
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <p className="text-white">LOADING...</p>;
                  if (error) {
                    console.log(error.message);
                    return (
                      <p className="text-white">
                        Error. Try refreshing the page
                      </p>
                    );
                  }
                  const shopData = data.allShopData;

                  return (
                    <ShopList
                      shopData={shopData}
                      currentThursday={ filteringDate ? filteringDate : latestThursday}
                      country={country}
                    />
                  );
                }}
              </Query>
            ) : (
              ''
            )}
          </Container>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default Shops;
