import React from 'react';
// reactstrap components
import { Input, Table, Media } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { countryVariables } from '../../utils/country-variables';

const MonthlyCommissionModalBody = ({
  commissions,
  handleChange,
  country = 'liberia',
}) => {
  const commissionsToPay = commissions.filter((commission) => {
    return commission.amountSent < commission.commissionOwedForTheWeek;
  });

  const currencySymbol = countryVariables[country]['currencySymbol'];

  return (
    <Table
      className="align-items-center table-flush custom-table"
      hover
      responsive
    >
      {commissionsToPay.length > 0 ? (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">Agent Name</th>
              <th scope="col">
                Monthly <br /> Com To Pay
              </th>
              {/* <th scope="col">Loan <br /> Type</th> */}
              <th scope="col">
                Amount <br /> To Pay
              </th>
              <th scope="col">
                Select
                <br />
                Date{' '}
              </th>
              <th scope="col">Note</th>
            </tr>
          </thead>
          <tbody>
            {commissionsToPay.map((commission, index) => {
              return (
                <tr key={commission.id}>
                  <td>
                    <Media className="text-sm font-weight-bold">
                      {commission.agentFullName}
                      <span className="pl-1">({commission.agentUsername})</span>
                    </Media>
                  </td>
                  <td>
                    <CurrencyFormat
                      value={commission.commissionOwedForTheWeek}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={currencySymbol}
                    />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <h4 className="mr-2 mb-0">{currencySymbol}</h4>
                      <Input
                        className=""
                        name="amount"
                        placeholder="Enter amount"
                        type="number"
                        bsSize="sm"
                        data-id={commission.id}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  </td>
                  <td>
                    {/* Date Selecting <br /> Here */}
                    {new Date(commission.date).toLocaleString('en-us', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </td>
                  <td>
                    <Input
                      type="textarea"
                      placeholder="Reason"
                      maxLength="50"
                      name="note"
                      id="exampleText"
                      bsSize="sm"
                      data-id={commission.id}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      ) : (
        <h2 className="text-center">
          Currently, there is no agent that did not receive his/her weekly
          commissions
        </h2>
      )}
    </Table>
  );
};

export default MonthlyCommissionModalBody;
