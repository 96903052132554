import React from 'react'

// reactstrap components
import {
  Row,
  Card,
  Col,
  Table
} from 'reactstrap';


const AgentLeaderBoard = ({ commissionData, shopLeaderboard=false }) => {

  

  return (
    <>
      <Row className='mb-5 mt-4'>
        <Col md='12' lg='12' xl='12'>
          <Card className='shadow'>
            <Table className='align-items-center table-flush custom-table' size='sm' hover responsive>
              <thead className='bg-primary'>
                <tr>
                  <th scope='col' className='text-white'>Territory</th>
                  <th scope='col' className='text-white'>{shopLeaderboard ? 'Shop Name': 'Agent Full Name'}</th>
                  <th scope='col' className='text-white'>Total Points</th>
                </tr>
              </thead>
              <tbody>
                {commissionData.map((commission, index) => {
                  return (
                    <tr key={index}>
                      <td className=''>{commission.territoryName}</td>
                      <td className=''>{commission.agentFullName}</td>
                      <td className=''>{commission.sumWeeklyPoints}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AgentLeaderBoard;
