import React from 'react'

import {
  Row, Card, CardBody, Col } from "reactstrap";

import CurrencyFormat from "react-currency-format";
import { countryVariables } from '../../utils/country-variables';

const ShopBanner = ({routeProps, shopRevenue}) => {

  // Getting data from route link
  const { 
    shopCoodinator, shopCoodinatorPhone, shopAssistant, shopAssistantPhone,
    shop, shopTerritory, brandAmbassadorCount, country
  } = routeProps.location.state;

  return (
    <Row className="justify-content-end">
      <Col md="12" lg="12">
        <Card className="mb-4">
          <CardBody>
            <Row className="align-items-center">
              <Col lg="3">
                <h2 className="mb-0 text-uppercase">
                  <span className="fas fa-store mr-2"></span>
                  {`${shop.shopName}`}
                </h2>
                <p className="mb-0 font-weight-bold">
                  <span className="fas fa-map-pin mr-2"></span>
                  {shopTerritory}
                </p>
              </Col>
              <Col lg="3">
                <h2 className="mb-0">
                  <span className="fas fa-user-secret mr-2"></span>
                  {shopCoodinator}
                </h2>
                <p className="mb-0 font-weight-bold">
                  <span className="fas fa-mobile-alt mr-2"></span>
                  {shopCoodinatorPhone}
                </p>
              </Col>
              <Col lg="3">
                <h2 className="mb-0">
                  <span className="fas fa-user-tie mr-2"></span>
                  {shopAssistant}
                </h2>
                <p className="mb-0 font-weight-bold">
                  <span className="fas fa-mobile-alt mr-2"></span>
                  {shopAssistantPhone}
                </p>
              </Col>
              <Col lg="3" className="text-right">
                <h1 className="mb-0">
                  <span className="fas fa-users mr-2"></span>
                  {brandAmbassadorCount}
                </h1>
                <h1 className="mb-0">
                  <span className="fas fa-money-bill-wave mr-2"></span>
                  <CurrencyFormat
                    className="text-nowrap"
                    value={shopRevenue}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={countryVariables[country]['currencySymbol']} />
                </h1>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ShopBanner;
