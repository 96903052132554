import moment from 'moment';

// Get the a certains number of prior months
export const getPreviousNMonths = (numberOfPriorMonth) => {
  return Array.apply(null, Array(numberOfPriorMonth)).map((month, index) => {
    return moment().subtract(index, 'months');
  });
};


// GET a lsit certain number of prior thursdays from a given thursday date
export const getPreviousNThursday = (startDate, numberOfPriorWeek) => {
  return Array.apply(null, Array(numberOfPriorWeek)).map((month, index) => {
    // return prior thursday dates
    return moment(startDate)
      .subtract(index, 'weeks')
      .startOf('week').add(4, 'days').format('YYYY-MM-DD');
  });
}
