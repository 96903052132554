import React, { useState, useRef } from 'react';
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';

import AwaitingRewards from './rewards/awaiting-rewards';
import RewardedAgents from './rewards/rewarded-agents';
import { graphQlQueries } from '../gql/main-gql';
import { countryVariables } from '../../utils/country-variables';
import { decrypt } from '../../utils/utils-2';

const TerritoryRewards = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const rewardModalRef = useRef();

  // // Refetch the rewarded query everytime the component rerender
  // useEffect(() => {
  //   refetch()
  // })

  const country = decrypt(localStorage.getItem('country'));
  const rewardLabel = countryVariables[country].rewardLabel;

  const refresh = () => {
    refetch();
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Capturing the territoriy abbreviation passed as parameters
  const {
    match: { params },
  } = props;
  const { territoryName } = props.location.state;

  // Load rewards
  const { data, loading, error, refetch } = useQuery(
    graphQlQueries[country]['awaited-reward'],
    {
      variables: { territoryName: territoryName },
      fetchPolicy: 'no-cache',
    },
  );
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row className="align-items-center">
              <Col md="12" lg="12" xl="12">
                <div className="card-stats overview-stats bg-transparent mb-4">
                  <Row className="align-items-center">
                    <Col md={{ size: 6 }}>
                      <Card className="card-stats">
                        <CardBody>
                          <Row className="justify-content-between">
                            <Col md="6">
                              <CardTitle
                                tag="h5"
                                className="mb-0 territory-name"
                              >
                                <p className="territory-name-short">
                                  {params.abbr}
                                </p>
                                <p className="territory-name-full text-left">
                                  {territoryName}
                                </p>
                              </CardTitle>
                            </Col>
                            <Col md="6" className="text-right">
                              <p className="mb-0 display-3 text-primary">
                                <i className="fas fa-gifts pl-2 pr-2" />
                                <strong>{data[rewardLabel].length}</strong>
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row className="mb-5">
              <Col md="12" lg="12" xl="12">
                <Card className="shadow mb-2">
                  <CardHeader className="border-0">
                    <Nav pills>
                      <NavItem key="nav-1">
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          onClick={() => {
                            toggle('1');
                          }}
                          href="#"
                          role="tab"
                        >
                          Agents Awaiting Reward
                        </NavLink>
                      </NavItem>
                      <NavItem key="nav-2">
                        <NavLink
                          className={classnames({ active: activeTab === '2' })}
                          onClick={() => {
                            toggle('2');
                          }}
                          href="#"
                          role="tab"
                        >
                          Rewarded Agents
                        </NavLink>
                      </NavItem>
                      {/* <NavItem key="nav-3">
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggle('3'); }}
                        href="#"
                        role="tab"
                      >
                        Overall Points
                      </NavLink>
                    </NavItem> */}
                      <Button
                        color="primary"
                        className="btn-warning ml-auto"
                        onClick={() => rewardModalRef.current.toggleModal()}
                      >
                        Pay Reward
                      </Button>
                    </Nav>
                  </CardHeader>
                </Card>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" key="tab-1">
                    <AwaitingRewards
                      data={data[rewardLabel]}
                      ref={rewardModalRef}
                      refresh={refresh}
                      territoryName={territoryName}
                    />
                  </TabPane>
                  <TabPane tabId="2" key="tab-2">
                    <RewardedAgents territoryName={territoryName} />
                  </TabPane>
                  {/* <TabPane tabId="3" key="tab-3">
                  <OverallPoints/>
                </TabPane> */}
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default TerritoryRewards;
