import React from 'react';

// reactstrap components
import { Row, Card, Col, Table, Media } from 'reactstrap';

import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';

import {
  constructMonthlySupervisorBonuses,
  getTotalMontlyBonuses,
} from '../../utils/helpers';

const SupervisorMonthlyTable = ({ commissions, territories, supervisors }) => {
  const reportCommissions = commissions.map((commission) => {
    return commission.node;
  });

  const supervisorBonuses = constructMonthlySupervisorBonuses(
    territories,
    reportCommissions,
    supervisors,
  );
  const totalBonuses = getTotalMontlyBonuses(supervisorBonuses);

  return (
    <>
      <Row className="mb-5 mt-4">
        <Col md="12" lg="12" xl="12">
          <Card className="shadow">
            {supervisorBonuses.map((supervisorData, index) => {
              return (
                <Table
                  className="align-items-center table-flush custom-table"
                  size="sm"
                  hover
                  responsive
                  key={index}
                >
                  <thead>
                    <tr className="bg-primary">
                      <td>
                        <h4 className="mb-0 text-white">
                          {supervisorData.territoryName}{' '}
                          <strong>({supervisorData.territoryAbbr})</strong>
                        </h4>
                      </td>
                      <td />
                      <td />
                      <td />
                    </tr>
                  </thead>
                  <thead className="">
                    <tr>
                      <th scope="col" className="">
                        Supervisor Name
                      </th>
                      <th
                        scope="col"
                        className="text-center"
                        style={{ width: 50 }}
                      >
                        <i className="fas fa-users" />
                      </th>
                      <th scope="col" style={{ width: 202 }}>
                        Total <br />
                        Commission
                      </th>
                      <th scope="col" style={{ width: 182 }}>
                        Bonus <br /> <strong>(10%)</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {supervisorData.supervisors.map((supervisor, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="">
                            <Link
                              to={
                                '/admin/supervisor-details/' +
                                supervisor.username
                              }
                            >
                              <Media className="align-items-center">
                                <span className="mb-0 text-sm">
                                  {supervisor.supervisorName}
                                  <strong className="pl-1">
                                    ({supervisor.username})
                                  </strong>
                                </span>
                              </Media>
                            </Link>
                          </th>
                          <td className="text-center">
                            {supervisor.numAgents}
                          </td>
                          <td>
                            <CurrencyFormat
                              value={supervisor.totalAgentOwed}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </td>
                          <td>
                            <strong>
                              <CurrencyFormat
                                value={supervisor.bonus}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </strong>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              );
            })}
            <Table className="table-flush custom-table" hover responsive>
              {/*Total Table*/}
              <thead className="thead-light">
                <tr>
                  <th scope="col" />
                  <th scope="col" className="text-center" style={{ width: 50 }}>
                    <i className="fas fa-users" />
                  </th>
                  <th scope="col" style={{ width: 202 }}>
                    Total <br />
                    Commission
                  </th>
                  <th scope="col" style={{ width: 182 }}>
                    Bonus <br /> <strong>(10%)</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="mb-0">Totals</h4>
                  </td>
                  <td>
                    <h3 className="font-weight-bold mb-0">
                      {totalBonuses.totalAgent}
                    </h3>
                  </td>
                  <td>
                    <h3 className="font-weight-bold mb-0">
                      <CurrencyFormat
                        value={totalBonuses.totalCommissions}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </h3>
                  </td>
                  <td>
                    <h3 className="font-weight-bold mb-0">
                      <CurrencyFormat
                        value={totalBonuses.totalBonuses}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Le '}
                      />
                    </h3>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SupervisorMonthlyTable;
