import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';

// reactstrap components
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';

import { countryVariables } from '../../utils/country-variables';


const TerritoryOverview = ({ territoryData=[], country }) => {
  const { expected, remaining, fulfilled } = countryVariables[
    country
  ];
  const currenySymbol = countryVariables[country]['currencySymbol'];

  return territoryData.map((territory, i) => {
    const rewardRecipients = territory.totalToReward;
    const showReward = rewardRecipients > 0 ? 'visible' : 'hidden';
    return (
      <Col key={i} md="12" lg="4" xl="4">
        <Card
          className="card-stats mb-4"
          tag={Link}
          to={`/admin/single-territory/${territory.territoryAbbreviation}`}
        >
          <CardBody>
            <Row className="justify-content-between">
              <Col md="6">
                <CardTitle tag="h5" className="mb-0 territory-name">
                  <p className="territory-name-short">
                    {territory.territoryAbbreviation}
                  </p>
                  <p className="territory-name-full text-left">
                    {territory.territoryRegionName}
                  </p>
                </CardTitle>
              </Col>
              <Col md="6" className="text-right">
                <span className="text-success stats-percentage">
                  { isNaN(territory.percentage * 100) ? 0 : Math.round(territory.percentage * 100)}
                  %
                </span>{' '}
                <br />
                <span
                  className="text-primary"
                  style={{ visibility: showReward }}
                >
                  <i className="fas fa-gift"></i>{' '}
                  <span>{rewardRecipients}</span>
                </span>
              </Col>
            </Row>
            <Row className="align-items-end justify-content-between mt-4">
              <Col md="3">
                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                  <i className="fas fa-users" />
                </div>
                <div className="agent-amount pl-2">
                  {territory.totalAgentCount}
                </div>
              </Col>
              <Col md="9" className="pb-1">
                <Row className="justify-content-between text-dark">
                  <Col>
                    <small>{expected}</small>
                  </Col>
                  <Col className="text-right">
                    <small className="text-nowrap">
                      <CurrencyFormat
                        value={territory.expected}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currenySymbol }
                      />
                    </small>
                  </Col>
                </Row>
                <Row className="justify-content-between text-success">
                  <Col>
                    <small>{fulfilled}</small>
                  </Col>
                  <Col className="text-right">
                    <small className="text-nowrap">
                      <CurrencyFormat
                        value={territory.fulfilled}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currenySymbol}
                      />
                    </small>
                  </Col>
                </Row>
                <Row className="justify-content-between text-danger">
                  <Col>
                    <small>{remaining}</small>
                  </Col>
                  <Col className="text-right">
                    <small className="text-nowrap">
                      <CurrencyFormat
                        value={territory.remaining}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={country === 'sierra leone' ? currenySymbol : '' }
                      />
                    </small>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  });
};

export default TerritoryOverview;
