import gql from 'graphql-tag'

// 
// Sierra Leone
// 
export const PROSPECTIVE_VALIDATION_COUNT = gql`
  query prospectValidationCount  {
    prospectValidationCount {
      approuved
      toApprouve
      toReject
      toQuestion
      toDecide
      rejected
    }
  }
`

export const GET_ALL_PROSPECTIVE_VALIDATION = gql`
  query allProspectiveValidations (
    $first: Int
    $before: String
    $after: String
    $validationStatus: String
    $recommendedStatus: String
    $angazaStatus: String
    $listOfValidationStatusToExclude: [String]
    $search: String
    $territory: [String]
    ) {
      allProspectiveValidations (
      first: $first
      before: $before
      after: $after
      validationStatus: $validationStatus
      recommendedStatus: $recommendedStatus
      angazaStatus: $angazaStatus
      listOfValidationStatusToExclude: $listOfValidationStatusToExclude
      search: $search
      territory: $territory
      ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          angazaId
          prospectName
          responsibleUser
          pricingGroup
          phone
          createdDate
          points
          houseQuality
          workingVehicle
          moreThan1HouseFloors
          remittancesFromIntl
          ownsGenerator
          typeOfPhone
          freezerTv
          houseIsFinished
          additionalIncome
        }
      }
    }
  }
`

export const UPDATE_PROSPECT_VALIDATION = gql`
  mutation updateProspectValidation(
    $id: ID!,
    $validationStatus: String,
    $userEmail: String,
    $additionalIncome: String,
    $freezerTv: String,
    $houseIsFinished: String,
    $houseQuality: String,
    $moreThan1HouseFloors: String,
    $ownsGenerator: String,
    $remittancesFromIntl: String,
    $typeOfPhone: String,
    $workingVehicle: String,

    ) {
      updateProspectValidation (
        id: $id,
        validationStatus: $validationStatus,
        userEmail: $userEmail,
        additionalIncome: $additionalIncome,
        freezerTv: $freezerTv,
        houseIsFinished: $houseIsFinished,
        houseQuality: $houseQuality,
        moreThan1HouseFloors: $moreThan1HouseFloors,
        ownsGenerator: $ownsGenerator,
        remittancesFromIntl: $remittancesFromIntl,
        typeOfPhone: $typeOfPhone,
        workingVehicle: $workingVehicle
        ) {
        prospectValidation {
          id
          validationStatus
      }
    }
  }
`

// 
// Liberia
// 
export const LB_PROSPECTIVE_VALIDATION_COUNT = gql`
  query lbProspectValidationCount  {
    lbProspectValidationCount {
      approuved
      toApprouve
      toReject
      toQuestion
      toDecide
      rejected
    }
  }
`

export const LB_GET_ALL_PROSPECTIVE_VALIDATION = gql`
  query allLbProspectiveValidations (
    $first: Int
    $before: String
    $after: String
    $validationStatus: String
    $recommendedStatus: String
    $angazaStatus: String
    $listOfValidationStatusToExclude: [String]
    $search: String
    $territory: [String]
    ) {
      allLbProspectiveValidations (
      first: $first
      before: $before
      after: $after
      validationStatus: $validationStatus
      recommendedStatus: $recommendedStatus
      angazaStatus: $angazaStatus
      listOfValidationStatusToExclude: $listOfValidationStatusToExclude
      search: $search
      territory: $territory
      ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          angazaId
          prospectName
          responsibleUser
          pricingGroup
          phone
          createdDate
          points
          houseQuality
          workingVehicle
          moreThan1HouseFloors
          remittancesFromIntl
          ownsGenerator
          typeOfPhone
          freezerTv
          houseIsFinished
          additionalIncome
        }
      }
    }
  }
`

export const LB_UPDATE_PROSPECT_VALIDATION = gql`
  mutation updateLbProspectValidation(
    $id: ID!,
    $validationStatus: String,
    $userEmail: String,
    $additionalIncome: String,
    $freezerTv: String,
    $houseIsFinished: String,
    $houseQuality: String,
    $moreThan1HouseFloors: String,
    $ownsGenerator: String,
    $remittancesFromIntl: String,
    $typeOfPhone: String,
    $workingVehicle: String,
    ) {
      updateLbProspectValidation (
        id: $id,
        validationStatus: $validationStatus,
        userEmail: $userEmail,
        additionalIncome: $additionalIncome,
        freezerTv: $freezerTv,
        houseIsFinished: $houseIsFinished,
        houseQuality: $houseQuality,
        moreThan1HouseFloors: $moreThan1HouseFloors,
        ownsGenerator: $ownsGenerator,
        remittancesFromIntl: $remittancesFromIntl,
        typeOfPhone: $typeOfPhone,
        workingVehicle: $workingVehicle
        ) {
        prospectValidation {
          id
          validationStatus
      }
    }
  }
`