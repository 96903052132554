import React from 'react';
import { Link } from 'react-router-dom';

import CurrencyFormat from 'react-currency-format';
// reactstrap components
import {
  Card,
  Table,
  Container,
  CardHeader,
  Media,
  Progress,
} from 'reactstrap';

import { countryVariables } from '../../utils/country-variables';
import {
  supervisorTotalRemitted,
  supervisorExpectedRemittance,
  getSupervisorActualRevenue,
  getSupervisorTargetRevenue
} from '../../utils/supervisor-utils';

import { simplePercentage, formatPhoneNumber } from '../../utils/helpers';

// update Sierra Leone data
countryVariables['sierra leone'].supervisorPage.remittance.func = supervisorExpectedRemittance;
countryVariables['sierra leone'].supervisorPage.remitted.func = supervisorTotalRemitted;

// Update Liberia data
countryVariables['liberia'].supervisorPage.remittance.func = getSupervisorTargetRevenue;
countryVariables['liberia'].supervisorPage.remitted.func = getSupervisorActualRevenue;


const TerritorySupervisors = ({
  supervisors,
  currentThursday,
  territoryName,
  country,
}) => {
  const supervisorAgentLabel =
    countryVariables[country]['supervisorAgentLabel'];
  
  const singleSupervisorVariable = countryVariables[country].supervisorPage;
  const currenySymbol = countryVariables[country]['currencySymbol'];

  return (
    <>
      <Container className="" fluid>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex align-items-center justify-content-between">
            <h3 className="mb-0"> {territoryName} Supervisors</h3>
          </CardHeader>
          <Table
            className="align-items-center table-flush custom-table"
            hover
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Supervisor Name</th>
                <th scope="col">
                  Phone <br />
                  Number
                </th>
                <th scope="col">
                  <i className="fas fa-users" />
                </th>
                <th scope="col">
                {singleSupervisorVariable.remittance.label[0]} <br />
                {singleSupervisorVariable.remittance.label[1]}
                </th>
                <th scope="col">
                {singleSupervisorVariable.toRemit.label[0]} <br />
                {singleSupervisorVariable.toRemit.label[1]}
                </th>
                <th scope="col">
                {singleSupervisorVariable.remitted.label[0]} <br />
                {singleSupervisorVariable.remitted.label[1]}
                </th>
                <th scope="col">{singleSupervisorVariable.status.label}</th>
              </tr>
            </thead>
            <tbody>
              {supervisors
                .map((sup) => sup.node)
                .filter(sup => sup.isActive == true)
                .map((supervisor) => {
                  return (
                    <tr key={supervisor.username}>
                      <th scope="row">
                        <Link
                          to={
                            '/admin/supervisor-details/' + supervisor.username
                          }
                        >
                          <Media className="align-items-center">
                            <div
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                src={
                                  supervisor.userPhoto === '0'
                                    ? require('../../assets/img/profile/default-avatar.png')
                                    : supervisor.userPhoto
                                }
                              />
                            </div>
                            <Media>
                              <span className="mb-0 text-sm">
                                {supervisor.firstName} {supervisor.lastName}
                                <strong className="pl-1">
                                  ({supervisor.username})
                                </strong>
                              </span>
                            </Media>
                          </Media>
                        </Link>
                      </th>
                      <td>{formatPhoneNumber(supervisor.primaryPhone)}</td>
                      <td>{supervisor[supervisorAgentLabel].agents.length}</td>
                      <td>
                        <CurrencyFormat
                          value={singleSupervisorVariable.remittance.func(
                            supervisor[supervisorAgentLabel].agents,
                            currentThursday,
                          )}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currenySymbol}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={
                            singleSupervisorVariable.remittance.func(
                              supervisor[supervisorAgentLabel].agents,
                              currentThursday,
                            ) -
                            singleSupervisorVariable.remitted.func(
                              supervisor[supervisorAgentLabel].agents,
                              currentThursday,
                            )
                          }
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currenySymbol}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={singleSupervisorVariable.remitted.func(
                            supervisor[supervisorAgentLabel].agents,
                            currentThursday,
                          )}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={currenySymbol}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span style={{ minWidth: 34 }} className="mr-1">
                            {simplePercentage(
                              singleSupervisorVariable.remitted.func(
                                supervisor[supervisorAgentLabel].agents,
                                currentThursday,
                              ),
                              singleSupervisorVariable.remittance.func(
                                supervisor[supervisorAgentLabel].agents,
                                currentThursday,
                              ),
                            )}
                            %
                          </span>
                          <div>
                            <Progress
                              max="100"
                              value={simplePercentage(
                                singleSupervisorVariable.remitted.func(
                                  supervisor[supervisorAgentLabel].agents,
                                  currentThursday,
                                ),
                                singleSupervisorVariable.remittance.func(
                                  supervisor[supervisorAgentLabel].agents,
                                  currentThursday,
                                ),
                              )}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default TerritorySupervisors;
