import React, { useState } from 'react';

const NoDataTable = ({title}) => {

  return (
    <>
      <div className="text-center my-5 pb-4 px-5">
        <h1>
          <i className="fa fa-server"/>
        </h1>
        <h2>No {title}</h2>
        <p>No data at the moment, check back later or adjust your filter.</p>
      </div>
    </>
  );
};

export default NoDataTable;
