import crypto from 'crypto';

const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

// Encryption passphare
const word = process.env.REACT_APP_PASSPHRASE;

export const encrypt1 = (text) => {
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return JSON.stringify({
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
  });
};

export const decrypt1 = (text) => {
  let parsedText = JSON.parse(text);
  let iv = Buffer.from(parsedText.iv, 'hex');
  let encryptedText = Buffer.from(parsedText.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

// Simple encryption
const cipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('');
};

export const encrypt = cipher(word);
export const decrypt = decipher(word);
