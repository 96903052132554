import React from 'react';

import { Input } from 'reactstrap';

import { getPreviousNMonths } from '../utils/helpers-2';

const DateSelecter = ({ name, id, handleParentSelect }) => {
  const listOfPreviousMonth = getPreviousNMonths(13);

  const handleChange = (event) => {
    handleParentSelect(event);
  };

  return (
    <Input type="select" name={name} id={id} onChange={handleChange}>
      {listOfPreviousMonth.map((date, index) => {
        // checking start and end date to set options values
        let startOfMonth, endOfMonth;
        const isStart = name.startsWith('start');
        if (isStart) {
          startOfMonth = date.startOf('month').format('YYYY-MM-DD');
        } else {
          endOfMonth = date.endOf('month').format('YYYY-MM-DD');
        }
        if (index === 1) {
          return (
            <option
              key={index}
              value={isStart ? startOfMonth : endOfMonth}
              selected
            >
              {date.format('MMM YYYY')}
            </option>
          );
        }
        return (
          <option key={index} value={isStart ? startOfMonth : endOfMonth}>
            {date.format('MMM YYYY')}
          </option>
        );
      })}
    </Input>
  );
};

export default DateSelecter;
