import React, { useState } from 'react';
import '../styles/App.css';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';

import Login from './login/login';
import Admin from './admin/admin';

function App() {
  const [loggout, handleLogout] = useState(false);
  let history = useHistory();
  const logout = () => {
    localStorage.removeItem('token');
    // Remove the user email as well
    localStorage.removeItem('userEmail');
    localStorage.removeItem('isStaff');
    localStorage.removeItem('username');
    localStorage.removeItem('isSuperuser');
    localStorage.removeItem('country');
    localStorage.removeItem('role');
  };

  const userLoggout = localStorage.getItem('loggedout');

  if (!userLoggout && !loggout) {
    logout();
    history.push('/login');
    handleLogout(true);
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
