import gql from 'graphql-tag';

export const GET_GLOBAL_DASHBOARD_DATA = gql`
  query GetGlobalData($country: String!, $date: String) {
    globalData(country: $country, date: $date) {
      expected
      fulfilled
      weekThursday
      remaining
      totalToReward
      totalAgentCount
      percentage
    }
  }
`;

export const GET_TERRITORY_DASHBOARD_DATA = gql`
  query GetTerritoryData($country: String!, $date: String) {
    territoryData(country: $country, date: $date) {
      territoryName
      territoryManager
      territoryRegionName
      territoryAbbreviation
      expected
      fulfilled
      remaining
      weekThursday
      totalToReward
      totalAgentCount
      percentage
    }
  }
`;

export const AGENT_BIRTHDAYS = gql`
  query GetAgentBirthdays($country: String!, $weekThursday: String!) {
    agentsBirthdays(weekThursday: $weekThursday, country: $country) {
      fullName
      territory
      district
      phoneNumber
      birthdayDate
    }
  }
`;

export const GET_GLOBAL_SHOP_DATA = gql`
  query GetGlobalShopData($country: String!, $date: String) {
    globalShopData(date: $date, country: $country) {
      weekThursday
      totalRevenue
      totalShopCount
    }
  }
`;

export const GET_SHOP_DATA = gql`
  query GetShopData($country: String!, $date: String, $username: String) {
    allShopData(date: $date, country: $country, username: $username) {
      weekThursday
      shopRevenue
      brandAmbassadorCount
      territory
      territoryAbbreviation
      shopName
      shopUsername
      shopManagerName
      shopManagerPhoneNumber
      shopAssistantName
      shopAssistantPhoneNumber
      brandAmbassadorData
    }
  }
`;
