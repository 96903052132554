import React, { useState, useRef, useEffect } from 'react';
// reactstrap components
import { Button, Modal } from 'reactstrap';

import { useMutation } from '@apollo/react-hooks';

import DeductionModalBody from '../modals/deduction-modal';
import RewardModalBody from '../modals/add-reward-modal';
import AdjustmentModalBody from '../modals/adjustment-modal';
import WeeklyCommissionModalBody from '../modals/weekly-commission-modal';
import MonthlyCommissionModalBody from '../modals/monthly-commission-modal';
import { graphQlQueries } from '../gql/main-gql';
import { countryVariables } from '../../utils/country-variables';

const ActionModal = ({ props }) => {
  const initialArray = Array.apply(null, Array(100)).map(() => {
    return {
      commissionId: '',
      amount: '',
      note: '',
      physicalReward: '',
      rewardType: '',
    };
  });

  const createActionLabel =
    countryVariables[props.country]['createActionLabel'];
  const actionLabel = countryVariables[props.country]['actionLabel'];

  const [formFields, handleformFields] = useState(initialArray);
  const [errors, setErrors] = useState({ error: false, errorMessage: '' });
  const [emptyModal, setEmptyModal] = useState('');
  const [progress, setProgress] = useState('');
  const [success, setSuccess] = useState('');
  const stateRef = useRef();
  const [updateCommission] = useMutation(
    graphQlQueries[props.country]['update-commission'],
  );
  const [createAction] = useMutation(
    graphQlQueries[props.country]['create-action'],
  );

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  stateRef.current = success;

  // Update initial array when component render
  useEffect(() => {
    const array = Array.apply(null, Array(props.data.length)).map(() => {
      return {
        commissionId: '',
        amount: '',
        note: '',
        physicalReward: '',
        rewardType: '',
      };
    });
    handleformFields(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle state change
  const handleFormChange = (event, index) => {
    let value = formFields.slice();
    if (event.target.name === 'amount') {
      value[index].amount = event.target.value;
      value[index].commissionId = event.target.dataset.id;
      value[index].rewardType = event.target.dataset.reward;
    }
    if (event.target.name === 'note') {
      value[index].note = event.target.value;
      value[index].commissionId = event.target.dataset.id;
    }
    if (event.target.name === 'physicalReward') {
      value[index].physicalReward = event.target.value;
      value[index].commissionId = event.target.dataset.id;
      value[index].rewardType = event.target.dataset.reward;
    }

    handleformFields(value);
  };

  // handle action submission
  const handleActionSubmit = () => {
    const commissions = formFields.filter((item) => item.commissionId !== '');
    if (commissions.length !== props.selectedCommissions.length) {
      setEmptyModal('you shoud fill at least one field for each agent');
      return;
    }
    const userEmail = localStorage.getItem('userEmail');
    const actionName = props.selectedAction;
    if (!userEmail) {
      setEmptyModal('The session expired, Log In Again to Continue');
      return;
    }
    setProgress(`${actionName} In Progress`);
    // First create an action
    createAction({
      variables: { actionName: actionName, userEmail: userEmail },
    }).then(
      (response) =>
        hanlePostAction(response, commissions, userEmail, actionName),
      (error) => handleError(error),
    );
  };

  const hanlePostAction = (response, commissions, userEmail, actionName) => {
    setProgress('');
    const actionId = response.data[createActionLabel][actionLabel].id;
    commissions.forEach((commission) => {
      const id = commission.commissionId;
      const payload = {
        userEmail,
        actionName,
        actionId,
        amount: commission.amount,
        note: commission.note,
        reward: commission.physicalReward,
        rewardType: commission.rewardType,
        country: props.country,
      };
      updateCommission({
        variables: { id: id, payload: JSON.stringify(payload) },
      }).then(
        (result) => handleSuccess(result),
        (error) => handleError(error),
      );
    });
  };

  const handleSuccess = (result) => {
    console.log(result);
    setProgress('');
    setSuccess('Action added successfully');
    handleToggler();
  };

  const handleToggler = () => {
    const succeed = stateRef.current;
    if (succeed) {
      setTimeout(() => {
        setSuccess('');
        if (errors.error) {
          setErrors({
            error: false,
            errorMessage: '',
          });
        }
        setEmptyModal('');
        props.toggler();
      }, 2000);
    } else {
      if (errors.error) {
        setErrors({
          error: false,
          errorMessage: '',
        });
      }
      setEmptyModal('');
      props.toggler();
    }
    // reset form field
    const array = Array.apply(null, Array(props.data.length)).map(() => {
      return {
        commissionId: '',
        amount: '',
        note: '',
        physicalReward: '',
        rewardType: '',
      };
    });
    handleformFields(array);
  };

  const handleError = (err) => {
    setProgress('');
    err.graphQLErrors.map(({ message }) =>
      setErrors({
        error: true,
        errorMessage: message,
      }),
    );
  };

  let ModalBody;
  if (props.selectedAction === 'Deduct Commission') {
    ModalBody = (
      <DeductionModalBody
        territoryName={props.territoryName}
        commissions={props.data}
      />
    );
  } else if (props.selectedAction === 'Adjust Deposit/Loan') {
    ModalBody = (
      <AdjustmentModalBody
        commissions={props.selectedCommissions}
        handleChange={handleFormChange}
        country={props.country}
      />
    );
  } else if (props.selectedAction === 'Add Reward') {
    ModalBody = (
      <RewardModalBody
        commissions={props.selectedCommissions}
        handleChange={handleFormChange}
        country={props.country}
      />
    );
  } else if (props.selectedAction === 'Pay Weekly Commissions') {
    ModalBody = (
      <WeeklyCommissionModalBody
        commissions={props.selectedCommissions}
        handleChange={handleFormChange}
        country={props.country}
      />
    );
  } else if (props.selectedAction === 'Pay Monthly Commissions') {
    ModalBody = (
      <MonthlyCommissionModalBody
        commissions={props.selectedCommissions}
        handleChange={handleFormChange}
        country={props.country}
      />
    );
  }

  return (
    <>
      <div className="d-inline mr-3">
        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={props.showModal}
          toggle={handleToggler}
        >
          <div className="modal-header">
            <h4 className="modal-title">
              {props.selectedAction} from selected agents
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleToggler}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="text-center text-danger mb-2">
            <small>{errors.error ? errors.errorMessage : ''}</small>
          </div>
          <div className="text-center text-danger mb-2">
            <small>{emptyModal ? emptyModal : ''}</small>
          </div>
          <div className="text-center text-success mb-2">
            <small>{progress ? progress : ''}</small>
          </div>
          <div className="text-center text-success mb-2">
            <small>{success ? success : ''}</small>
          </div>
          <div className="modal-body p-0">
            {/* modal body based on the selected action */}
            {ModalBody}
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={handleToggler}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="ml-auto"
              type="button"
              onClick={handleActionSubmit}
            >
              Okay
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ActionModal;
