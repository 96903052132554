import React, { useState } from 'react';

import _ from 'lodash';

import {
  Row,
  Button,
  Card,
  CardBody,
  Col,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Table,
} from 'reactstrap';
import classnames from 'classnames';

import { formatPhoneNumber } from '../../utils/helpers';

const BrandAmbassadorList = ({ ambassadorsList }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const activeAmbassadors = ambassadorsList.filter(
    (ambassador) => ambassador.active === true,
  );
  const inactiveAmbassadors = ambassadorsList.filter(
    (ambassador) => ambassador.active === false,
  );
  return (
    <Row>
      <Col>
        <Card className="mb-4 shops-card">
          <CardHeader className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 mr-3">Shop Ambassadors</h3>
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1');
                    }}
                    href="#"
                  >
                    Active BAs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2');
                    }}
                    href="#"
                  >
                    Inactive BAs
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <Button
              className="btn-icon btn-3"
              color="info"
              style={{ visibility: 'hidden' }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
              Add BA
            </Button>
          </CardHeader>
          <CardBody className="p-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                  size={'sm'}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Shop Ambassador Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col" className="text-center">
                        Activation Code
                      </th>
                      <th scope="col" className="text-center">
                        Prospected
                      </th>
                      <th scope="col" className="text-center">
                        Converted
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeAmbassadors.map((brandAmbassador, index) => {
                      return (
                        <tr key={index}>
                          <td>
                          
                            {brandAmbassador.name}
                          </td>
                          <td>
                            {formatPhoneNumber(
                              brandAmbassador.phone,
                            )}
                          </td>
                          <td className="text-center">
                            {brandAmbassador.code}
                          </td>
                          <td className="text-center">
                            {brandAmbassador.prospected}
                          </td>
                          <td className="text-center">
                            {brandAmbassador.converted}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TabPane>
              <TabPane tabId="2">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                  size={'sm'}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Shop Ambassador Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col" className="text-center">
                        Activation Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inactiveAmbassadors.map((brandAmbassador) => {
                      return (
                        <tr key={brandAmbassador.phone}>
                          <td className="text-muted">
                            {brandAmbassador.name}
                          </td>
                          <td className="text-muted">
                            {formatPhoneNumber(
                              brandAmbassador.phone,
                            )}
                          </td>
                          <td className="text-center text-muted">
                            {brandAmbassador.code}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BrandAmbassadorList;
