/* eslint-disable */

export const countryVariables = {
  'sierra leone': {
    name: 'sierra leone',
    currencySymbol: 'Le ',
    expected: 'Remittance',
    fulfilled: 'Remitted',
    remaining: 'To Remit',
    percentage: 'Percentage',
    territoryLabel: 'territory',
    supervisorLabel: 'supervisor',
    agentProfileLabel: 'allCommissions',
    smsLabel: 'allSmses',
    createActionLabel: 'addAction',
    actionLabel: 'action',
    rewardLabel: 'allRewards',
    updateRewardLabel: 'reception',
    supervisorAgentLabel: 'agentSup',
    agentRole: ['agent', 'agent - urban'],
    overviewComponent: {
      variables: [
        ['Remittance', 'function'],
        ['To Remit', 'function'],
        ['Remitted', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territoriesComponent: {
      variables: [
        ['Remittance', 'function'],
        ['To Remit', 'function'],
        ['Remitted', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territoryDetail: {
      variables: [
        ['Remittance', 'function'],
        ['To Remit', 'function'],
        ['Remitted', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territorySelectOptions: ['Deduct Commission', 'Adjust Deposit/Loan'],
    territoryAgentTable: {
      balance: {
        label: ['E-currency', 'Balance Friday'],
        name: 'balanceFridayMorning',
        value: '',
        show: true,
        func: '',
      },
      expected: {
        label: ['Expected', 'Remittance'],
        name: 'expectedRemittance',
        value: '',
        show: true,
        func: '',
      },
      toRemit: {
        label: ['Amount', 'To Remit'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      paid: {
        label: ['Total', 'Remitted'],
        name: 'totalRemitted',
        value: '',
        show: true,
        func: '',
      },
      manualPay: {
        label: ['Cash'],
        name: 'totalCashRemitted',
        value: '',
        show: true,
        func: '',
      },
      mobilePay: {
        label: ['Mobile Money'],
        name: 'totalMobileMoneyRemitted',
        value: '',
        show: true,
        func: '',
      },
      delta: {
        label: ['Delta'],
        name: 'delta',
        value: '',
        show: true,
        func: '',
      },
      payStatus: {
        label: ['Remittance Status'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      comOwedWeek: {
        label: ['Commission Owed', 'For the week'],
        name: 'commissionOwedForTheWeek',
        value: '',
        show: true,
        func: '',
      },
      weeklyOwed: {
        label: ['Weekly Owed'],
        name: 'weeklyOwed',
        value: '',
        show: true,
        func: '',
      },
      remitCom: {
        label: ['Remit Com'],
        name: 'remitCommission',
        value: '',
        show: true,
        func: '',
      },
      payCom: {
        label: ['Payment Com'],
        name: 'mobilePaymentCommission',
        value: '',
        show: true,
        func: '',
      },
      topup: {
        label: ['Topup'],
        name: 'weeklyTopup',
        value: '',
        show: true,
        func: '',
      },
      owedBefore: {
        label: ['Owed Com'],
        name: 'commissionOwedBefore',
        value: '',
        show: true,
        func: '',
      },
      reward: {
        label: ['Reward'],
        name: 'agentOfTheMonthAndOtherRewards',
        value: '',
        show: true,
        func: '',
      },
      pdsCom: {
        label: ['PDS Com'],
        name: 'pdsCommission',
        value: '',
        show: true,
        func: '',
      },
      pdsBonus: {
        label: ['PDS Bonus'],
        name: 'pdsBonus',
        value: '',
        show: true,
        func: '',
      },
      deductComDebt: {
        label: ['Deduct Commission', 'From debt'],
        name: 'deductDebtFromCommission',
        value: '',
        show: true,
        func: '',
      },
      debtAfterRemit: {
        label: ['Debt', 'After Remit'],
        name: 'debtAfterRemit',
        value: '',
        show: true,
        func: '',
      },
      debtBeforeRemit: {
        label: ['Before Remit'],
        name: 'debtBeforeRemit',
        value: '',
        show: true,
        func: '',
      },
      comDeductDebt: {
        label: ['Com deduct Debt'],
        name: 'commissionToDeductFromDebt',
        value: '',
        show: true,
        func: '',
      },
      withholdCom: {
        label: ['Withhold', 'Commission'],
        name: 'withholdingCommission',
        value: '',
        show: true,
        func: '',
      },
      points: {
        label: ['Points'],
        name: 'totalPoints',
        value: '',
        show: true,
        func: '',
      },
      weeklyPoints: {
        label: ['Week'],
        name: 'weeklyPoints',
        value: '',
        show: true,
        func: '',
      },
      tier: {
        label: ['Tier'],
        name: 'agentTierStatus',
        value: '',
        show: true,
        func: '',
      },
      currentPoints: {
        label: ['Current Week', 'Total Points'],
        name: 'weekTotalPoints',
        value: '',
        show: true,
        func: '',
      },
      percentageShow: {
        label: ['Percentage'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      adjustment: {
        label: ['Angaza Adjustment'],
        name: 'angazaAdjustment',
        value: '',
        show: true,
        func: '',
      },
      toAdjust: {
        label: ['To Adjust'],
        name: 'toAdjustOnAngaza',
        value: '',
        show: true,
        func: '',
      },
      adjusted: {
        label: ['Adjusted'],
        name: 'angazaAdjustmentDone',
        value: '',
        show: true,
        func: '',
      },
    },
    supervisorPage: {
      remittance: {
        label: ['Expected', 'Remittance'],
        func: ''
      },
      remitted: {
        label: ['Total', 'Remitted'],
        func: ''
      },
      toRemit: {
        label: ['To', 'Remit'],
        func: ''
      },
      status: {
        label: 'Remittance Status',
        func: ''
      }
    },
    supervisorDetail: {
      remittance: {
        label: 'Remittance',
        func: ''
      },
      remitted: {
        label: 'Remitted',
        func: ''
      },
      toRemit: {
        label: 'To Remit',
        func: ''
      }
    },
  },
  liberia: {
    name: 'liberia',
    currencySymbol: 'L$ ',
    expected: 'Target Sales',
    fulfilled: 'Actual Sales',
    remaining: 'PAR Friday',
    percentage: 'Percentage',
    territoryLabel: 'lbTerritory',
    supervisorLabel: 'lbSupervisor',
    agentProfileLabel: 'allLbCommissions',
    smsLabel: 'allLbSmses',
    createActionLabel: 'addLbAction',
    actionLabel: 'lbAction',
    rewardLabel: 'allLbRewards',
    agentRole: ['sales agent'],
    updateRewardLabel: 'lbReception',
    supervisorAgentLabel: 'lbAgentSup',
    overviewComponent: {
      variables: [
        ['Target Sales', 'function'],
        ['PAR Friday (%)', 'function'],
        ['Actual Sales Revenue', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territoriesComponent: {
      variables: [
        ['Target Sales', 'function'],
        ['PAR Friday (%)', 'function'],
        ['Actual Sales Revenue', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territoryDetail: {
      variables: [
        ['Target Sales', 'function'],
        ['PAR Friday (%)', 'function'],
        ['Actual Sales Revenue', 'function'],
        ['Percentage', 'function'],
      ],
    },
    territorySelectOptions: [
      'Adjust Deposit/Loan',
      'Pay Weekly Commissions',
      'Pay Monthly Commissions',
    ],
    territoryAgentTable: {
      balance: {
        label: ['Targent', 'Sales'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      expected: {
        label: ['Actual', 'Revenue'],
        name: 'totalRevenueMonth',
        value: '',
        show: true,
        func: '',
      },
      toRemit: {
        label: ['Revenue', 'Diff'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      paid: {
        label: ['Total', 'Payments'],
        name: 'totalPayments',
        value: '',
        show: true,
        func: '',
      },
      manualPay: {
        label: ['Manual'],
        name: 'manualPayment',
        value: '',
        show: true,
        func: '',
      },
      mobilePay: {
        label: ['Mobile Money'],
        name: 'mtnPayment',
        value: '',
        show: true,
        func: '',
      },
      delta: {
        label: ['Delta'],
        name: 'delta',
        value: '',
        show: true,
        func: '',
      },
      payStatus: {
        label: ['PAR Friday status'],
        name: '',
        value: '',
        show: true,
        func: '',
      },
      comOwedWeek: {
        label: ['Commission Owed', 'For the week'],
        name: 'commissionOwedForTheWeek',
        value: '',
        show: true,
        func: '',
      },
      weeklyOwed: {
        label: ['Weekly Owed'],
        name: 'weeklyOwed',
        value: '',
        show: true,
        func: '',
      },
      remitCom: {
        label: ['Remit Com'],
        name: 'remitCommission',
        value: '',
        show: true,
        func: '',
      },
      payCom: {
        label: ['Payment Com'],
        name: 'paymentCommission',
        value: '',
        show: true,
        func: '',
      },
      topup: {
        label: ['Topup'],
        name: 'weeklyTopup',
        value: '',
        show: true,
        func: '',
      },
      owedBefore: {
        label: ['Owed Com'],
        name: 'commissionOwedBefore',
        value: '',
        show: true,
        func: '',
      },
      reward: {
        label: ['Reward'],
        name: 'agentOfTheMonthAndOtherRewards',
        value: '',
        show: true,
        func: '',
      },
      pdsCom: {
        label: ['PDS Com'],
        name: 'pdsCommission',
        value: '',
        show: true,
        func: '',
      },
      pdsBonus: {
        label: ['PDS Bonus'],
        name: 'pdsBonus',
        value: '',
        show: true,
        func: '',
      },
      deductComDebt: {
        label: ['Deduct Commission', 'From debt'],
        name: 'deductDebtFromCommission',
        value: '',
        show: true,
        func: '',
      },
      debtAfterRemit: {
        label: ['Debt', 'After Deduction'],
        name: 'debtAfterDeduction',
        value: '',
        show: true,
        func: '',
      },
      debtBeforeRemit: {
        label: ['Before Deduction'],
        name: 'debtBeforeDeduction',
        value: '',
        show: true,
        func: '',
      },
      comDeductDebt: {
        label: ['Com deduct Debt'],
        name: 'commissionToDeductFromDebt',
        value: '',
        show: true,
        func: '',
      },
      withholdCom: {
        label: ['Withhold', 'Commission'],
        name: 'withholdingCommission',
        value: '',
        show: true,
        func: '',
      },
      points: {
        label: ['Points'],
        name: 'totalPoints',
        value: '',
        show: true,
        func: '',
      },
      weeklyPoints: {
        label: ['Week'],
        name: 'weeklyPoints',
        value: '',
        show: true,
        func: '',
      },
      tier: {
        label: ['Tier'],
        name: 'lateAccountTier',
        value: '',
        show: true,
        func: '',
      },
      currentPoints: {
        label: ['Current Week', 'Total Points'],
        name: 'weekTotalPoints',
        value: '',
        show: true,
        func: '',
      },
      percentageShow: {
        label: ['Percentage'],
        name: 'agentWeekPar',
        value: '',
        show: true,
        func: '',
      },
      adjustment: {
        label: ['Amount sent'],
        name: 'amountSent',
        value: '',
        show: true,
        func: '',
      },
      toAdjust: {
        label: ['To Send for Agent'],
        name: 'toSendForAgent',
        value: '',
        show: true,
        func: '',
      },
      adjusted: {
        label: ['Money Sent'],
        name: 'moneySent',
        value: '',
        show: true,
        func: '',
      },
    },
    supervisorPage: {
      remittance: {
        label: ['Target', 'Revenue'],
        func: ''
      },
      remitted: {
        label: ['Actual', 'Revenue'],
        func: ''
      },
      toRemit: {
        label: ['Revenue', 'Diff'],
        func: ''
      },
      status: {
        label: 'Revenue Status',
        func: ''
      },
    },
    supervisorDetail: {
      remittance: {
        label: 'Target Revenue',
        func: ''
      },
      remitted: {
        label: 'Actual Revenue',
        func: ''
      },
      toRemit: {
        label: 'Revenue Diff',
        func: ''
      }
    },
  },
};
