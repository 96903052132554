/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import { useQuery } from '@apollo/react-hooks';
import { GET_LOGS } from '../gql/gql';
import { getUsernameFromEmail } from "../../utils/helpers";

const AdminNavbar = (props) => {

  const logout = () => {
    localStorage.removeItem('token')
    // Remove the user email as well
    localStorage.removeItem('userEmail')
    localStorage.removeItem('isStaff')
    localStorage.removeItem('username')
    localStorage.removeItem('isSuperuser');
    localStorage.removeItem('country');
    localStorage.removeItem('role');
    props.history.push('/login')
  }

  const { data, loading, error } = useQuery(GET_LOGS);
  if (loading) return `LOADING...`;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;

  const commandLogs = data.allLogs.edges.map(item => item.node);
  let lastCommissionLog;
  if (commandLogs.length) {
    lastCommissionLog = commandLogs[0]
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h2
            className="h4 mb-0 text-white text-uppercase ml-3 ml-md-0 ml-lg-0"
          >
            {props.brandText}
          </h2>
  <small className="text-white ml-auto mr-3 mr-md-0 mr-lg-0 d-md-block d-sm-none d-none"><i>Last updated: {
                lastCommissionLog  ?
                new Date(lastCommissionLog.finished).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: false
                }) : 'No update yet'}</i></small>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto" style={{visibility: 'hidden'}}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center justify-content-center">

                  <Media className="d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      <br />
                      {/* Ahmed Elomar <i>admin</i> */}
                    </span>
                  </Media>
                  <span className="ml-2 avatar rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/profile/default-avatar.png")}
                    />
                  </span>
                </Media>
                <span className="mt-5 ml-2 h5 text-white">{getUsernameFromEmail(localStorage.getItem('userEmail'))}</span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem to="/login" tag={Link}>
                  <i className="ni ni-user-run" />
                  <span onClick={logout}>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
