import React from 'react';

import CurrencyFormat from 'react-currency-format';
import { useMutation } from '@apollo/react-hooks';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  // Table,
  Container,
  Row,
  UncontrolledTooltip,
  // Collapse,
  Col,
} from 'reactstrap';

import { singleAgentCommissions, commissionValue, getAgentTargetRevenue } from '../../utils/helpers';
import { countryVariables } from '../../utils/country-variables';
import { graphQlQueries } from '../gql/main-gql';

countryVariables[
  'liberia'
].territoryAgentTable.balance.func = getAgentTargetRevenue;

const AgentProfile = ({ commissionData, currentThursday, country }) => {
  // const [isOpen, setIsOpen] = useState(true);

  const agentProfileLabel = countryVariables[country]['agentProfileLabel'];
  // naming fields based on country
  const commissionFields = countryVariables[country].territoryAgentTable;
  const currencySymbol = countryVariables[country]['currencySymbol'];

  const commission = singleAgentCommissions(
    commissionData[agentProfileLabel].edges,
    currentThursday,
  );
  let agentCommission;
  // eslint-disable-next-line
  const [updateCommission, { data }] = useMutation(
    graphQlQueries[country]['update-commission'],
  );

  if (commission.length) {
    // Pay commission toggle handler
    const handlePayCommission = (id, e) => {
      updateCommission({
        variables: { id: id, payCommission: e.target.checked },
      });
    };

    // Withhold commission toggle handler
    const handleWithholdCommission = (id, e) => {
      const value = e.target.checked ? 'yes' : 'no';
      updateCommission({ variables: { id: id, withholdingCommission: value } });
    };

    const handleDeductCommission = (id, e) => {
      updateCommission({
        variables: { id: id, deductDebtFromCommission: e.target.checked },
      });
    };

    agentCommission = commission[0];

    // const toggle = () => setIsOpen(!isOpen);

    return (
      <>
        <Container className="mt-5" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" md="12" lg="12" xl="7">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={
                            agentCommission.agent.userPhoto === '0'
                              ? require('../../assets/img/profile/default-avatar.png')
                              : agentCommission.agent.userPhoto
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-4 pt-md-0 pb-0 pb-md-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-left h5 mb-0 font-weight-300">
                      Start Date: <br />{' '}
                      <strong>
                        {new Date(
                          agentCommission.agent.createdUtc,
                        ).toLocaleString('en-us', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </strong>{' '}
                      <br /> <br />
                      Years Of Service: <br />
                      <strong>{agentCommission.yearsOfService} </strong>
                      <br /> <br />
                      PAR Friday Morning: <br />
                      <strong>{agentCommission.agentWeekPar} </strong>
                    </div>
                    {commissionValue(
                      agentCommission,
                      commissionFields,
                      'debtBeforeRemit',
                    ) > 0 ? (
                      <span className="btn btn-warning">On Loan</span>
                    ) : (
                      ''
                    )}
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <div className="text-center mt-md-2">
                    <h3 className="font-weight-normal">
                      {agentCommission.agent.firstName}{' '}
                      {agentCommission.agent.lastName}
                      <span className="font-weight-bold pl-2">
                        ({agentCommission.agent.username})
                      </span>
                    </h3>
                    <div className="h5 font-weight-300 pt-2">
                      <i className="fas fa-globe-africa mr-2" />
                      {agentCommission.agent.district.territory.name}
                    </div>
                    <div className="h5 font-weight-normal pt-2">
                      <i className="fas fa-user-tie mr-2" />
                      Agent's Supervisor -{' '}
                      <strong>
                        {agentCommission.agent.supervisor.firstName}{' '}
                        {agentCommission.agent.supervisor.lastName}
                        <span className="font-weight-bold pl-2">
                          ({agentCommission.agent.supervisor.username})
                        </span>
                      </strong>
                    </div>
                    <hr className="mt-4 mb-3" />
                  </div>
                  <h3 className="text-center font-weight-700">Overall</h3>

                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center pt-0">
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="fas fa-gifts mr-1"></span>
                            <span className="description">Products sold</span>
                          </div>
                          <span className="heading">
                            {agentCommission.totalProductsSold}
                            <sup className="text-lowercase font-weight-400 text-primary pl-2">
                              <span className="fas fa-lock-open mr-1"></span>
                              {agentCommission.totalProductsUnlocked}
                            </sup>
                          </span>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="far fa-grin-stars mr-1"></span>
                            <span className="description">Points</span>
                          </div>
                          <span className="heading">
                            {agentCommission.totalPoints}
                          </span>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="far fa-grin-stars mr-1"></span>
                            <span className="description">
                              Current Week Points
                            </span>
                          </div>
                          <span className="heading">
                            {agentCommission.weekTotalPoints}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <hr className="mt-0 mb-3" />

                  <div className="position-relative">
                    <h3 className="text-center font-weight-700">Week</h3>
                    <Row className="pt-3">
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center pt-0">
                          <div>
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                {/* Balance Friday Morning */}
                                {`${commissionFields.balance.label[0]} ${commissionFields.balance.label[1]}`}
                              </span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={commissionFields['balance'].name ? 
                                commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'balance',
                                ): commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'balance',
                                )(agentCommission)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                {/* Expected Remittance */}
                                {`${commissionFields.expected.label[0]} ${commissionFields.expected.label[1]}`}
                              </span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'expected',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </span>
                          </div>
                          <div
                            data-placement="top"
                            id={'tooltip' + parseInt(agentCommission.totalRemitted)}
                          >
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                {`${commissionFields.paid.label[0]} ${commissionFields.paid.label[1]}`}
                              </span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'paid',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={'tooltip' + parseInt(agentCommission.totalRemitted)}
                          >
                            <Row>
                              <Col className="text-left">
                                {commissionFields.manualPay.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'manualPay',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">
                                {commissionFields.mobilePay.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'mobilePay',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <hr className="m-0 border-neutral" />
                            <Row>
                              <Col className="text-left">
                                {commissionFields.delta.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.delta}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </Row>
                    <hr className="m-0 mb-2 ml-auto mr-auto" />
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center pt-0">
                          <div
                            data-placement="top"
                            id={
                              'tooltip' +
                              parseInt(agentCommission.commissionOwedForTheWeek)
                            }
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                Commission Owed
                              </span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={agentCommission.commissionOwedForTheWeek}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={
                              'tooltip' +
                              parseInt(agentCommission.commissionOwedForTheWeek)
                            }
                          >
                            <Row>
                              <Col className="text-left">Weekly</Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.weeklyOwed}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">Topup</Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.weeklyTopup}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                Remit Com
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.remitCommission}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                Payment Com
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'payCom',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                Owed Com
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.commissionOwedBefore}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left">Reward</Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={
                                    agentCommission.agentOfTheMonthAndOtherRewards
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                          </UncontrolledTooltip>

                          <div data-placement="top" id="tooltipDebt">
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">Loan/Deposit</span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'debtAfterRemit',
                                  )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />
                            </span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="tooltipDebt"
                          >
                            <Row>
                              <Col className="text-left text-nowrap">
                                Manual Debt adjustments
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.manualDebtAdjustments}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                Com to deduct
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={
                                    agentCommission.commissionToDeductFromDebt
                                  }
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                {commissionFields.debtBeforeRemit.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'debtBeforeRemit',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                              {commissionFields.debtAfterRemit.label[0] + ' ' + commissionFields.debtAfterRemit.label[1]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'debtAfterRemit',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                          </UncontrolledTooltip>

                          <div data-placement="top" id="tooltipAngaza">
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="fas fa-cogs mr-1"></span>
                              <span className="description">
                                {/* Adjustement */}
                                {commissionFields.adjustment.label[0]}
                              </span>
                            </div>
                            <span className="heading">
                              <CurrencyFormat
                                value={commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'adjustment',
                                )}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={currencySymbol}
                              />{' '}
                              {commissionValue(
                                agentCommission,
                                commissionFields,
                                'adjusted',
                              )
                                ? '(Yes)'
                                : '(No)'}
                            </span>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target="tooltipAngaza"
                          >
                            <Row>
                              <Col className="text-left text-nowrap">
                                {/* To Adjust */}
                                {commissionFields.toAdjust.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'toAdjust',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                {/* Adjusted */}
                                {commissionFields.adjusted.label[0]}
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={commissionValue(
                                    agentCommission,
                                    commissionFields,
                                    'adjustment',
                                  )}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-left text-nowrap">
                                Commission Owed After
                              </Col>
                              <Col className="text-right text-capitalize text-nowrap">
                                <CurrencyFormat
                                  value={agentCommission.commissionOwedAfter}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={currencySymbol}
                                />
                              </Col>
                            </Row>
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </Row>
                    <hr className="m-0 mb-2 ml-auto mr-auto" />
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center pt-0">
                          <div>
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                Pay Commission
                              </span>
                            </div>
                            <span className="heading">
                              <div className="d-flex align-items-center justify-content-center">
                                <label className="custom-toggle mb-0">
                                  <input
                                    defaultChecked={
                                      agentCommission.payCommission
                                    }
                                    type="checkbox"
                                    onChange={(e) =>
                                      handlePayCommission(agentCommission.id, e)
                                    }
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <strong className="pl-3">
                                  {agentCommission.payCommission
                                    ? '(Yes)'
                                    : '(No)'}
                                </strong>
                              </div>
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                Withhold Commission
                              </span>
                            </div>
                            <span className="heading">
                              <div className="d-flex align-items-center justify-content-center">
                                <label className="custom-toggle mb-0">
                                  <input
                                    defaultChecked={
                                      agentCommission.withholdingCommission
                                    }
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleWithholdCommission(
                                        agentCommission.id,
                                        e,
                                      )
                                    }
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <strong className="pl-3">
                                  {agentCommission.withholdingCommission
                                    ? '(Yes)'
                                    : '(No)'}
                                </strong>
                              </div>
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span className="description">
                                Deduct from debt
                              </span>
                            </div>
                            <span className="heading">
                              <div className="d-flex align-items-center justify-content-center">
                                <label className="custom-toggle mb-0">
                                  <input
                                    defaultChecked={
                                      agentCommission.deductDebtFromCommission
                                    }
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleDeductCommission(
                                        agentCommission.id,
                                        e,
                                      )
                                    }
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <strong className="pl-3">
                                  {agentCommission.deductDebtFromCommission
                                    ? '(Yes)'
                                    : '(No)'}
                                </strong>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <hr className="m-0 mb-2 ml-auto mr-auto" />
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats pt-0 d-flex justify-content-center">
                          <div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="fas fa-gifts mr-1"></span>
                              <span className="description">Product sold</span>
                            </div>
                            <span className="heading">
                              {agentCommission.productsSold}
                              <sup className="text-lowercase font-weight-400 text-primary pl-2">
                                <span className="fas fa-lock-open mr-1"></span>
                                {agentCommission.productsUnlocked}
                              </sup>
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="far fa-grin-stars mr-1"></span>
                              <span className="description">Points</span>
                            </div>
                            <span className="heading">
                              {agentCommission.weeklyPoints}
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="fas fa-trophy mr-1"></span>
                              <span className="description">
                                {commissionFields.tier.label[0]}
                              </span>
                            </div>
                            <span
                              className={
                                'heading span-' +
                                commissionValue(
                                  agentCommission,
                                  commissionFields,
                                  'tier',
                                )
                              }
                            >
                              <sup className="text-lowercase font-weight-400 text-primary"></sup>
                            </span>
                          </div>
                          <div>
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="fas fa-star mr-1"></span>
                              <span className="description">Reward</span>
                            </div>
                            <span className="heading">
                              {agentCommission.reward}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <hr className="m-0 mb-2 ml-auto mr-auto" />
                    <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center pt-0">
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="far fa-grin-stars mr-1"></span>
                            <span className="description">Commission Withheld (KYC issue)</span>
                          </div>
                          <span className="heading">
                            {agentCommission.amountWithheld}
                          </span>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="far fa-grin-stars mr-1"></span>
                            <span className="description">Withheld Points (KYC issue)</span>
                          </div>
                          <span className="heading">
                            {agentCommission.weeklyPointsToWithhold}
                          </span>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            <span className="far fa-grin-stars mr-1"></span>
                            <span className="description">
                              Current week accounts with KYC issues
                            </span>
                          </div>
                          <span className="heading">
                            {agentCommission.weekAccountsWithKycIssues}
                          </span>
                        </div>
                      </div>
                    </div>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="" md="12" lg="12" xl="5">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">More Agent details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        className="btn-icon"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        <i className="fas fa-edit" />
                        <span>Edit account</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={agentCommission.username}
                              id="input-username"
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={agentCommission.agentEmail}
                              placeholder="email@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={agentCommission.agentFirstName}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={agentCommission.agentLastName}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone-number"
                            >
                              Phone Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={agentCommission.phoneNumber}
                              id="input-phone-number"
                              placeholder="Phone Number"
                              type="tel"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-sec-phone-number"
                            >
                              Secondary Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={agentCommission.phoneNumber}
                              id="input-sec-phone-number"
                              placeholder="Secondary Phone Number"
                              type="tel"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return <p className="text-white">LOADING...</p>;
};

export default AgentProfile;
