import React from 'react'

// reactstrap components
import {
  Row,
  Card,
  Col,
  Table
} from 'reactstrap';

import CurrencyFormat from 'react-currency-format';

import { getBrandAmbassadorsByShop } from '../../utils/utils'

const AssetFinanceMonthly = ({assetFinanceList}) => {

  const brandAmbassadorsByShop = getBrandAmbassadorsByShop(assetFinanceList)
  const totalProspected = assetFinanceList.reduce(
    (sum, ambassador) => {return sum + ambassador.prospected}, 0)
  const totalConverted = assetFinanceList.reduce(
    (sum, ambassador) => {
      return sum + ambassador.converted}, 0)
  const totalBaCommissions = assetFinanceList.reduce(
    (sum, ambassador) => {return sum + ambassador.baCommission}, 0)
  
  return (
    <>
      <Row className='mb-5 mt-4'>
        <Col md='12' lg='12' xl='12'>
          <Card className='shadow'>
            {brandAmbassadorsByShop.map((shop, index) => {
              return (
                <Table className='align-items-center table-flush custom-table' size='sm' hover responsive key={index}>
                  <thead>
                    <tr className='bg-primary'>
                      <td><h4 className='mb-0 text-white'>{shop.shopName}</h4></td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </thead>
                  <thead className=''>
                    <tr>
                      <th scope='col' className=''>Brand Ambassador Name</th>
                      <th scope='col' className=''>Activation Code</th>
                      <th scope='col' style={{width: 202}}>Prospected</th>
                      <th scope='col' style={{width: 182}}>Converted</th>
                      <th scope='col' style={{width: 182}}>Commissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shop.baList.map((ambassador, index) => {
                      return (
                        <tr key={index}>
                          <td className=''>{ambassador.fullName}</td>
                          <td className=''>{ambassador.baCode}</td>
                          <td className=''>{ambassador.prospected}</td>
                          <td className=''>{ambassador.converted}</td>
                          <td>
                            <strong>
                              <CurrencyFormat
                                value={ambassador.baCommission}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '} />
                            </strong>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              );
            })}
            <Table className='table-flush custom-table' hover responsive>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'/>
                  <th scope='col' style={{width: 202}}>Total <br />Prospected</th>
                  <th scope='col' style={{width: 182}}>Total <br />Converted</th>
                  <th scope='col' style={{width: 182}}>Total Commissions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><h4 className='mb-0'>Totals</h4></td>
                  <td><h3 className='font-weight-bold mb-0'>{totalProspected}</h3></td>
                  <td><h3 className='font-weight-bold mb-0'>{totalConverted}</h3></td>
                  <td>
                    <h3 className='font-weight-bold mb-0'>
                      <CurrencyFormat
                        value={totalBaCommissions}
                        displayType={'text'}
                        thousandSeparator={true} prefix={'Le '} />
                    </h3>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AssetFinanceMonthly;
