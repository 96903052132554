import gql from 'graphql-tag'

export const GET_ALL_AGENTS = gql`
  query GetallAgents {
    allAgents {
      edges {
      node {
        username
        firstName
        lastName
        role
        archived
        district {
          territory {
            name
          }
        }
      }
    }
    }
  }
`

export const GET_ALL_TERRITORIES = gql`
  query GetallTerritories {
    allTerritories {
      edges {
        node {
          name
          abbreviation
          isActive
          region {
            name
          }
          terriDistrict {
            districts: edges {
              node {
                name,
                districtAgent {
                  agents: edges {
                    node {
                      username
                      role
                      agentCommission {
                        commissions: edges {
                          node {
                            agent {
                              id
                              username
                            }
                            currentWeekThursday
                            expectedRemittance
                            totalRemitted
                            reward
                            isRewardPaid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          }
        }
    }
  }
`

export const GET_ALL_SUPERVISORS = gql`
  query GetallSupervisors {
    edges {
      node {
        username
        firstName
        territory {
          name
        }
      }
    }
  }
`

export const GET_ALL_COMMISSIONS = gql`
query GetAllCommissions($agentview: String) {
  allCommissions(agentview: $agentview) {
    edges {
      node {
        id
        currentWeekThursday
        angazaAdjustementDone
        toAdjustOnAngaza
      }
    }
  }
}
`

export const GET_SINGLE_AGENT_COMMISSIONS = gql`
query GetAgentCommissions($agent_Username: String!, $agentview: String) {
  allCommissions(agent_Username: $agent_Username, agentview: $agentview) {
    edges {
      node {
        id
        agent {
          id
          firstName
          lastName
          createdUtc
          username
          userPhoto
          district {
            territory {
              name
            }
          }
          supervisor {
            firstName
            lastName
            username
            userPhoto
          }
        }
        currentWeekThursday
        expectedRemittance
        totalRemitted
        totalCashRemitted
        totalMobileMoneyRemitted
        delta
        agentTierStatus
        commissionOwedForTheWeek
        weeklyOwed
        remitCommission
        mobilePaymentCommission
        commissionOwedBefore
        weeklyTopup
        deductDebtFromCommission
        commissionToDeductFromDebt
        debtBeforeRemit
        totalPoints
        weeklyPoints
        withholdingCommission
        payCommission
        balanceFridayMorning
        productsSold
        productsUnlocked
        totalProductsSold
        totalProductsUnlocked
        agentOfTheMonthAndOtherRewards
        manualDebtAdjustments
        debtAfterRemit
        totalRemittedOnTime
        yearsOfService
        previousWeekRemitScore
        consecutiveFullFiftykRemits
        consecutivePerfectFiftykRemits
        reward
        toAdjustOnAngaza
        angazaAdjustment
        commissionOwedAfter
        angazaAdjustementDone
        weekTotalPoints
        weeklyPointsToWithhold
        amountWithheld
        weekAccountsWithKycIssues
      }
    }
  }
}
`


export const UPDATE_COMMISSION = gql`
  mutation CommissionMutation(
    $id: ID, $payCommission: Boolean, $withholdingCommission: String,
    $deductDebtFromCommission: Boolean, $payload: String) {
    updateCommission(
      id: $id, payCommission: $payCommission, withholdingCommission: $withholdingCommission,
      deductDebtFromCommission: $deductDebtFromCommission, payload: $payload) {
        commission {
          id
          payCommission
          withholdingCommission
          deductDebtFromCommission
          weeklyOwed
          commissionOwedForTheWeek
          remitCommission
          mobilePaymentCommission
          weeklyTopup
          expectedRemittance
          delta
          toAdjustOnAngaza
          commissionOwedAfter
          manualDebtAdjustments
          agentOfTheMonthAndOtherRewards
          debtAfterRemit
          reward
          isRewardPaid
          
        }
    }
  }
`
export const CREATE_ACTION = gql`
  mutation CreateAction($actionName: String, $userEmail: String) {
    addAction(actionName: $actionName, userEmail: $userEmail) {
      action {
        id
      }
    }
  }
`


export const GET_SINGLE_TERRITORY = gql`
  query GetTerritory($abbreviation: String!) {
    territory(abbreviation: $abbreviation) {
      id
      name
      abbreviation
      territoryManager {
        username
        firstName
        lastName
      }
      supervisor {
        supervisors: edges {
          node {
            firstName
            lastName
            username
          }
        }
      }
      terriDistrict {
        districts: edges {
          node {
            name,
            districtAgent {
              agents: edges {
                node {
                  username
                  role
                  agentCommission {
                    commissions: edges {
                      node {
                        id
                        agent {
                          id
                          firstName
                          lastName
                          username
                          balanceSll
                        }
                        currentWeekThursday
                        expectedRemittance
                        totalRemitted
                        totalCashRemitted
                        totalMobileMoneyRemitted
                        delta
                        agentTierStatus
                        commissionOwedForTheWeek
                        weeklyOwed
                        remitCommission
                        mobilePaymentCommission
                        weeklyTopup
                        deductDebtFromCommission
                        commissionToDeductFromDebt
                        debtBeforeRemit
                        totalPoints
                        weeklyPoints
                        deductDebtFromCommission
                        withholdingCommission
                        balanceFridayMorning
                        toAdjustOnAngaza
                        debtAfterRemit
                        angazaAdjustment
                        commissionOwedAfter
                        angazaAdjustementDone
                        agentOfTheMonthAndOtherRewards
                        commissionOwedBefore
                        reward
                        isRewardPaid
                        weekTotalPoints
                        pdsBonus
                        pdsCommission
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SINGLE_TERRITORY_SUPERVISORS = gql`
  query GetTerritory($abbreviation: String!) {
    territory(abbreviation: $abbreviation) {
      id
      name
      abbreviation
      territoryManager {
        username
        firstName
        lastName
      }
      supervisor {
        supervisors: edges {
          node {
            firstName
            lastName
            username
            primaryPhone
            userPhoto
            isActive
            agentSup {
              agents: edges {
                node {
                  username,
                  agentCommission {
                    commissions: edges {
                      node {
                        id
                        agent {
                          id
                          firstName
                          lastName
                          username
                          balanceSll
                        }
                        currentWeekThursday
                        expectedRemittance
                        totalRemitted
                        totalCashRemitted
                        totalMobileMoneyRemitted
                        delta
                        agentTierStatus
                        commissionOwedForTheWeek
                        weeklyOwed
                        remitCommission
                        mobilePaymentCommission
                        weeklyTopup
                        deductDebtFromCommission
                        commissionToDeductFromDebt
                        debtBeforeRemit
                        totalPoints
                        weeklyPoints
                        deductDebtFromCommission
                        withholdingCommission
                        balanceFridayMorning
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SUPERVISOR_DETAIL = gql`
  query GetSupervisorDetail($username: String!) {
    supervisor(username: $username) {
      id
      firstName
      lastName
      username
      userPhoto
      primaryPhone
      agentSup {
        agents: edges {
          node {
            username,
            agentCommission {
              commissions: edges {
                node {
                  id
                  agent {
                    id
                    firstName
                    lastName
                    username
                    balanceSll
                  }
                  currentWeekThursday
                  expectedRemittance
                  totalRemitted
                  totalCashRemitted
                  totalMobileMoneyRemitted
                  delta
                  agentTierStatus
                  commissionOwedForTheWeek
                  weeklyOwed
                  remitCommission
                  mobilePaymentCommission
                  weeklyTopup
                  deductDebtFromCommission
                  commissionToDeductFromDebt
                  debtBeforeRemit
                  totalPoints
                  weeklyPoints
                  deductDebtFromCommission
                  withholdingCommission
                  balanceFridayMorning
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_LOGS = gql`
  query GetComLogs {
    allLogs{
      edges{
        node {
          id
          task
          finished
          started
        }
      }
    }
  }
`

export const GET_CURRENT_SMSES = gql`
  query getSmses ($currentWeekThursday: String, $territoryName: String){
    allSmses(currentWeekThursday: $currentWeekThursday, territoryName: $territoryName) {
      id
      agent {
        username
        district {
          id
          territory {
            id
            name
          }
        }
      }
      messageNumber
      textMessage
      currentWeekThursday
    }
  }
`

export const GET_AWAITED_REWARD = gql`
  query getAwaitedReward($territoryName: String, $rewarded: String){
    allRewards(territoryName: $territoryName, rewarded: $rewarded) {
      id
      agent {
        id
        username
        firstName
        lastName
        primaryPhone
        agentCommission {
          edges {
            node {
              id
              totalPoints
              currentWeekThursday
            }
          }
        }
      }
      pointSystemReward {
        id
        numberOfPoint
        rewardDetail
        cash
      }
      dateJoined
      datePaid
      isCash
    }
  }
`

export const GET_SYSTEM_REWARD = gql`
  query GetPointSystemReward {
    allPointSystemRewards {
      id
      numberOfPoint
      rewardDetail
      cash
    }
  }
`

export const UPDATE_REWARD = gql`
  mutation UpdateReward($id: ID, $payload: String) {
    receptionMutation(id: $id, payload: $payload) {
      reception {
        id
        agent {
          id
          username
          firstName
          lastName
          primaryPhone
          agentCommission {
            edges {
              node {
                id
                totalPoints
                currentWeekThursday
              }
            }
          }
        }
        pointSystemReward {
          id
          numberOfPoint
          rewardDetail
          cash
        }
        dateJoined
        datePaid
        isCash  
      }
    }
  }
`

export const GET_DATE_RANGED_COMMISSIONS = gql`
  query GetDateRangedCommissions($range: String!) {
    allCommissions(range: $range) {
      edges {
        node {
          currentWeekThursday
          agent {
            username
            district {
              territory {
                name
              }
            }
          }
          commissionOwedForTheWeek
          weeklyTopup
          weeklyOwed
          agentOfTheMonthAndOtherRewards
          remitCommission
          mobilePaymentCommission
          angazaAdjustment
          pdsCommission
          pdsBonus
          weeklyPoints
          amountWithheld
        }
      }
    }
  }
`

export const GET_TERRITORIES = gql`
query GetTerritories {
  allTerritories {
    edges {
      node {
        name
        abbreviation
      }
    }
  }
}
`

export const GET_DATE_RANGED_COMMISSIONS_SUPERVISOR = gql`
  query GetDateRangedCommissionsSup($range: String!) {
    allCommissions(range: $range) {
      edges {
        node {
          currentWeekThursday
          agent {
            district {
              territory {
                name
                abbreviation
              }
            }
            supervisor {
              username
            }
          }
          commissionOwedForTheWeek
          weeklyTopup
        }
      }
    }
  }
`

export const GET_SUPERVISORS = gql`
query GetSupervisors {
  allSupervisors {
    edges {
      node {
        username
        firstName
        lastName
        territory {
          name
        }
        agentSup {
          edges {
            node {
              username
              role
            }
          }
        }
      }
    }
  }
}
`

export const GET_AGENT_REWARDS = gql`
  query GetAgentRewards($username: String) {
    allRewards(username: $username) {
      datePaid
      pointSystemReward {
        rewardDetail
      }
    }
  }
`
