import React, { useState } from 'react';

import { Query } from 'react-apollo';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import _ from 'lodash';

import { GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS } from '../gql/gql-sec';
import { changeNumberFormat } from '../../utils/helpers';
import AssetFinanceMonthly from './asset-finance-monthly';


const AssetFinanceScheme = ({ startDate, endDate, exchanger }) => {
  const [excelReportData, setExportReportData] = useState([]);

  // Excel related info
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formattedStart = new Date(startDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const fileName =
    formattedStart !== formattedEnd
      ? `${formattedStart} - ${formattedEnd} Report`
      : `${formattedStart} Report`;

  //  excel exporter to call from parent component
  const assetFinanceExcelExporter = () => {
    exportToExcel(excelReportData, fileName);
  };

  // Add the child function to exchanger that might be called by the parent.
  exchanger.assetFinanceExcelExporter = assetFinanceExcelExporter;

  // Transform and order performance data
  const reorganizePerformanceData = (performanceQueryResultList) => {
    const reportingData = performanceQueryResultList
      .map((performance) => performance.node)
      .map((data) => {
        return {
          shopName: data.shopName,
          fullName: data.baFullName,
          baCode: data.baCode,
          phoneNumber: data.baPhoneNumber,
          prospected: data.monthlyProspected,
          converted: data.monthlyConverted,
          baCommission: data.monthlyCommission,
        };
      });

    const orderedReportingData = _.orderBy(
      reportingData,
      ['shopName', 'fullName'],
      ['asc', 'asc'],
    );

    setExportReportData(orderedReportingData);
  };

  const exportToExcel = (excelData, fileName) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const end = new Date(endDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    // we have 7 columns
    const wsCols = Array.apply(1, Array(8)).map((item, index) => {
      if (index === 1) {
        return { wch: 19 };
      }
      return { wch: 12 };
    });
    // Adding 4 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 4)).map((item) => {
      return { hpt: 20 };
    });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A3' });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} Report`;
    ws['B1'] = { t: 's', v: title };

    // Change headers Name
    ws.A3.v = 'Shop/Bank Name';
    ws.B3.v = 'Full Name';
    ws.C3.v = 'Activation Code';
    ws.D3.v = 'Phone Number';
    ws.E3.v = 'Prospected';
    ws.F3.v = 'Converted/Approved';
    ws.G3.v = 'Commissions';

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws['!ref'].split(':')[0];
    const lastRefCell = ws['!ref'].split(':')[1];

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws['!ref'] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ['F', 'G'], '#,##0');

    // // merge total cell A12 and B12
    const merge = [
      { s: { r: lastRefCellRow, c: 0 }, e: { r: lastRefCellRow, c: 3 } },
    ];
    ws['!merges'] = merge;

    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' };
    ws[`E${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(E4:E${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`F${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(F4:F${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`G${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(G4:G${lastRefCellRow})`,
      z: '#,##0',
    };

    /* Write total data */
    const wb = {
      Sheets: { "Asset Finance' Commissions": ws },
      SheetNames: ["Asset Finance' Commissions"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      {/*Normal Table*/}
      <Query
        query={GET_BRAND_AMBASSADOR_MONTHLY_CONVERTS}
        variables={{
          endofmonth: `${endDate}`,
          role: 'Asset Finance Scheme (AFS)',
        }}
        fetchPolicy='no-cache'
        onCompleted={(data) =>
          // Update excel report data state
          reorganizePerformanceData(data.allBaMonthlyConverted.edges)
        }
      >
        {({ loading, error, data }) => {
          if (loading) return <p className="text-white">LOADING...</p>;
          if (error) {
            return <p>Error. Try refreshing the page</p>;
          }

          return <AssetFinanceMonthly assetFinanceList={excelReportData}/>;
        }}
      </Query>
    </div>
  );
};

export default AssetFinanceScheme;
