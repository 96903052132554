import React, { useState } from 'react';

import { Query } from 'react-apollo';

import { Card, CardBody, Row, Col, Container, CardTitle } from 'reactstrap';

import TerritorySupervisors from './territory-supervisors';
import DateFilter from '../layouts/date-filter';
import { graphQlQueries } from '../gql/main-gql';
import { countryVariables } from '../../utils/country-variables';
import { decrypt } from '../../utils/utils-2';

const SupervisorTerritory = (props) => {
  // Capturing the territoriy abbreviation passed as parameters
  const {
    match: { params },
  } = props;
  const { territoryManager, territoryName } = props.location.state;
  const [latestThursday, updateLatestThursday] = useState('');
  const [filteringDate, updateFilteringDate] = useState('');
  const country = decrypt(localStorage.getItem('country'));

  const territoryLabel = country
    ? countryVariables[country]['territoryLabel']
    : '';
  const supervisorLabel = country
    ? countryVariables[country]['supervisorLabel']
    : '';

  const dateFiltering = (thursday, filterDate) => {
    updateLatestThursday(thursday);
    updateFilteringDate(filterDate);
  };

  return (
    <>
      {country ? (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row className="align-items-center">
              <Col md="12" lg="12" xl="12">
                <div className="card-stats overview-stats bg-transparent mb-4">
                  <Row className="align-items-center justify-content-between">
                    <Col md="5">
                      <DateFilter parentDateFilter={dateFiltering} />
                    </Col>
                    <Col md={{ size: 6, offset: 1 }}>
                      <Card className="card-stats">
                        <CardBody>
                          <Row className="justify-content-between">
                            <Col md="6">
                              <CardTitle
                                tag="h5"
                                className="mb-0 territory-name"
                              >
                                <p className="territory-name-short">
                                  {params.abbr}
                                </p>
                                <p className="territory-name-full text-left">
                                  {territoryName}
                                </p>
                              </CardTitle>
                            </Col>
                            <Col md="6" className="text-right">
                              <small className="mb-1">
                                Territory manager{' '}
                                <i className="fas fa-user-secret pl-2" />
                              </small>
                              <p className="font-weight-bold">
                                {territoryManager}
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <Query
            query={graphQlQueries[country]['single-supervisor']}
            variables={{ abbreviation: params.abbr }}
          >
            {({ loading, error, data }) => {
              if (loading) return <p className="text-white">LOADING...</p>;
              if (error) {
                console.log(error.message);
                return <p>Error. Try refreshing the page</p>;
              }

              return (
                <TerritorySupervisors
                  supervisors={
                    data[territoryLabel][supervisorLabel].supervisors
                  }
                  currentThursday={
                    filteringDate ? filteringDate : latestThursday
                  }
                  territoryName={territoryName}
                  country={country}
                />
              );
            }}
          </Query>
        </div>
      ) : (
        <div className="page-content bg-gradient-primary pb-8 pt-5 pt-md-8">
          <p className="text-danger">No country specified</p>
        </div>
      )}
    </>
  );
};

export default SupervisorTerritory;
