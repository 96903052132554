import React, { useState } from 'react';

import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  GET_ALL_PROSPECTS,
  GET_SETTING_COMMISSIONS,
  GET_ALL_SHOP_STAFF,
} from '../gql/gql-sec';
import BACommissionMonthly from './ba-com-monthly';
import { changeNumberFormat } from '../../utils/helpers';
import { constructMonthlyBACommissions } from '../../utils/utils';

const BrandAmbassadorCommissions = ({
  startDate,
  endDate,
  exchanger,
  pdsBA = false,
}) => {
  const [baComData, setBaComData] = useState([]);

  // Excel related info
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formattedStart = new Date(startDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
  });
  const fileName = pdsBA
    ? formattedStart !== formattedEnd
      ? `${formattedStart} - ${formattedEnd} PDS BAs Commissions`
      : `${formattedStart} PDS BAs Commissions`
    : formattedStart !== formattedEnd
    ? `${formattedStart} - ${formattedEnd} BAs Commissions`
    : `${formattedStart} BAs Commissions`;
  const fileTitle = pdsBA
    ? 'Brand Ambassadors PDS Com Report'
    : "Brand Ambassadors' Commissions Report";

  // Loading brand ambassadors
  const { data, loading, error } = useQuery(GET_ALL_SHOP_STAFF, {
    variables: { ambassador: 'true' },
    fetchPolicy: 'no-cache',
  });

  // Loading brand ambassadors PDS
  const { data: pdsData, loading: pdsLoading, error: pdsError } = useQuery(
    GET_ALL_SHOP_STAFF,
    {
      variables: { pdsambassador: 'true' },
      fetchPolicy: 'no-cache',
    },
  );

  // Loading setting Commissions
  const { data: comData, loading: comLoading, error: comError } = useQuery(
    GET_SETTING_COMMISSIONS,
  );

  // Brand Ambassadors
  if (loading) return <p className="text-white">LOADING...</p>;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;
  const brandAmbassadors = data.allShopStaffs.edges.map((staff) => staff.node);

  // Brand Ambassadors PDS
  if (pdsLoading) return <p className="text-white">LOADING...</p>;
  if (pdsError) return `Error! ${pdsError.message}`;
  if (!pdsData) return `NOT FOUND`;
  const brandAmbassadorsPDS = pdsData.allShopStaffs.edges.map(
    (staff) => staff.node,
  );

  // commissions
  if (comLoading) return <p className="text-white">LOADING...</p>;
  if (comError) return `Error! ${comError.message}`;
  if (!comData) return `NOT FOUND`;
  const commissionSettings = comData.allSettingCommissions;

  const monthDirectSales = [];

  const generateBaCommissionData = (prospectsEdges) => {
    const prospects = prospectsEdges.map((prospect) => {
      return prospect.node;
    });
    // Construct the excel export data
    const excelBaData = pdsBA
      ? constructMonthlyBACommissions(
          prospects,
          brandAmbassadorsPDS,
          commissionSettings,
          endDate,
          monthDirectSales,
          true,
        )
      : constructMonthlyBACommissions(
          prospects,
          brandAmbassadors,
          commissionSettings,
          endDate,
          monthDirectSales,
          false,
        );
    // Update excel report data state
    setBaComData(excelBaData);
  };

  const exportToExcel = (excelData, fileName, fileTitle) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const end = new Date(endDate).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    // we have 7 columns
    const wsCols = Array.apply(1, Array(8)).map((item, index) => {
      if (index === 1) {
        return { wch: 19 };
      }
      return { wch: 12 };
    });
    // Adding 5 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 5)).map((item) => {
      return { hpt: 20 };
    });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A3' });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} ${fileTitle}`;
    ws['B1'] = { t: 's', v: title };

    // Change headers Name
    ws.A3.v = 'Shop Name';
    ws.B3.v = 'Full Name';
    ws.C3.v = 'Activation Code';
    ws.D3.v = 'Phone Number';
    ws.E3.v = 'Bank Name';
    ws.F3.v = 'Bank Account';
    ws.G3.v = 'BBAN Number';
    ws.H3.v = 'Revenue';
    ws.I3.v = 'Prospected'
    ws.J3.v = 'Converted/Approved'
    ws.K3.v = 'Commissions'

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws['!ref'].split(':')[0];
    const lastRefCell = ws['!ref'].split(':')[1];

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws['!ref'] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;

    // // Change number format
    changeNumberFormat(ws, ['F', 'G'], '#,##0');

    // // merge total cell A12 and B12
    const merge = [
      { s: { r: lastRefCellRow, c: 0 }, e: { r: lastRefCellRow, c: 3 } },
    ];
    ws['!merges'] = merge;

    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' };
    ws[`H${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(H4:E${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`I${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(I4:I${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`J${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(J4:J${lastRefCellRow})`,
      z: '#,##0',
    };
    ws[`K${lastRefCellRow + 1}`] = {
      t: 'n',
      f: `SUM(K4:K${lastRefCellRow})`,
      z: '#,##0',
    };

    /* Write total data */
    const wb = {
      Sheets: { "Brand Ambassadors' Commissions": ws },
      SheetNames: ["Brand Ambassadors' Commissions"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  //  excel exporter to call from parent component
  const baCommissionExcelExporter = () => {
    exportToExcel(baComData, fileName, fileTitle);
  };

  // Add the child function to exchanger that might be called by the parent.
  exchanger.baCommissionExcelExporter = baCommissionExcelExporter;

  return (
    <>
      <div>
        {/*Normal Table*/}
        <Query
          query={GET_ALL_PROSPECTS}
          variables={{ range: `${startDate},${endDate}`, pdsba: `${pdsBA}` }}
          fetchPolicy="no-cache"
          onCompleted={(data) =>
            generateBaCommissionData(data.allProspects.edges)
          }
        >
          {({ loading, error, data }) => {
            if (loading) return <p className="text-white">LOADING...</p>;
            if (error) {
              return <p>Error. Try refreshing the page</p>;
            }

            return <BACommissionMonthly brandAmbassadorsList={baComData} />;
          }}
        </Query>
      </div>
    </>
  );
};

export default BrandAmbassadorCommissions;
