import React, { useState } from 'react';
import { Badge } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

const RewardBadge = ({ reward, currencySymbol, index, showPaidDate=false }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <Badge color="primary" pill className="mr-2 mb-1" key={index}>
          <strong className="pr-1">
            P{reward.pointSystemReward.numberOfPoint} =
          </strong>
          {reward.pointSystemReward.cash ? (
            <CurrencyFormat
              value={reward.pointSystemReward.cash}
              displayType={'text'}
              thousandSeparator={true}
              prefix={currencySymbol}
            />
          ) : (
            ''
          )}{' '}
          {reward.pointSystemReward.cash ? 'Or' : ''}
          <span className="pl-1">{reward.pointSystemReward.rewardDetail}</span>
          {isHovered && (
            <div
              className="text-left"
              style={{
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px',
                color: 'white',
                background: 'black',
                borderRadius: '5px',
              }}
            >
              <strong>
                Date Joined:{' '}
                {new Date(reward.dateJoined).toLocaleString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </strong>{' '}
              <br />
              {showPaidDate && (<br />)}
              {showPaidDate && (
                <strong>
                Date Paid:{' '}
                {new Date(reward.datePaid).toLocaleString(
                  'en-us',
                  {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  },
                )}
              </strong>
              ) }
              
            </div>
          )}
        </Badge>
      </div>
    </>
  );
};

export default RewardBadge;
